import { firebase } from '../plugins/firebase'
import { collection, doc, Firestore, getDoc, getDocs, setDoc } from '@firebase/firestore'

export const featureList = ['holiday-mode'] as const


type FeatureName = typeof featureList[number]
const firestore: Firestore = firebase.getFirestore()

export default class Feature {

  public constructor(
    public name: FeatureName,
    public isActive: boolean,
  ) {
  }

  public save(): Promise<void> {
    return setDoc(doc(firestore, 'features', this.name), { ...this })
  }

  public static async getFeatures(): Promise<Feature[]> {
    const featuresSnapshot = await getDocs(collection(firestore, 'features'))
    return featuresSnapshot.docs.map(doc => {
      const data = doc.data() as Feature
      return new Feature(data.name, data.isActive)
    })
  }


  private static async getFeature(name: FeatureName): Promise<Feature | undefined> {
    const featureSnapshot = await getDoc(doc(firestore, 'features', name))
    const data = featureSnapshot.data() as Feature | undefined
    return data ? new Feature(data.name, data.isActive) : undefined
  }

  public static getHolidayMode(): Promise<Feature | undefined> {
    return Feature.getFeature('holiday-mode')
  }

}