import Dashboard from '../pages/admin/Dashboard.vue'

export default [
  {
    path: 'soft-admin',
    component: Dashboard
  },
  {
    path: 'soft-admin/products',
    component: () => import('../pages/admin/Products.vue'),
  },
  {
    path: 'soft-admin/products/:id/edit',
    component: () => import('../pages/admin/ProductAddEdit.vue'),
  },
  {
    path: 'soft-admin/orders',
    component: () => import('../pages/admin/Orders.vue'),
  },
  {
    path: 'soft-admin/chats',
    component: () => import('../pages/admin/Chats.vue'),
  },
  {
    path: 'soft-admin/users',
    component: () => import('../pages/admin/Users.vue'),
  },
  {
    path: 'soft-admin/users/:uid',
    component: () => import('../pages/admin/Users.vue'),
  },
  {
    path: 'soft-admin/blchecker',
    component: () => import('../pages/admin/BacklinkChecker.vue'),
  },
  {
    path: 'soft-admin/projects',
    component: () => import('../pages/advertiser/Projects.vue'),
    children: [
      { path: '', component: () => import('../pages/advertiser/Projects.vue') },
      { path: 'create', component: () => import('../pages/advertiser/projects/ProjectsAddEdit.vue') },
      { path: ':project/edit', component: () => import('../pages/advertiser/projects/ProjectsAddEdit.vue') },
      { path: ':project/campaigns/create', component: () => import('../pages/advertiser/projects/CampaignAddEdit.vue') },
      { path: ':project/campaigns/:campaign', component: () => import('../pages/advertiser/projects/CampaignView.vue') },
      { path: ':project/campaigns/:campaign/edit', component: () => import('../pages/advertiser/projects/CampaignAddEdit.vue') },
    ],
  },
]
