<template>
  <Popup v-if="product" size="lg">
    <AdvertiserPressOrderPopup
      v-if="product instanceof Press && isAdvertiser"
      :product="product"
      :order-refused="orderRefused"
      :order-done="orderDone"
      :order-change="orderChange"
    />
    <PublisherPressOrderPopup
      v-if="product instanceof Press && !isAdvertiser"
      :product="product"
      :order-done="orderDone"
    />
    <AdvertiserArticleOrderPopup
      v-if="product instanceof Article && isAdvertiser"
      :product="product"
      :order-refused="orderRefused"
      :order-done="orderDone"
      :order-change="orderChange"
    />
    <PublisherArticleOrderPopup
      v-else-if="product instanceof Article && !isAdvertiser"
      :product="product"
      :order-done="orderDone"
    />
    <AdvertiserProductOrderPopup
      v-else-if="product instanceof Product && isAdvertiser"
      :product="product"
      :order-refused="orderRefused"
      :order-done="orderDone"
      :order-change="orderChange"
    />
    <PublisherProductOrderPopup
      v-else-if="product instanceof Product && !isAdvertiser"
      :product="product"
      :order-refused="orderRefused"
      :order-done="orderDone"
    />
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import Product from '../../models/product'
import { State } from '../../store'
import Popup from './Popup.vue'
import Order from '../../models/order'
import { ref } from 'vue'
import Article from '../../models/article'
import AdvertiserArticleOrderPopup from './AdvertiserArticleOrderPopup.vue'
import PublisherArticleOrderPopup from './PublisherArticleOrderPopup.vue'
import AdvertiserProductOrderPopup from './AdvertiserProductOrderPopup.vue'
import PublisherProductOrderPopup from './PublisherProductOrderPopup.vue'
import AdvertiserPressOrderPopup from './AdvertiserPressOrderPopup.vue'
import Press from '../../models/press'
import PublisherPressOrderPopup from './PublisherPressOrderPopup.vue'

const store = useStore<State>()
const isAdvertiser = store.state.AccountType.type === 'advertiser'

const product = ref<Product | Article | Press>()
const order = ref<Order>(store.state.popup.args.order)
const orderDone = store.state.popup.args.orderDone
const orderRefused = store.state.popup.args.orderRefused
const orderChange = store.state.popup.args.orderChange

if (order.value.product_collection === 'presses')
  Press.find(order.value.product).then((res) => {
    console.log(res)
    product.value = res as Press
  })
else if (order.value.product_collection === 'products')
  Product.find(order.value.product).then(
    (res) => (product.value = res as Product),
  )
else if (order.value.product_collection === 'articles')
  Article.find(order.value.product).then(
    (res) => (product.value = res as Article),
  )
</script>
