<template>
  <Heading :text="t('sell-presses')" />
  <Banner v-if="saved"> {{ t('saved') }} </Banner>
  <form ref="form" @submit.prevent="save">
    <div class="text-right">
      <button class="btn gradient">{{ t('save') }}</button>
    </div>
    <div class="grid grid-cols-2 gap-5">
      <div
        v-for="(press, k) of presses"
        :key="k"
        class="text-center border-b-4 mb-4 pb-4"
      >
        <FormRow
          v-model="presses[k].name"
          :label="t('name')"
          type="text"
          class="text-black w-full"
          required
        />
        <FormRow
          v-model="presses[k].short_description"
          :label="t('short-description')"
          type="text"
          class="text-black w-full"
          required
        />
        <FormRow
          v-model="presses[k].price"
          :label="t('price')"
          type="number"
          class="text-black w-full"
          required
        />
        <FormRow
          v-model="presses[k].description"
          :label="t('description')"
          type="textarea"
          class="text-black w-full"
          required
        />
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Heading from '../../components/Heading.vue'
import User from '../../models/user'
import Banner from '../../components/Banner.vue'
import FormRow from '../../components/form/FormRow.vue'
import { useI18n } from 'vue-i18n'
import Press from '../../models/press'
import { useStore } from 'vuex'
import { State } from '../../store'
import LoaderVue from '../../components/Loader.vue'

const i18n = useI18n()
const { t } = i18n
const store = useStore<State>()

store.commit('popup/open', {
  popup: LoaderVue,
})

const form = ref<HTMLFormElement>()

const base = <Press[]>[
  {
    name: 'Pubblicazione su nostra Newsroom',
    languages: ['it'],
    short_description: 'Iki è gratuito nella versione base',
    description:
      'Iscriviti e dal tuo pannello invia il CS\nSarà pubblicato <strong>GRATUITAMENTE</strong>, dopo la nostra revisione sul sito <strong>UfficioStampa.Online</strong>',
    price: 0,
  },
  {
    name: 'Bronzo',
    languages: ['it'],
    short_description: 'Iki invia Comunicati Stampa in Una Regione',
    description:
      'Pubblicazione <strong>GRATUITA</storng> su <strong>UfficioStampa.Online</strong>\nPubblicazione in <strong>UN</strong> sito di notizie <strong>Google News</strong>\nVisibilità nei nostri canali Social Network\nTrasmissione alle <strong>redazioni REGIONALE</strong> in base alla specifica categoria trattata',
    price: 99,
  },
  {
    name: 'Argento',
    languages: ['it'],
    short_description: 'Iki invia Comunicati Stampa in Una Regione',
    description:
      'Pubblicazione <strong>GRATUITA</storng> su <strong>UfficioStampa.Online</strong>\nPubblicazione in <strong>UN</strong> sito di notizie <strong>Google News</strong>\nVisibilità nei nostri canali Social Network\nTrasmissione alle <strong>redazioni REGIONALE</strong> in base alla specifica categoria trattata',
    price: 119,
  },
  {
    name: 'Oro',
    languages: ['it'],
    short_description: 'Iki fa volare il tuo Comunicato Stampa',
    description:
      'Pubblicazione <strong>GRATUITA</storng> su <strong>UfficioStampa.Online</strong>\nPubblicazione in <strong>UN</strong> sito di notizie <strong>Google News</strong>\nVisibilità nei nostri canali Social Network\nTrasmissione alle <strong>redazioni REGIONALE</strong> in base alla specifica categoria trattata\nRevisione del comunicato stampa da un Giornalista professionista\nServizio di rassegna stampa',
    price: 229,
  },
  {
    name: 'Platino',
    languages: ['it'],
    short_description: 'Iki all inclusive!',
    description:
      'Pubblicazione <strong>GRATUITA</storng> su <strong>UfficioStampa.Online</strong>\nPubblicazione in <strong>DUE</strong> siti di notizie <strong>Google News</strong>\nVisibilità nei nostri canali Social Network\nTrasmissione alle <strong>redazioni REGIONALE</strong> in base alla specifica categoria trattata\nRevisione del comunicato stampa da un Giornalista professionista\nServizio di rassegna stampa',
    price: 429,
  },
  {
    name: 'Top',
    languages: ['it'],
    short_description: 'Iki garanzia pubblicazioni!',
    description:
      'Pubblicazione <strong>GRATUITA</storng> su <strong>UfficioStampa.Online</strong>\nPubblicazione in <strong>DUE</strong> siti di notizie <strong>Google News</strong>\n<strong>GARANZIA</strong> Pubblicazione in <strong>almeno 5 Testate Giornalistiche</strong>\nVisibilità nei nostri canali Social Network\nTrasmissione alle <strong>redazioni REGIONALE</strong> in base alla specifica categoria trattata\nRevisione del comunicato stampa da un Giornalista professionista\nServizio di rassegna stampa',
    price: 999,
  },
]
const presses = ref<Press[]>([])

const loadData = () => {
  Promise.all([Press.own(), User.getCurrent()]).then(([prs, user]) => {
    if ((prs as Press[]).length > 0) presses.value = prs as Press[]
    else {
      for (let b of base) {
        presses.value?.push(
          new Press({
            ...b,
            ...{
              owner: user.uid as string,
              createdAt: new Date(),
            },
          }),
        )
      }
    }
    store.commit('popup/close')
  })
}
onMounted(loadData)

const saved = ref(false)

const save = async () => {
  if (!presses.value) return
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  for (let press of presses.value) {
    press.price = parseFloat(press.price as unknown as string)
    press.save()
  }
  await loadData()
  saved.value = true
  store.commit('popup/close')
}
</script>
