<template>
  <Popup>
    <h3>{{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}</h3>
    <FormInput
      v-model="notes"
      type="textarea"
      name="notes"
      :placeholder="t('refuse-order-notes')"
      class="my-4"
    />
    <div class="flex justify-end gap-2">
      <button class="btn bg-red-500 text-white !m-0 text-center" @click="close">
        {{ t('cancel') }}
      </button>
      <button
        class="btn gradient text-white !m-0 text-center"
        @click="refuseOrder"
      >
        {{ t(isAdmin ? 'rollback' : 'refuse') }}
      </button>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useFirebase } from '../../plugins/firebase'
import { State } from '../../store'
import Loader from '../Loader.vue'
import Popup from './Popup.vue'
import FormInput from '../form/FormInput.vue'
import { useRoute } from 'vue-router'

const firebase = useFirebase()
const { t } = useI18n()
const store = useStore<State>()
const order = store.state.popup.args.order
const update = store.state.popup.args.update
const notes = ref('')

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const refuseOrder = async () => {
  store.commit('popup/close')
  store.commit('popup/open', {
    popup: Loader,
  })
  await firebase.function('orderRefused', { order, notes: notes.value })
  update()
  setTimeout(() => {
    store.commit('popup/close')
  }, 2000)
}
const close = () => store.commit('popup/close')
</script>
