import { createApp } from 'vue'
import App from './App.vue'
import i18n from './lang'
import router from './router'
import store from './store'
import createFirebase from './plugins/firebase'
import createPlatform from './plugins/platform'
import createStorage from './plugins/storage'
import './plugins/formatters'
import './style.css'
import UnexpectedErrorVue from './components/popups/UnexpectedError.vue'

const firebase = createFirebase()
const platform = createPlatform()
const storage = createStorage()

const _app = createApp(App)
  .use(platform)
  .use(storage)
  .use(firebase)
  .use(store)
  .use(router)
  .use(i18n)

_app.config.errorHandler = (
  err: unknown
) => {
  import('./store').then((store) => {
    console.log(err)
    store.default.commit('popup/open', {
      popup: UnexpectedErrorVue,
      err,
    })
    console.error(err)
  })
}
_app.mount('#app')
