<template>
  <div v-if="visible" class="inset-x-0 pb-2 sm:pb-5">
    <div
      class="p-2 rounded-lg shadow-lg sm:p-3 text-black"
      :class="{
      'static-gradient': !bg,
      [bg as string]: bg,
      '!text-white': !bg || bg.match(/red/i)
      }"
    >
      <div class="flex items-center justify-between flex-wrap">
        <div class="w-0 flex-1 flex items-center">
          <span class="flex p-2 rounded-lg bg-black bg-opacity-25">
            <SpeakerphoneIcon class="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p class="ml-3 font-medium text-white">
            <span v-if="short" class="md:hidden">{{ short }}</span>
            <span v-else-if="$slots.short" class="md:hidden">
              <slot name="short"> </slot>
            </span>
            <span v-else class="md:hidden">
              <slot />
            </span>
            <span class="hidden md:inline">
              <slot />
            </span>
          </p>
          <div class="flex-grow"></div>
          <button @click="visible = false">
            <XIcon class="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SpeakerphoneIcon, XIcon } from '@heroicons/vue/outline'
import { ref } from 'vue'
defineProps<{
  short?: string
  bg?: string
}>()

const visible = ref(true)
</script>
