<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ t('copywriting-service') }}
        </h4>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <div class="my-6 divide-y-2 space-y-4">
    <dl class="flex flex-col sm:gap-4 lg:gap-6 text-sm">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('number-of-words') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.words }}</dd>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('language') }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ t(order.language as string) }}
        </dd>
      </div>
      <div v-if="order.anchor" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('anchor') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.anchor }}</dd>
      </div>
      <div v-if="order.link" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('link') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.link }}</dd>
      </div>
      <div v-if="order.publishDate" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">
          {{ t('preferred-publish-date') }}
        </dt>
        <dd class="text-gray-700 col-span-2">
          {{ order.publishDate?.format(false) }}
        </dd>
      </div>
      <div v-if="order.articleLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('article-link') }}</dt>
        <dd class="text-gray-700 col-span-2">
          <a
            :href="order.articleLink"
            target="_blank"
            class="text-primary-500 hover:text-primary-600 hover:underline"
          >
            {{ order.articleLink }}
          </a>
        </dd>
      </div>
      <div v-if="order.pictureLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('picture-link') }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ order.pictureLink }}
        </dd>
      </div>
    </dl>
  </div>
  <div v-if="order.publicationguidelines" class="mb-4">
    <h4 class="font-medium text-gray-900 flex">
      {{ t('publication-guidelines') }}:
    </h4>
    <p class="mt-2 text-sm text-gray-600 max-w-2xl" v-html="order.publicationguidelines"></p>
  </div>
  <div v-if="order.status !== 'pending'">
    <h4 class="font-medium text-gray-900 flex mb-4">{{ t('publication') }}:</h4>
    <template v-if="currentArticle">
      <a :href="currentArticle" class="btn gradient">
        {{ t('download-article') }}
      </a>
    </template>
    <template v-else-if="order.advertiserInfo">
      <span class="text-emerald-500">{{
        t('copywriting-order-article-missing-advertiser')
      }}</span>
    </template>
    <div v-if="order.status === 'paid'">
      <FormRow
        v-model="info.publishDate"
        name="publish-date"
        :label="t('preferred-publish-date')"
        type="date"
      />
      <FormRow
        v-model="info.anchor"
        name="anchor"
        :label="t('anchor')"
        type="text"
      />
      <FormRow v-model="info.link" name="link" :label="t('link')" type="text" />
      <FormRow
        v-model="info.publicationguidelines"
        name="publicationguidelines"
        :label="t('publication-guidelines')"
        type="textarea"
      />
    </div>
  </div>
  <div
    v-if="
      order.status !== 'work-in-progress' &&
      order.status !== 'need-review' &&
      order.status !== 'completed'
    "
    class="flex justify-end items-center gap-2 mt-6"
  >
    <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
    <button
      :disabled="loading"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="save"
    >
      {{ t('save') }}
    </button>
  </div>
  <div
    v-if="order.status === 'need-review' || order.status === 'completed'"
    class="flex justify-end items-center gap-2 mt-6"
  >
    <button
      v-if="order.status === 'need-review' || isAdmin"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="orderRefused"
    >
      {{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}
    </button>
    <button
      v-if="order.status === 'need-review'"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="orderDone"
    >
      {{ t('confirm-order') }}
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import Article from '../../models/article'
import FormRow from '../form/FormRow.vue'
import { listAll, ref as storageRef, getDownloadURL } from '@firebase/storage'
import { useFirebase } from '../../plugins/firebase'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const props = defineProps<{
  product: Article
  orderChange: () => void
  orderDone: () => void
  orderRefused: () => void
}>()
const order = store.state.popup.args.order as Order
const hasLinked = ref(false)
Order.own().then(
  (orders) =>
    (hasLinked.value = !!orders?.find((ord) => ord.linkedOrder === order.uid)),
)

const info = ref({
  publishDate: order.publishDate?.toISOString().split('T')[0],
  link: order.link,
  anchor: order.anchor,
  publicationguidelines: order.publicationguidelines,
})

const storage = useFirebase().getStorage()
const currentArticle = ref<string>()
;(async () => {
  const list = await listAll(storageRef(storage, 'articles/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.uid as string),
  )
  if (el)
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `articles/${el.name}`),
    )
})()

const done = ref(false)
const loading = ref(false)
const save = async () => {
  console.log(info.value)
  loading.value = true
  order.link = info.value.link
  order.anchor = info.value.anchor
  order.advertiserInfo = true
  order.advertiserInfoDate = new Date()
  order.publishDate = info.value.publishDate
    ? new Date(info.value.publishDate)
    : undefined
  order.publicationguidelines = info.value.publicationguidelines
  await order.save()
  loading.value = false
  done.value = true
  await props.orderChange()
}
</script>
