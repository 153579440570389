<template>
  <tr
    v-if="product"
    :class="[
      'hover:static-gradient-transparent',
      ...(isFeatured ? ['bg-gray-100'] : []),
    ]"
  >
    <td
      class="p-2 text-sm border text-center flex items-center justify-evenly space-x-2"
    >
      <input
        v-if="campaign"
        type="checkbox"
        class="rounded"
        :checked="modelValue"
        @change="
          emit(
            'update:modelValue',
            ($event.currentTarget as HTMLInputElement).checked,
          )
        "
      />
      <a
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        target="_BLANK"
        :href="'/app/advertiser/products/view/' + product.uid"
      >
        <EyeIcon class="w-4 h-4" />
      </a>
      <button
        v-if="!campaign"
        class="bg-yellow-600 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click.prevent="addToCart(product as Product)"
      >
        <ShoppingCartIcon class="h-5 w-4" />
      </button>
      <Tooltip v-if="!campaign" content="tooltip-product-star">
        <button
          class="block w-1/3 text-center lg:w-auto"
          :class="isPreferred ? 'text-yellow-500' : 'text-gray-200'"
          @click="preferred"
        >
          <StarIcon class="relative h-8 w-8" />
        </button>
      </Tooltip>
    </td>
    <td
      v-for="col of enabledColumns"
      :key="col"
      class="p-2 text-sm border text-center"
    >
      <template v-if="col === 'country'">
        <div class="w-6 mx-auto rounded shadow-sm overflow-hidden">
          <img
            :src="
              '/flag-icons/' +
              (product.country ?? '').replace('en', 'us') +
              '.svg'
            "
            class="h-full w-full object-cover"
          />
        </div>
      </template>
      <template v-else-if="col === 'price'">
        <template
          v-if="
            product.promo &&
            product.promo.endDate.seconds * 1000 > new Date().getTime()
          "
        >
          <s>&euro; {{ product.price.format() }}</s
          >&nbsp;
          <span class="text-primary-500 font-bold"
            >&euro;
            {{
              (product.price * ((100 - product.promo.discount) / 100)).format()
            }}</span
          >
        </template>
        <template v-else>
          <span class="text-primary-500 font-bold"
            >&euro; {{ product.price.format() }}</span
          ></template
        ></template
      >
      <template v-else-if="col === 'da'">{{ product.metrics.da }}</template>
      <template v-else-if="col === 'ascore'">{{ product.metrics.ascore }}</template>
      <template v-else-if="col === 'za'">{{ product.metrics.za }}</template>
      <template v-else-if="col === 'gnews'">
        <span
          v-if="product.google_news"
          class="flex justify-center items-center text-emerald-500"
        >
          <CheckIcon class="w-6 h-6" />
        </span>
        <span v-else class="flex justify-center items-center text-red-600">
          <XIcon class="w-6 h-6" />
        </span>
      </template>
      <template
        v-else-if="['gambling', 'adult', 'trading', 'cbd'].includes(col)"
      >
        <span
          v-if="product.accepts[col as 'gambling' | 'adult'| 'trading'| 'cbd']"
          class="flex justify-center items-center text-emerald-500"
        >
          <CheckIcon class="w-6 h-6" />
        </span>
        <span v-else class="flex justify-center items-center text-red-600">
          <XIcon class="w-6 h-6" />
        </span>
      </template>
      <template v-else-if="col === 'bl'">
        {{ product.metrics.backlinks?.total ?? 0 }}
      </template>
      <template v-else-if="col === 'edu'">
        {{ product.metrics.backlinks?.edu ?? 0 }}
      </template>
      <template v-else-if="col === 'gov'">
        {{ product.metrics.backlinks?.gov ?? 0 }}
      </template>
      <template v-else-if="col === 'type'">
        {{ t(product.type) }}
      </template>
      <template v-else-if="col === 'category'">
        <div class="flex items-center justify-center">
          <span v-if="product.categories.length > 0">{{
            t(product.categories[0])
          }}</span>
          <span v-else>
            {{ t(product.category) }}
          </span>
          <Tooltip
            v-if="product.categories.length > 1"
            :content="
              product.categories
                .slice(1, product.categories.length)
                .map((i) => t(i))
                .join(', ')
            "
          >
            <span
              class="bg-gray-600 p-1 rounded-lg text-white ml-2 text-[10px] cursor-pointer"
              >+{{ product.categories.length - 1 }}</span
            >
          </Tooltip>
        </div>
      </template>
      <template v-else-if="col === 'wr'">
        {{ t(product.metrics.wr ?? 'N.D.') }}
      </template>
      <template v-else-if="col === 'name'">
        <strong
          class="flex items-center justify-center gap-1">{{ product.name }}
          <div v-if="isFeatured" class="uppercase text-[9px] bg-primary-500 text-white rounded-md px-1">AD</div></strong>
      </template>
      <template v-else-if="col === 'date'">
        <strong>{{ product.createdAt.format(false) }}</strong>
      </template>
      <template v-else>{{ (product as any)[col] }}</template>
    </td>
  </tr>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import Tooltip from './Tooltip.vue'
import { useStore } from 'vuex'
import Product from '../models/product'
import { useFirebase } from '../plugins/firebase'
import { ref as StorageRef, getDownloadURL } from '@firebase/storage'
import User from '../models/user'
import { State } from '../store'
import {
  XIcon,
  CheckIcon,
  EyeIcon,
  ShoppingCartIcon,
  StarIcon,
} from '@heroicons/vue/solid'
import ProductPopup from './popups/ProductPopup.vue'
import { useI18n } from 'vue-i18n'
import { InformationCircleIcon } from '@heroicons/vue/outline'

const { t } = useI18n()
const props = defineProps<{
  product?: Product
  enabledColumns?: string[]
  campaign?: boolean
  modelValue?: boolean
  promo?: boolean
}>()
const emit = defineEmits(['update', 'update:modelValue'])

const types = [
  'website',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'reddit',
  'telegram',
].filter(
  (type) => (props?.product as unknown as Record<string, []>)[type].length,
)

const productImage = ref<string | null>(null)
const store = useStore<State>()
const isFeatured = computed(() => {
  if (!props.product) return
  return (
    props.product.adminFeatured ||
    (props.product.featured &&
      new Date().getTime() <= props.product.featured.endDate.toDate().getTime())
  )
})
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isPublisher = store.state.AccountType.type === 'publisher'
const isPreferred = ref<boolean>(false)
const columnSpans = <any>{
  category: 'col-span-2',
  bl: 'col-span-2',
}
const user = ref<User>()
User.getCurrent().then((r) => {
  user.value = r
  isPreferred.value = (r.meta?.favorites ?? []).includes(
    props.product?.uid as string,
  ) as boolean
})

//const router = useRouter()
const addToCart = (product: Product) => {
  store.commit('cart/add', product)
  store.commit('snackbar/open', 'media-added-to-cart')
}

const remove = () => {
  props.product?.delete()
  emit('update')
}

const preferred = () => {
  user.value?.addFavorite(props.product?.uid as string).then(() => {
    isPreferred.value = !isPreferred.value
  })
}

const openPopup = () =>
  store.commit('popup/open', {
    popup: ProductPopup,
    product: props.product,
    productImage: productImage.value,
  })

const firebase = useFirebase()
const approve = async () => {
  await props.product?.approve()
  emit('update')
}

if (props.product?.screenshot) {
  getDownloadURL(
    StorageRef(
      firebase.getStorage(),
      decodeURIComponent(props.product.screenshot),
    ),
  )
    .then((r) => {
      productImage.value = r
    })
    .catch((e) => {
      console.log(e)
    })
}
</script>
