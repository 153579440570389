<template>
  <div>
    <Heading :text="t('bundles')" />
    <div class="flex justify-end mb-4">
      <button class="btn gradient text-sm mb-4" @click="addBundle">
        {{ t('create-bundle') }}
      </button>
    </div>
    <div v-if="bundles?.length" class="space-y-4">
      <details
        v-for="(bundle, i) of bundles"
        :key="bundle.uid"
        class="rounded-md overflow-hidden"
      >
        <summary
          class="flex justify-between items-center gradient px-4 py-2 cursor-pointer"
        >
          {{ bundle.name || t('new-bundle') }}
        </summary>
        <form class="border rounded-b-md p-4" @submit.prevent="save(i)">
          <div>
            <FormRow v-model="bundle.name" type="text" :label="t('name')" />
            <FormRow
              v-model="bundle.products"
              type="vselect"
              :label="t('products')"
              multiple
              :v-select-value="
                products
                  ?.filter((p) => bundle.products.map(p => p.uid).includes(p.uid!))
                  ?.map((p) => ({
                    value: { uid: p.uid, discount: 0 },
                    label: p.name,
                  }))
              "
              :options="
                products
                  ?.filter(p => !bundles?.filter((_, j) => i !== j).flatMap(b => b.products).flatMap(p => p.uid).includes(p.uid!))
                  ?.map((p) => ({
                    value: { uid: p.uid, discount: 0 },
                    label: p.name,
                  }))
              "
            />
            <FormRow
              v-model="bundle.discount"
              type="number"
              :label="t('discount-on-commission')"
              after="%"
            />
          </div>
          <div class="flex justify-between">
            <button type="button" class="btn error" @click="deleteBundle(i)">
              {{ t('delete') }}
            </button>
            <button class="btn gradient">{{ t('save') }}</button>
          </div>
        </form>
      </details>
    </div>
    <div v-else>{{ t('no-bundles') }}</div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import FormRow from '../../components/form/FormRow.vue'
import Heading from '../../components/Heading.vue'
import Loader from '../../components/Loader.vue'
import Bundle from '../../models/bundle'
import Product from '../../models/product'
import Setting from '../../models/setting'
import User from '../../models/user'
import store from '../../store'

const i18n = useI18n()
const { t } = i18n
const bundles = ref<Bundle[]>()
const products = ref<Product[]>()
const users = ref<User[]>()
const orderFee = ref<number>()

store.commit('popup/open', { popup: Loader })
Promise.all([
  Product.all<Product>(),
  User.all<User>(),
  Setting.findOrCreate('order-fee'),
]).then(([prods, us, setting]) => {
  products.value = prods ?? undefined
  users.value = us ?? undefined
  orderFee.value = setting?.value
  store.commit('popup/close')
})

const getBundles = () => {
  store.commit('popup/open', {
    popup: Loader,
  })
  Bundle.all<Bundle>().then((items) => {
    bundles.value = items ?? undefined
    for(const el of Array.from(document.querySelectorAll('details')))
      el.open = false
    store.commit('popup/close')
  })
}
const addBundle = () => {
  if (!bundles.value) bundles.value = []
  bundles.value.push(
    new Bundle({
      name: '',
      discount: 0,
      products: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    }),
  )
}
const save = async (i: number) => {
  store.commit('popup/open', {
    popup: Loader,
  })
  const discounts = bundles.value![i].products.map(
    (prod) =>
      (bundles.value![i].discount / 100) *
      (users.value?.find(
        (u) => u.uid === products.value?.find((p) => p.uid === prod.uid)?.owner,
      )?.meta?.orderFee ?? orderFee.value!),
  )
  bundles.value![i].products = bundles.value![i].products.map(({ uid }, i) => ({
    uid,
    discount: discounts[i],
  }))
  await bundles.value![i].save()
  getBundles()
}
const deleteBundle = async (i: number) => {
  store.commit('popup/open', {
    popup: Loader,
  })
  await bundles.value![i].delete()
  getBundles()
}

getBundles()
</script>
