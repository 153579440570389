import { firebase } from '../plugins/firebase'
import {
  IdTokenResult,
  User as FirebaseUser,
  UserInfo,
  UserMetadata,
  updateEmail as FirebaseUpdateEmail,
  verifyBeforeUpdateEmail,
} from '@firebase/auth'
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  getDocs,
  setDoc,
  DocumentData,
  orderBy,
  query,
  Timestamp,
  addDoc,
  deleteField,
  collectionGroup,
  where,
  deleteDoc,
} from '@firebase/firestore'
import { uploadBytes, ref, getDownloadURL, listAll } from '@firebase/storage'
import { v4 as uid } from 'uuid'
import Model from './model'
import Product from './product'

export interface Meta {
  balance: number
  orderFee?: number
  affiliatePercentage?: number
  phone?: string
  disabled: boolean
  firstName: string
  lastName: string
  isCopywriter: boolean
  transactions: {
    uid: string
    parent?: string
    type:
      | 'withdrawal'
      | 'deposit'
      | 'purchase'
      | 'order'
      | 'refund'
      | 'affiliate'
      | 'adjustment'
    description: string
    amount: number
    createdAt: Date
    status: 'pending' | 'completed' | 'rejected'
    invoice?: string
    paymentMethod?:
      | 'stripe'
      | 'wire'
      | 'balance'
      | 'order'
      | 'withdrawal'
      | 'paypal'
    orderId: string
    personalOrderId: string
    withdrawalDate?: string
    rejectReason?: string
    withdrawalInfo?: string
    custom?: any
    previousBalance?: number
    invoiceName?: string
  }[]
  invoicingAccounts: {
    uid?: string
    parent?: string
    firstName?: string
    lastName?: string
    organization?: string
    vat?: string
    address: string
    city: string
    zip: string
    state: string
    country: string
    sdi: string
    pec: string
  }[]
  notifications: {
    uid: string
    text: string
    read: boolean
    picture?: string
    link?: string
    from?: string
    createdAt: Date
    notified: boolean
  }[]
  notifications_settings: {
    order_new: {
      email: boolean
    }
    order_status: {
      email: boolean
    }
  }
  affiliate?: string
  favorites: string[]
  promotions: {
    uid?: string
    name: string
    products: string[]
    startDate: Timestamp
    endDate: Timestamp
    discount: number
  }[]
  consents: {
    updated?: boolean
    marketing: boolean
  }
  email_language: 'it' | 'en'
  registrationParameters?: object
  publisherBusinessName?: string
}

interface UserModel extends FirebaseUser {
  meta: Meta | null
  isAdmin: boolean
  isSoftAdmin: boolean
}

export default class User extends Model implements UserModel {
  private static currentUser: User | null = null
  public meta: Meta | null = null
  public collection = 'users'
  public static collection = 'users'
  public isAdmin = false
  public isSoftAdmin = false

  private constructor(
    private firebaseUser: FirebaseUser | null,
    public emailVerified: boolean,
    public isAnonymous: boolean,
    public metadata: UserMetadata,
    public providerData: UserInfo[],
    public refreshToken: string,
    public tenantId: string | null,
    public displayName: string | null,
    public email: string | null,
    public phoneNumber: string | null,
    public photoURL: string | null,
    public providerId: string,
    public uid: string,
  ) {
    super()
  }

  static async instantiate(id: string, user: DocumentData) {
    const { uid, ...attrs } = user as User
    const userModel = new this(
      user as FirebaseUser,
      attrs.emailVerified,
      attrs.isAnonymous,
      attrs.metadata,
      attrs.providerData,
      attrs.refreshToken,
      attrs.tenantId,
      attrs.displayName,
      attrs.email,
      attrs.phoneNumber,
      attrs.photoURL,
      attrs.providerId,
      id,
    )
    await userModel.setMeta()
    return userModel
  }

  static async allLight<T extends Model>() {
    const users = await firebase.function<FirebaseUser[]>('getUsers')
    return users.map((user) => {
      const { uid, ...attrs } = user as User
      const userModel = new this(
        user as FirebaseUser,
        attrs.emailVerified,
        attrs.isAnonymous,
        attrs.metadata,
        attrs.providerData,
        attrs.refreshToken,
        attrs.tenantId,
        attrs.displayName,
        attrs.email,
        attrs.phoneNumber,
        attrs.photoURL,
        attrs.providerId,
        uid,
      )

      userModel.meta = null

      return userModel as unknown as T
    })
  }

  static async all<T extends Model>() {
    const users = await firebase.function<FirebaseUser[]>('getUsers')
    const firestore = firebase.getFirestore()

    const usersMeta = (await Promise.all(
      (
        await getDocs(collection(firestore, 'users'))
      ).docs.map((el) => ({ uid: el.id, ...el.data() })),
    )) as Record<string, string>[]

    const transactions = (await Promise.all(
      (
        await getDocs(collectionGroup(firestore, 'transactions'))
      ).docs.map(async (el) => {
        const trans = el.data()
        if (trans.invoiceId) {
          const invoice = ref(firebase.getStorage(), trans.invoiceId)
          trans.invoice = await getDownloadURL(invoice).catch(console.error)
        }
        if (trans.createdAt)
          trans.createdAt = (trans.createdAt as Timestamp).toDate()
        return { uid: el.id, ...trans }
      }),
    )) as Meta['transactions']

    const invoicingAccounts = (
      await getDocs(collectionGroup(firestore, 'invoicingAccounts'))
    ).docs.map((el) => ({
      uid: el.id,
      ...el.data(),
    })) as Meta['invoicingAccounts']

    return users.map((user) => {
      const { uid, ...attrs } = user as User
      const userModel = new this(
        user as FirebaseUser,
        attrs.emailVerified,
        attrs.isAnonymous,
        attrs.metadata,
        attrs.providerData,
        attrs.refreshToken,
        attrs.tenantId,
        attrs.displayName,
        attrs.email,
        attrs.phoneNumber,
        attrs.photoURL,
        attrs.providerId,
        uid,
      )
      userModel.meta = (usersMeta.find((user) => user.uid === uid) ??
        {}) as unknown as Meta
      userModel.meta.transactions = transactions.filter(
        (trans) => trans.parent === userModel.uid,
      )
      userModel.meta.invoicingAccounts = invoicingAccounts.filter(
        (invoicing) => invoicing.parent === userModel.uid,
      )
      //console.log(userModel)
      return userModel as unknown as T
    })
  }

  static async allMetas<T extends Model>(users: User[]) {
    const firestore = firebase.getFirestore()

    const usersMeta = (await Promise.all(
      (
        await getDocs(collection(firestore, 'users'))
      ).docs.map((el) => ({ uid: el.id, ...el.data() })),
    )) as Record<string, string>[]

    const transactions = (await Promise.all(
      (
        await getDocs(collectionGroup(firestore, 'transactions'))
      ).docs.map(async (el) => {
        const trans = el.data()
        if (trans.createdAt)
          trans.createdAt = (trans.createdAt as Timestamp).toDate()
        return { uid: el.id, ...trans }
      }),
    )) as Meta['transactions']

    const invoicingAccounts = (
      await getDocs(collectionGroup(firestore, 'invoicingAccounts'))
    ).docs.map((el) => ({
      uid: el.id,
      ...el.data(),
    })) as Meta['invoicingAccounts']

    return users.map((user) => {
      const { uid, ...attrs } = user as User
      const userModel = new this(
        user as FirebaseUser,
        attrs.emailVerified,
        attrs.isAnonymous,
        attrs.metadata,
        attrs.providerData,
        attrs.refreshToken,
        attrs.tenantId,
        attrs.displayName,
        attrs.email,
        attrs.phoneNumber,
        attrs.photoURL,
        attrs.providerId,
        uid,
      )
      userModel.meta = (usersMeta.find((user) => user.uid === uid) ??
        {}) as unknown as Meta
      userModel.meta.transactions = transactions.filter(
        (trans) => trans.parent === userModel.uid,
      )
      userModel.meta.invoicingAccounts = invoicingAccounts.filter(
        (invoicing) => invoicing.parent === userModel.uid,
      )
      //console.log(userModel)
      return userModel as unknown as T
    })
  }

  static async getMetas(user: User) {
    const firestore = firebase.getFirestore()

    //get collection /users/${user.uid}
    const meta =
      (await getDoc(doc(collection(firestore, 'users'), user.uid)))?.data() ??
      {}

    //get transactions /users/${user.uid}/transactions
    const transactions = (
      await getDocs(collection(firestore, `/users/${user.uid}/transactions`))
    ).docs.map(async (el) => {
      const trans = el.data()
      if (trans.createdAt)
        trans.createdAt = (trans.createdAt as Timestamp).toDate()
      return { uid: el.id, ...trans }
    })

    //get invoicingAccounts /users/${user.uid}/invoicingAccounts
    const invoicingAccounts = (
      await getDocs(
        collection(firestore, `/users/${user.uid}/invoicingAccounts`),
      )
    ).docs.map((account) => ({
      uid: account.id,
      ...account.data(),
    }))

    return {
      ...user,
      meta,
      transactions,
      invoicingAccounts,
    }
  }

  static async getBalance(uid: string) {
    const firestore = firebase.getFirestore()
    const userData = await getDoc(doc(collection(firestore, '/users'), uid))
    return userData.get('balance')
  }

  static async addToBalance<T extends Model>(uid: string, amount: number) {
    const firestore = firebase.getFirestore()
    const userDoc = doc(collection(firestore, '/users'), uid)
    const userData = await getDoc(userDoc)
    const balance = userData.get('balance')
    await setDoc(userDoc, { balance: balance + amount }, { merge: true })
    return balance + amount
  }

  static async findTransactions<T extends Model>(uid: string) {
    const firestore = firebase.getFirestore()
    return (
      await getDocs(collection(firestore, `/users/${uid}/transactions`))
    ).docs.map((account) => ({
      uid: account.id,
      ...account.data(),
    }))
  }

  public async updateEmail(email: string) {
    return new Promise((resolve) => {
      if (this.firebaseUser) {
        resolve(verifyBeforeUpdateEmail(this.firebaseUser, email))
      } else resolve(false)
    })
  }

  public async updatePhone(phone: string) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(collection(firestore, '/users'), this.uid), { phone })
  }

  public async setAffiliate(affiliate: string) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(collection(firestore, '/users'), this.uid), {
      affiliate,
    })
  }

  public async updateDisplayName(firstName: string, lastName: string) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(collection(firestore, '/users'), this.uid), {
      firstName,
      lastName,
    })
  }

  public async updateNotificationsSettings(notifications_settings: object) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(collection(firestore, '/users'), this.uid), {
      notifications_settings: notifications_settings,
    })
  }

  private async setMeta() {
    const firestore = firebase.getFirestore()
    const data = doc(collection(firestore, '/users'), this.firebaseUser?.uid)

    const meta = ((await getDoc(data)).data() as DocumentData) ?? {}
    if (!meta.balance) {
      await setDoc(
        data,
        {
          balance: 0,
        },
        { merge: true },
      )
      meta.balance = 0
    }

    meta.invoicingAccounts = (
      await getDocs(
        collection(
          firestore,
          `/users/${this.firebaseUser?.uid}/invoicingAccounts`,
        ),
      )
    ).docs.map((account) => ({
      uid: account.id,
      ...account.data(),
    }))

    meta.transactions = await Promise.all(
      (
        await getDocs(
          query(
            collection(
              firestore,
              `/users/${this.firebaseUser?.uid}/transactions`,
            ),
            orderBy('createdAt', 'desc'),
          ),
        )
      ).docs.map(async (t) => {
        const trans = t.data()
        if (trans.invoiceId) {
          const invoice = ref(firebase.getStorage(), trans.invoiceId)
          trans.invoice = await getDownloadURL(invoice).catch(console.error)
        }
        trans.createdAt = (trans.createdAt as Timestamp).toDate()
        return { uid: t.id, ...trans }
      }),
    )

    meta.promotions = (
      await getDocs(
        collection(firestore, `/users/${this.firebaseUser?.uid}/promotions`),
      )
    ).docs.map((p) => {
      const promo = p.data()
      return {
        uid: p.id,
        ...promo,
        startDate: promo.startDate,
        endDate: promo.endDate,
      }
    })

    const storage = firebase.getStorage()
    meta.notifications = await Promise.all(
      (
        await getDocs(
          query(
            collection(
              firestore,
              `/users/${this.firebaseUser?.uid}/notifications`,
            ),
            orderBy('createdAt', 'desc'),
          ),
        )
      ).docs
        .map((n) => ({ uid: n.id, ...n.data() } as DocumentData))
        .map((n) => ({ createdAt: n.createdAt.toDate(), ...n }))
        .map(async (n) => {
          const path = (await listAll(ref(storage, `avatars/`))).items.find(
            (el) => el.fullPath.includes(this.uid),
          )?.fullPath
          return {
            picture: path
              ? await getDownloadURL(ref(storage, path))
              : undefined,
            ...n,
          }
        }),
    )

    this.meta = meta as Meta
  }

  static async getCurrent() {
    // Singleton
    if (User.currentUser) return User.currentUser

    // Fetch user and set it to make sure only one exists
    const firebaseUserModel = firebase.getAuth().currentUser as FirebaseUser
    const user = new this(
      firebaseUserModel,
      firebaseUserModel.emailVerified ?? false,
      firebaseUserModel.isAnonymous,
      firebaseUserModel.metadata,
      firebaseUserModel.providerData,
      firebaseUserModel.refreshToken,
      firebaseUserModel.tenantId,
      firebaseUserModel.displayName,
      firebaseUserModel.email,
      firebaseUserModel.phoneNumber,
      firebaseUserModel.photoURL,
      firebaseUserModel.providerId,
      firebaseUserModel.uid,
    )

    // Set meta and return user
    await user.setMeta()
    user.isAdmin = !!(await user.getIdTokenResult()).claims.admin
    user.isSoftAdmin = !!(await user.getIdTokenResult()).claims.softAdmin
    User.currentUser = user
    return User.currentUser
  }

  static async getAffiliateStats() {
    const current = (await this.getCurrent()).uid
    const firestore = firebase.getFirestore()
    const totalAffiliates = 0 /*(
      await getDocs(
        query(
          collection(firestore, 'users'),
          where('affiliate', '==', current),
        ),
      )
    ).docs.length*/
    const totalEarn = (
      await getDocs(
        query(
          collection(firestore, `/users/${current}/transactions`),
          where('type', '==', 'affiliate'),
        ),
      )
    ).docs
      .map((i) => i.data().amount)
      .reduce((a: number, b: number) => a + b, 0)
    return { totalAffiliates, totalEarn }
  }

  async withdraw(amount: number, invoice: File) {
    const firestore = firebase.getFirestore()
    const invoiceId = `invoices/${uid()}.${invoice.name.split('.').pop()}`

    // Save invoice
    const storage = ref(firebase.getStorage(), invoiceId)
    uploadBytes(storage, await invoice.arrayBuffer())

    // Create transaction
    const transaction = doc(
      collection(firestore, `/users/${this.uid}/transactions`),
    )
    await setDoc(transaction, {
      type: 'withdrawal',
      invoiceId,
      amount: -amount,
      parent: this.uid,
      description: 'Withdrawal',
      status: 'pending',
      createdAt: new Date(),
      paymentMethod: 'withdrawal',
    })

    // Update balance
    const userData = doc(collection(firestore, '/users'), this.uid)
    await setDoc(
      userData,
      { balance: (this.meta as Meta).balance - amount },
      { merge: true },
    )
    ;(this.meta as Meta).balance -= amount
  }

  async addFavorite(product: string) {
    const firestore = firebase.getFirestore()
    const favorites = (this.meta as Meta).favorites || []
    if (!favorites.includes(product)) favorites.push(product)
    else {
      const index = favorites.indexOf(product)
      if (index !== -1) favorites.splice(index, 1)
    }
    updateDoc(doc(collection(firestore, '/users'), this.uid), { favorites })
  }

  async notify(notification: { text: string; link?: string; from?: string }) {
    const firestore = firebase.getFirestore()
    const { text, link, from = 'Rankister' } = notification
    addDoc(collection(firestore, `users/${this.uid}/notifications`), {
      text,
      ...(link ? { link } : {}),
      ...(from ? { from } : {}),
      read: false,
      createdAt: new Date(),
      notified: false,
    })
  }

  static async createTransaction(uid: string, transaction: any) {
    const firestore = firebase.getFirestore()
    return addDoc(
      collection(firestore, `users/${uid}/transactions`),
      transaction,
    )
  }

  async read(notification: string) {
    const firestore = firebase.getFirestore()
    updateDoc(
      doc(firestore, `users/${this.uid}/notifications/${notification}`),
      { read: true },
    )
  }

  async setOrderFee(fee?: number) {
    const firestore = firebase.getFirestore()
    if (fee) {
      await updateDoc(doc(firestore, `users/${this.uid}`), { orderFee: fee })
    } else {
      await updateDoc(doc(firestore, `users/${this.uid}`), {
        orderFee: deleteField(),
      })
    }
  }

  async setAffiliatePercentage(affiliatePercentage?: number) {
    const firestore = firebase.getFirestore()
    if (affiliatePercentage) {
      await updateDoc(doc(firestore, `users/${this.uid}`), {
        affiliatePercentage,
      })
    } else {
      await updateDoc(doc(firestore, `users/${this.uid}`), {
        affiliatePercentage: deleteField(),
      })
    }
  }

  async delete() {
    await this.firebaseUser?.delete()
  }

  async getIdToken(forceRefresh?: boolean) {
    return (await this.firebaseUser?.getIdToken(forceRefresh)) as string
  }

  async getIdTokenResult(forceRefresh?: boolean) {
    return (await this.firebaseUser?.getIdTokenResult(
      forceRefresh,
    )) as IdTokenResult
  }

  async reload() {
    await this.firebaseUser?.reload()
  }

  async updateConsents(consents: { marketing: boolean }) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(firestore, `users/${this.uid}`), { consents })
  }

  toJSON(): object {
    if (typeof this.firebaseUser?.toJSON === 'function')
      return this.firebaseUser?.toJSON() as object
    else return this
  }

  async getMeta(meta: string) {
    const firestore = firebase.getFirestore()
    const userData = await getDoc(
      doc(collection(firestore, '/users'), this.uid),
    )
    const _meta = meta as keyof Meta

    if (!this.meta)
      this.meta = {
        balance: 0,
        disabled: false,
        firstName: '',
        lastName: '',
        isCopywriter: false,
        transactions: [],
        invoicingAccounts: [],
        notifications: [],
        notifications_settings: {
          order_new: {
            email: true,
          },
          order_status: {
            email: true,
          },
        },
        favorites: [],
        promotions: [],
        consents: {
          marketing: false,
        },
        email_language: 'en',
      }

    this.meta = {
      ...this.meta,
      [_meta]: await userData.get(_meta),
    }

    return this
  }

  async refresh() {
    await this.setMeta()
    return this
  }

  async addEditPromotion(promo: any) {
    const firestore = firebase.getFirestore()
    promo.discount = parseFloat(promo.discount as unknown as string)
    promo.startDate = Timestamp.fromDate(promo.startDate)
    promo.endDate = Timestamp.fromDate(promo.endDate)
    console.log(promo)
    const promoObj = !promo.uid
      ? await doc(collection(firestore, `/users/${this.uid}/promotions`))
      : await doc(
          collection(firestore, `/users/${this.uid}/promotions`),
          promo.uid,
        )

    for (const product of promo.products) {
      const prod = await Product.find(product)
      if (!prod) continue
      prod.promo = {
        ...(promo.uid ? { uid: promo.uid } : {}),
        startDate: promo.startDate,
        endDate: promo.endDate,
        discount: promo.discount,
      }
      await prod.save()
    }

    await setDoc(promoObj, promo)
    return promo
  }

  async deletePromo(promo: any) {
    const firestore = firebase.getFirestore()

    for (const product of promo.products) {
      const prod = await Product.find(product)
      if (!prod) continue
      prod.promo = undefined
      await prod.save()
    }

    await deleteDoc(
      doc(collection(firestore, `users/${this.uid}/promotions`), promo.uid),
    )
  }

  /**
   * Get user reviews
   */
  public static async getUserReviews(uid: string): Promise<any> {
    const firestore = firebase.getFirestore()
    const ratings = (
      await getDocs(query(collection(firestore, `/users/${uid}/ratings`)))
    ).docs.map((t: any) => ({ uid: t.id, ...t.data() }))

    return ratings
  }

  public async updatePublisherBusinessName(publisherBusinessName: string) {
    const firestore = firebase.getFirestore()
    await updateDoc(doc(collection(firestore, '/users'), this.uid), {
      publisherBusinessName,
    })
  }
}
