<template>
  <Heading :text="t('sell-articles')" />
  <Banner v-if="article && !article.uid">
    {{ t('not-selling-articles') }}
  </Banner>
  <Banner v-if="saved"> {{ t('saved') }}</Banner>
  <form ref="form" @submit.prevent="save">
    <div v-if="article">
      <FormRow v-model="article.language" :label="t('languages')">
        <div class="flex items-center flex-wrap">
          <template v-for="locale of articleLanguages" :key="locale">
            <input
              :id="locale"
              :checked="!!article.language[locale]"
              class="first:ml-0 ml-4 rounded mr-1 text-primary-500"
              type="checkbox"
              @change="
                (e) => (((article as Article).language[locale as typeof articleLanguages[number]] as any) = (e.target as HTMLInputElement).checked ? {}  : null)
              "
            />
            <label :for="locale">{{ t('lang-' + locale) }}</label>
          </template>
        </div>
      </FormRow>
      <div
        v-for="lang of Object.keys(languages).filter(
          (key) => (article as Article).language[key as typeof articleLanguages[number]],
        )"
        :key="lang"
        class="my-10 grid grid-cols-3"
      >
        <p class="col-span-3">
          {{ `${t('price-per-word')}  ${t('lang-' + lang)}` }}
        </p>
        <FormRow
          v-for="quality of articleQualities"
          :key="lang + quality"
          :label="`${t('quality-' + quality)}`"
          :model-value="article.language[lang as typeof articleLanguages[number]][quality as typeof articleQualities[number]]"
          :name="`price-${lang}-${quality}`"
          class="ml-[-1rem]"
          step="0.001"
          type="number"
          @update:model-value="
            (val) => {
              if (!val) delete (article as Article).language[lang as typeof articleLanguages[number]][quality as typeof articleQualities[number]]
              else (article as Article).language[lang as typeof articleLanguages[number]][quality as typeof articleQualities[number]] = val || null
            }
          "
        />
      </div>
    </div>
    <div class="flex justify-end">
      <button class="btn gradient">{{ t('save') }}</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Heading from '../../components/Heading.vue'
import Article, {
  ILanguage,
  languages as articleLanguages,
  qualities as articleQualities,
} from '../../models/article'
import User from '../../models/user'
import Banner from '../../components/Banner.vue'
import FormRow from '../../components/form/FormRow.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const article = ref<Article>()
const form = ref<HTMLFormElement>()
const languages = ref<Record<string, boolean>>(
  articleLanguages.reduce((acc, el) => ({ ...acc, [el]: false }), {}),
)

const loadData = () =>
  Promise.all([Article.own(), User.getCurrent()]).then(([art, user]) => {
    article.value =
      (art ?? [])[0] ??
      new Article({
        owner: user.uid,
        language: {} as ILanguage,
        createdAt: new Date(),
      })
    console.log(article.value)
    languages.value = articleLanguages.reduce(
      (acc, el) => ({
        ...acc,
        [el]:
          article.value?.language &&
          article.value?.language[el as (typeof articleLanguages)[number]],
      }),
      {},
    )
  })
onMounted(loadData)

const saved = ref(false)

const save = async () => {
  if (!article.value || !form.value?.reportValidity()) return
  if (article.value?.language) {
    article.value.language = Object.keys(article.value.language)
      .filter(
        (key) =>
          article.value?.language[key as (typeof articleLanguages)[number]] &&
          Object.keys(
            article.value?.language[key as (typeof articleLanguages)[number]],
          ).length > 0,
      )
      .reduce(
        (acc, el) => ({
          ...acc,
          [el]: article.value?.language[
            el as (typeof articleLanguages)[number]
          ],
        }),
        {} as ILanguage,
      )
  }
  await article.value.save()
  await loadData()
  saved.value = true
}
</script>
