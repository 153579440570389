import { DocumentData, Timestamp } from '@firebase/firestore'
import Model from './model'

export const languages = ['en', 'fr', 'de', 'es', 'it', 'pt'] as const
export const qualities = ['low', 'medium', 'premium'] as const

export type IQualityPrice = Record<(typeof qualities)[number], number>
export type ILanguage = Record<(typeof languages)[number], IQualityPrice>

interface ArticleModel {
  uid?: string
  owner: string
  language: ILanguage
  createdAt: Date
}

export default class Article extends Model implements ArticleModel {
  public collection = 'articles'
  static collection = 'articles'

  public owner: string
  public language: ILanguage
  createdAt: Date

  public constructor(attributes: ArticleModel) {
    super()
    this.uid = attributes.uid
    this.owner = attributes.owner
    this.language = attributes.language
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as ArticleModel
    return new this({
      uid: id,
      ...attrs,
    })
  }
}
