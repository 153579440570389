<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="fixed inset-0 flex z-40 md:hidden"
      @close="$emit('close')"
    >
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
      </TransitionChild>
      <TransitionChild
        as="template"
        enter="transition ease-in-out duration-300 transform"
        enter-from="-translate-x-full"
        enter-to="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leave-from="translate-x-0"
        leave-to="-translate-x-full"
      >
        <div
          class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700"
        >
          <TransitionChild
            as="template"
            enter="ease-in-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in-out duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button
                type="button"
                class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                @click="$emit('close')"
              >
                <span class="sr-only">Close sidebar</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </TransitionChild>
          <div class="flex-shrink-0 flex items-center px-4">
            <Logo type="white" />
          </div>
          <div
            v-if="menuFull && !isAdmin"
            class="mx-4 mt-4 mb-2 bg-gray-600 text-white rounded-md px-4 py-4"
          >
            <Switch />
          </div>
          <div class="mt-5 flex-1 h-0 overflow-y-auto">
            <nav class="px-2 space-y-1">
              <NavLink
                v-for="item in nav"
                :key="item.name"
                :to="item.href"
                :current="route.path === item.href"
                @close-nav="$emit('close')"
                class="relative"
              >
                <component
                  v-if="typeof item.icon === 'function'"
                  :is="item.icon"
                  class="mr-4 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                <div v-else class="mr-4 flex-shrink-0 h-6 w-6" v-html="item.icon"></div>
                {{ t(item.name) }}
                <div
                  v-if="item.badge"
                  class="absolute top-1/2 right-2 bg-red-500 rounded-md p-1 text-xs"
                  style="transform: translateY(-50%)"
                >
                  {{ item.badge }}
                </div>
              </NavLink>
              <NavLink
                v-if="user?.isAdmin"
                :to="
                  route.fullPath.startsWith('/app/admin')
                    ? '/app'
                    : '/app/admin'
                "
                @close-nav="$emit('close')"
              >
                <UserIcon
                  v-if="route.fullPath.startsWith('/app/admin')"
                  class="mr-4 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                <IdentificationIcon
                  v-else
                  class="mr-4 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                {{ route.fullPath.startsWith('/app/admin') ? 'User' : 'Admin' }}
              </NavLink>
              <NavLink
                v-if="user?.isSoftAdmin"
                :to="
                  route.fullPath.startsWith('/app/soft-admin')
                    ? '/app'
                    : '/app/soft-admin'
                "
                @close-nav="$emit('close')"
              >
                <UserIcon
                  v-if="route.fullPath.startsWith('/app/soft-admin')"
                  class="mr-4 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                <IdentificationIcon
                  v-else
                  class="mr-4 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                {{
                  route.fullPath.startsWith('/app/soft-admin')
                    ? 'User'
                    : 'Soft Admin'
                }}
              </NavLink>
            </nav>
          </div>
        </div>
      </TransitionChild>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import NavLink from '../components/NavLink.vue'
import { useRoute } from 'vue-router'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { XIcon, UserIcon, IdentificationIcon } from '@heroicons/vue/outline'
import Logo from '../components/Logo.vue'
import { NavItem } from './navs'
import User from '../models/user'
import Switch from '../components/Switch.vue'

defineProps<{
  open: boolean
  isAdmin: boolean
  nav: NavItem[]
  menuFull: boolean
  user?: User
}>()

defineEmits(['close'])

const { t } = useI18n()
const route = useRoute()
</script>
