<template>
  <Heading :text="t('projects')" />
  <p v-html="t('projects-intro').split('\n').join('<br />')"></p>
  <div class="flex justify-end mb-4">
    <RouterLink
      v-if="!isAdmin && !isSoftAdmin && !isCopywriter"
      to="/app/advertiser/projects/create"
      class="btn gradient text-sm mb-4"
    >
      {{ t('create-project') }}
    </RouterLink>
  </div>
  <div v-if="projects?.length" class="space-y-4">
    <details
      v-for="project of projects"
      :key="project.uid"
      class="rounded-md overflow-hidden"
    >
      <summary
        class="flex justify-between items-center gradient px-4 py-2 cursor-pointer"
      >
        {{ project.name }}
        <RouterLink
          v-if="!isCopywriter"
          :to="`/app/${
            isSoftAdmin
              ? 'soft-admin'
              : isAdmin
              ? 'admin'
              : isCopywriter
              ? 'copywriter'
              : 'advertiser'
          }/projects/${project.uid}/edit`"
        >
          <PencilIcon class="w-5 h-5" />
        </RouterLink>
      </summary>
      <div class="border rounded-b-md p-4">
        <strong>{{ project.domain }}</strong>
        <template v-if="project.notes !== ''">
          <hr class="my-2" />
          <div v-html="project.notes?.split('\n').join('<br />')"></div>
        </template>
        <h3 class="text-lg font-semibold mb-2 text-center">
          {{ t('campaigns') }}
        </h3>
        <div v-if="!project.campaigns?.length">{{ t('no-campaigns') }}</div>
        <div v-else>
          <PillTabs
            v-model="type"
            i18n
            :values="['all', 'drafts', 'started', 'completed']"
          />
        </div>
        <div
          v-for="campaign of project.campaigns
            ?.filter((i) =>
              type == 0
                ? true
                : type == 1
                ? !i.completed && !i.started
                : type == 2
                ? i.started && !i.completed
                : i.completed,
            )
            .filter((i) => (isCopywriter ? i.started : true))"
          :key="campaign.uid"
        >
          <RouterLink
            :to="`/app/${
              isSoftAdmin
                ? 'soft-admin'
                : isAdmin
                ? 'admin'
                : isCopywriter
                ? 'copywriter'
                : 'advertiser'
            }/projects/${project.uid}/campaigns/${campaign.uid}`"
            class="flex items-center py-3 border-b-2 px-2 justify-between border-primary-500"
          >
            {{ campaign.name }}
            <ChevronRightIcon class="w-5 h-5" />
          </RouterLink>
        </div>
        <div v-if="!isCopywriter" class="flex justify-end mt-4">
          <RouterLink
            :to="`/app/${
              isSoftAdmin ? 'soft-admin' : isAdmin ? 'admin' : 'advertiser'
            }/projects/${project.uid}/campaigns/create`"
            class="btn gradient text-sm"
          >
            {{ t('create-campaign') }}
          </RouterLink>
        </div>
      </div>
    </details>
  </div>
  <div v-else>{{ t('no-projects') }}</div>
</template>

<script lang="ts" setup>
import Heading from '../../../components/Heading.vue'
import { RouterLink, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import Project from '../../../models/project'
import { PencilIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import PillTabs from '../../../components/PillTabs.vue'
import { useStore } from 'vuex'
import { State } from '../../../store'
import LoaderVue from '../../../components/Loader.vue'

const route = useRoute()
const { t } = useI18n()
const type = ref(0)
const store = useStore<State>()
const projects = ref<Project[]>()
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isCopywriter = useRoute().fullPath.startsWith('/app/copywriter')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')
if (isAdmin || isSoftAdmin || isCopywriter)
  Project.all().then((res) => {
    projects.value = res as Project[]
    store.commit('popup/close')
  })
else
  Project.own().then((res) => {
    projects.value = res as Project[]
    store.commit('popup/close')
  })

store.commit('popup/open', {
  popup: LoaderVue,
})
</script>
