<template>
  <form v-if="campaign" ref="form" @submit.prevent="checkout">
    <RouterLink
      :to="
        '/app/' +
        (isSoftAdmin
          ? 'soft-admin'
          : isAdmin
          ? 'admin'
          : isCopywriter
          ? 'copywriter'
          : 'advertiser') +
        '/projects'
      "
      class="flex items-center gap-2 top-4 sm:top-6 left-4 sm:left-6 text-sm"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="black"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        />
      </svg>
      {{ t('back-to-projects') }}
    </RouterLink>
    <Heading>
      {{ campaign?.name }}
      <RouterLink
        v-if="!isCopywriter"
        :to="`/app/${
          route.path.includes('soft-admin')
            ? 'soft-admin'
            : route.path.includes('admin')
            ? 'admin'
            : 'advertiser'
        }/projects/${project?.uid}/campaigns/${campaign.uid}/edit`"
        class="gradient"
      >
        <PencilIcon
          class="inline-block w-5 h-9 bg-white text-black mix-blend-lighten"
        />
      </RouterLink>
    </Heading>
    <p class="my-4" v-html="t('campaign-info')"></p>
    <template v-if="campaign?.notes !== ''">
      <div v-html="campaign?.notes?.split('\n').join('<br />')"></div>
      <hr class="my-2"
    /></template>

    <p class="mb-4 font-bold italic">
      {{ t('picture-accept-terms-projects') }}
    </p>

    <details class="my-2">
      <summary class="btn primary">
        {{ t('discover-article-qualities') }}
      </summary>
      <div>
        <p v-for="q of ['low', 'medium', 'premium']" :key="q">
          <strong>{{ t(`quality-${q}`) }}</strong
          >: {{ t(`infobox-info-quality-${q}`) }}
        </p>
      </div>
    </details>
    <div v-if="!campaign?.started" class="w-full overflow-x-auto">
      <table class="w-full">
        <tr class="bg-gray-100 font-bold">
          <td class="p-2 text-sm border text-center">
            {{ t('name') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('need-copy') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('info') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('publication-date') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('image-link') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('link-type') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('article') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('anchor') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('link') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('language') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('quality') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('words') }}
          </td>
          <td class="p-2 text-sm border text-center">
            {{ t('price') }}
          </td>
        </tr>

        <tr
          v-for="(el, i) of setup"
          :key="campaign.products[i].uid"
          class="hover:static-gradient-transparent"
        >
          <td class="p-2 text-sm border text-center">
            {{ campaign.products[i].name }}
          </td>
          <td class="p-2 text-sm border text-center">
            <input v-model="el.needArticle" type="checkbox" class="rounded" />
          </td>
          <td class="p-2 text-sm border text-center">
            <InformationCircleIcon
              class="w-6 h-6 text-primary-500 cursor-pointer"
              @click="openInfoPopup(i)"
            />
          </td>
          <td class="p-2 text-sm border text-center">
            <input
              v-model="el.date"
              :name="`date-${i}`"
              type="date"
              :min="
                el.needArticle
                  ? new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000)
                      .toISOString()
                      .split('T')[0]
                  : new Date().toISOString().split('T')[0]
              "
              :placeholder="t('publish-date')"
              class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent text-sm"
              required
            />
          </td>
          <td class="p-2 text-sm border text-center">
            <input
              v-model="el.image"
              :name="`image-${i}`"
              type="text"
              :placeholder="t('image')"
              class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
            />
          </td>
          <td class="p-2 text-sm border text-center">
            <select
              v-model="el.linkType"
              :name="`link-type-${i}`"
              class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent text-sm"
            >
              <option
                v-for="opt in ['dofollow', 'nofollow', 'citation'].filter(
                  (o) =>
                    !(campaign?.products || [])[i]?.only_nofollow ||
                    o !== 'dofollow',
                )"
                :key="opt"
                :value="opt"
              >
                {{ t(opt) }}
              </option>
            </select>
          </td>

          <td class="p-2 text-sm border text-center">
            <div class="flex items-center gap-1">
              <a
                v-if="el.article"
                :href="el.article"
                class="text-primary-600 hover:text-primary-700 underline whitespace-nowrap"
              >
                {{ t('current-article') }}
              </a>
              <input
                type="file"
                :name="`article-${i}`"
                class="disabled:opacity-50"
                :disabled="el.needArticle"
                @change="
                  el.articleFile = (
                    $event.target as HTMLInputElement
                  ).files?.item(0) as File
                "
              />
            </div>
          </td>
          <td class="p-2 text-sm border text-center">
            <input
              v-model="el.anchor"
              :name="`anchor-${i}`"
              type="text"
              :placeholder="t('anchor')"
              class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
              :required="el.needArticle"
            />
          </td>
          <td class="p-2 text-sm border text-center">
            <input
              v-model="el.link"
              :name="`link-${i}`"
              type="text"
              :placeholder="t('link')"
              class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
              :required="el.needArticle"
            />
          </td>
          <td class="p-2 text-sm border text-center">
            <select
              v-if="el && article"
              v-model="el.lang"
              @change="el.qual = undefined"
              :name="`lang-${i}`"
              :disabled="!el.needArticle"
              class="border-0 border-b py-0 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
              :required="el.needArticle"
            >
              <option
                v-for="lang of Object.keys(article.language || {})"
                :key="lang"
                :value="lang"
                class="flex items-center gap-1"
              >
                {{ t('lang-' + lang) }}
              </option>
            </select>
          </td>
          <td class="p-2 text-sm border text-center">
            <select
              v-if="el && article"
              v-model="el.qual"
              :name="`qual-${i}`"
              :disabled="!el.needArticle"
              class="border-0 border-b py-0 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
              :required="el.needArticle"
            >
              <option
                v-for="qual of Object.keys((article as Article).language[el.lang as typeof languages[number]] || {})"
                :key="qual"
                :value="qual"
                class="flex items-center gap-1"
              >
                {{ t(`quality-${qual}`) }}
              </option>
            </select>
          </td>
          <td class="p-2 text-sm border text-center">
            <div class="flex flex-col">
              <input
                v-if="el"
                v-model="el.words"
                :name="`words-${i}`"
                type="number"
                placeholder="Words"
                :min="minWords"
                step="1"
                :disabled="!el.needArticle"
                class="border-0 border-b py-0 px-1 focus:ring-0 focus:border-primary-500 bg-transparent disabled:opacity-50 text-sm"
                :required="el.needArticle"
              />
              <small
                v-if="el?.words && (el.words as number) < minWords"
                class="-mb-2"
              >
                {{ t('min-words') }}
              </small>
            </div>
          </td>
          <td
            v-if="prices[i] !== undefined"
            class="p-2 text-sm border text-center"
          >
            {{ prices[i].format() }}&nbsp;€
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <ExportCsv
        class="float-right my-1 mb-2.5"
        :filename="campaign?.name || t('campaign')"
        :data="(campaignToSave as object[])"
      ></ExportCsv>
      <div class="w-full overflow-x-auto">
        <table class="w-full">
          <tr class="bg-gray-100 font-bold">
            <td class="p-2 text-sm border text-center">
              {{ t('name') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('publication-date') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('image') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('article') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('anchor') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('link') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('price') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('status') }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ t('order-id') }}
            </td>
          </tr>

          <tr
            v-for="(el, i) of setup"
            :key="campaign.products[i].uid"
            class="hover:static-gradient-transparent"
          >
            <td class="p-2 text-sm border text-center">
              {{ campaign.products[i].name }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ new Date(el.date as unknown as string).format(false) }}
            </td>
            <td class="p-2 text-sm border text-center">
              <a :href="el.image" target="_BLANK" class="underline">{{
                t('image')
              }}</a>
            </td>
            <td class="p-2 text-sm border text-center">
              <div v-if="!el.needArticle" class="flex items-center gap-1">
                <a
                  v-if="el.article"
                  :href="el.article"
                  class="text-primary-600 hover:text-primary-700 underline whitespace-nowrap"
                >
                  {{ t('current-article') }}
                </a>
                <input
                  v-else
                  type="file"
                  :name="`article-${i}`"
                  class="disabled:opacity-50"
                  :disabled="el.needArticle"
                  @change="
                    el.articleFile = (
                      $event.target as HTMLInputElement
                    ).files?.item(0) as File
                  "
                />
              </div>
              <div v-else>
                <div
                  v-if="el.needArticle"
                  class="flex items-center justify-center"
                >
                  <Logo type="favicon" />
                </div>
                <a
                  v-if="el.article"
                  :href="el.article"
                  class="text-primary-600 hover:text-primary-700 underline whitespace-nowrap"
                >
                  {{ t('current-article') }}
                </a>
              </div>
            </td>
            <td class="p-2 text-sm border text-center">
              {{ el.anchor }}
            </td>
            <td class="p-2 text-sm border text-center">
              {{ el.link }}
            </td>
            <td
              v-if="prices[i] !== undefined"
              class="p-2 text-sm border text-center"
            >
              {{ prices[i].format() }}&nbsp;€
            </td>
            <td class="p-2 text-sm border text-center">
              <OrderItemLight
                v-if="el.orderArticle instanceof Order"
                :order="el.orderArticle"
                @update="reload"
              />
              <OrderItemLight
                v-if="el.order instanceof Order"
                :order="el.order"
                @update="reload"
              />
              <span v-else>{{ t('pending') }}</span>
            </td>
            <td class="p-2 text-sm border text-center">
              {{ (el.order as Order)?.uid }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="flex justify-end my-4">
      <span class="font-bold text-2xl">
        {{ t('total') }}: {{ totalPrice.format() }} €
      </span>
    </div>
    <div
      v-if="!campaign.started && !isAdmin && !isSoftAdmin && !isCopywriter"
      class="flex flex-col md:flex-row md:items-center gap-4 my-4"
    >
      <button
        type="button"
        class="btn bg-red-500 text-white text-sm"
        @click="del"
      >
        {{ t('delete-campaign') }}
      </button>
      <div class="flex-grow hidden md:block" />
      <span v-if="saved" class="text-green-600">{{ t('saved') }}</span>
      <button type="button" class="btn gradient text-sm" @click.prevent="save">
        {{ t('save-campaign') }}
      </button>
      <button class="btn gradient text-sm">
        {{ t('checkout') }}
      </button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import Heading from '../../../components/Heading.vue'
import { RouterLink, useRoute, useRouter } from 'vue-router'
import { ref, computed, watch } from 'vue'
import Project from '../../../models/project'
import Product from '../../../models/product'
import { useI18n } from 'vue-i18n'
import Article, {
  ILanguage,
  IQualityPrice,
  languages,
  qualities,
} from '../../../models/article'
import {
  deleteObject,
  listAll,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from '@firebase/storage'
import { useFirebase } from '../../../plugins/firebase'
import { useStore } from 'vuex'
import { State } from '../../../store'
import Order from '../../../models/order'
import { where } from '@firebase/firestore'
import OrderItemLight from '../../../components/OrderItemLight.vue'
import Logo from '../../../components/Logo.vue'
import { InformationCircleIcon } from '@heroicons/vue/outline'
import CampaignInfoPopup from '../../../components/popups/CampaignInfoPopup.vue'
import { PencilIcon } from '@heroicons/vue/solid'
import LoaderVue from '../../../components/Loader.vue'
import ExportCsv from '../../../components/ExportCsv.vue'

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isCopywriter = useRoute().fullPath.startsWith('/app/copywriter')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')
const { t } = useI18n()

interface Setup {
  date?: Date
  image?: string
  anchor?: string
  publicationguidelines?: string
  extras: boolean[]
  needArticle: boolean
  words?: number
  linkType?: string
  qual?: string
  link?: number
  lang?: string
  article?: string
  articleFile?: File
  order?: string | Order
  orderArticle?: string | Order
}

interface Campaign {
  uid: string
  name: string
  notes: string
  products: Product[]
  setup: Setup[]
  started?: boolean
  completed?: boolean
}

const prices = computed(() => {
  return setup.value.map((el, i) => {
    return (
      (campaign.value?.products[i].promo &&
      (campaign.value.products[i].promo as any)?.endDate.seconds * 1000 >
        new Date().getTime()
        ? campaign.value!.products[i].price *
          ((100 - (campaign.value.products[i].promo as any).discount) / 100)
        : campaign.value!.products[i].price ?? 0) +
      (el.extras?.reduce((acc, extra, j) => {
        return (
          acc +
          Number(extra) * (campaign.value!.products[i].extras[j]?.price ?? 0)
        )
      }, 0) ?? 0) +
      Number(el.needArticle ?? false) *
        Number(el.words ?? 0) *
        (el.lang
          ? (article.value?.language &&
              article.value?.language[el.lang as (typeof languages)[number]] &&
              (article.value?.language[el.lang as (typeof languages)[number]])[
                el.qual as (typeof qualities)[number]
              ]) ??
            0
          : 0)
    )
  })
})
const totalPrice = computed(() => prices.value.reduce((acc, el) => acc + el, 0))

const route = useRoute()
const project = ref<Project>()
const campaign = ref<Campaign>()
const campaignToSave = ref<object[]>()
const article = ref<Article>()
const setup = ref<Setup[]>([])
const store = useStore<State>()

store.commit('popup/open', {
  popup: LoaderVue,
})

const fetch = () => {
  Promise.all([
    Article.all(),
    Project.find(route.params.project as string),
  ]).then(async ([articles, proj]) => {
    article.value = (articles as Article[])[0]

    project.value = proj as Project
    campaign.value = project.value?.campaigns?.find(
      (camp) => camp.uid === route.params.campaign,
    ) as unknown as Campaign
    if (campaign.value) {
      campaign.value.setup = campaign.value.setup.map((i) => ({
        ...{ extras: [] },
        ...i,
      })) as unknown as Setup[]
      campaign.value.products = (await Promise.all(
        campaign.value?.products.map((prod) =>
          Product.find(prod as unknown as string),
        ),
      )) as Product[]

      if (campaign.value.started) {
        if (isAdmin || isSoftAdmin)
          Order.all([where('campaign', '==', campaign.value?.uid)]).then(
            (ords) => {
              if (!campaign.value) return
              for (const k in campaign.value.setup) {
                const ord = ords?.find(
                  (i) => i.uid === campaign.value?.setup[k].order,
                )
                if (ord) campaign.value.setup[k].order = ord
                if (campaign.value?.setup[k].needArticle) {
                  const artOrd = ords?.find(
                    (i) => i.uid === campaign.value?.setup[k].orderArticle,
                  )
                  if (artOrd) campaign.value.setup[k].orderArticle = artOrd
                }
              }
              setup.value = campaign.value.setup
            },
          )
        else
          Order.own([where('campaign', '==', campaign.value?.uid)]).then(
            (ords) => {
              if (!campaign.value) return
              for (const k in campaign.value.setup) {
                const ord = ords?.find(
                  (i) => i.uid === campaign.value?.setup[k].order,
                )
                if (ord) campaign.value.setup[k].order = ord
                if (campaign.value?.setup[k].needArticle) {
                  const artOrd = ords?.find(
                    (i) => i.uid === campaign.value?.setup[k].orderArticle,
                  )
                  if (artOrd) campaign.value.setup[k].orderArticle = artOrd
                }
              }
              setup.value = campaign.value.setup
            },
          )
      } else setup.value = campaign.value.setup
    }
    campaignToSave.value = campaign.value.setup.map((el, i) => {
      return {
        [t('name')]: campaign.value?.products[i].name,
        [t('publication-date')]: new Date(el.date as unknown as string).format(
          false,
        ),
        [t('publication-guidelines')]: el.publicationguidelines,
        [t('image')]: el.image,
        [t('article')]: el.article || '',
        [t('anchor')]: el.anchor,
        [t('link')]: el.link,
        [t('price')]: campaign.value?.products[i].price || '',
        [t('status')]:
          (el.order instanceof Order && t(el.order.status)) || t('pending'),
        [t('order-id')]: el.order,
      }
    })
    store.commit('popup/close')
  })
}
fetch()

const reload = () => window.location.reload()
const minWords = 600
const router = useRouter()
const form = ref<HTMLFormElement>()
const saved = ref(false)
const storage = useFirebase().getStorage()

const save = async () => {
  if (!project.value || !campaign.value || campaign.value?.started) return
  store.commit('popup/open', {
    popup: LoaderVue,
  })

  const object = {
    uid: campaign.value.uid,
    name: campaign.value.name || '',
    notes: campaign.value.notes || '',
    products: campaign.value.products.map((el) => el.uid as string),
    setup: await Promise.all(
      setup.value.map(async (el, i) => {
        const { articleFile, ...data } = el
        if (data.needArticle) {
          return {
            date: data.date || '',
            image: data.image || '',
            anchor: data.anchor || '',
            needArticle: data.needArticle || false,
            words: data.words || 0,
            link: data.link || '',
            linkType: data.linkType || '',
            qual: data.qual || '',
            lang: data.lang || '',
            extras: Array.from(data.extras.entries()).map((el) => el[1]),
            publicationguidelines: data.publicationguidelines || '',
          } as Setup & {
            article: string
          }
        } else {
          let sRef
          if (articleFile) {
            const list = await listAll(storageRef(storage, 'projects/'))
            sRef = storageRef(
              storage,
              `project/${project.value?.uid}-${campaign.value?.uid}-${
                campaign.value?.products[i].uid
              }.${articleFile.name.split('.').pop()}`,
            )

            list.items.forEach((item) =>
              item.name.startsWith(
                `${project.value?.uid}-${campaign.value?.uid}-${campaign.value?.products[i].uid}`,
              )
                ? deleteObject(storageRef(storage, `projects/${item.name}`))
                : void 0,
            )
            await uploadBytes(sRef, await articleFile.arrayBuffer())
          }

          return {
            date: data.date || '',
            image: data.image || '',
            anchor: data.anchor || '',
            needArticle: data.needArticle || false,
            link: data.link || '',
            linkType: data.linkType || '',
            ...(sRef ? { article: await getDownloadURL(sRef) } : {}),
            extras: Array.from(data.extras.entries()).map((el) => el[1]),
            publicationguidelines: data.publicationguidelines || '',
          } as Setup & {
            article: string
          }
        }
      }),
    ),
  }

  await project.value.upsertCampaign(object)
  store.commit('popup/close')
}
const checkout = async () => {
  if (campaign.value?.started) return
  if (isAdmin || isSoftAdmin || isCopywriter) {
    alert("You can't make checkouts")
    return
  }
  if (!confirm(t('project-checkout-confirm'))) return
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  await save()
  store.commit('cart/empty')
  for (const i in setup.value) {
    const el = setup.value[i]
    store.commit('cart/add', {
      product: campaign.value?.products[i],
      campaign: campaign.value?.uid,
      defaults: {
        anchor: setup.value[i].anchor,
        link: setup.value[i].link,
        linkType: setup.value[i].linkType,
        pictureLink: setup.value[i].image,
        publishDate: setup.value[i].date,
        campaign_index: i,
        extras: Array.from(setup.value[i].extras.entries()).map((el) => el[1]),
        publicationguidelines: setup.value[i].publicationguidelines ?? '',
      },
    })
    if (el.needArticle) {
      store.commit('cart/add', {
        product: article.value,
        defaults: {
          words: el.words,
          language: el.lang,
          quality: el.qual,
          linkType: setup.value[i].linkType,
          publicationguidelines:
            campaign.value?.products[i].publicationguidelines +
            '<hr class="my-4" />' +
            (setup.value[i].publicationguidelines ?? ''),
          linkedOrder:
            store.state.cart.campaign[store.state.cart.campaign.length - 1],
          anchor: setup.value[i].anchor,
          link: setup.value[i].link,
          publishDate: setup.value[i].date,
          campaign_index: i,
          extras: Array.from(setup.value[i].extras.entries()).map(
            (el) => el[1],
          ),
        },
        campaign: campaign.value?.uid,
      })
    }
  }
  router.push(
    `/app/advertiser/cart/checkout?project=${project.value?.uid}&campaign=${campaign.value?.uid}`,
  )
}
const del = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  await project.value?.deleteCampaign(campaign.value!.uid)
  router.replace('/app/advertiser/projects/')
}

const openInfoPopup = (index: number) => {
  store.commit('popup/open', {
    popup: CampaignInfoPopup,
    campaign: campaign.value,
    index,
    update: (val: Campaign) => {
      campaign.value = val
      setup.value = val.setup
      store.commit('popup/close')
    },
  })
}
</script>
