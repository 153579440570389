<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ t('copywriting-service') }}
        </h4>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
        <p v-if="linkedInfo && !order.campaign" class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('domain') }}: {{ linkedInfo.domain }}<br />
          {{ t('publication-guidelines') }}
          <div class="my-2 italic" v-html="linkedInfo.publicationguidelines"></div>
        </p>
        <p v-if="order.campaign" class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('publication-guidelines') }}
          <div class="my-2 italic" v-html="order.publicationguidelines"></div>
        </p>
        <p v-if="order.project" class="mt-2 text-sm text-red-600 font-bold max-w-2xl">
          {{ t('publish-date') }}: {{ new Date((order.publishDate as Date)?.getTime() - ((24*60*60*1000) * 2)).format(false) }}<br />
        </p>
        <p v-if="order.project" class="mt-2 text-sm text-red-600 font-bold max-w-2xl">
          {{ t('real-publish-date') }}: {{ new Date((order.publishDate as Date)?.getTime()).format(false) }}<br />
        </p>
        <p v-if="order.campaign && order.project">
        <a class="underline" target="_BLANK" :href="'/app/copywriter/projects/' + order.project + '/campaigns/' + order.campaign">{{ t('campaign')}}</a></p>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <template
    v-if="
      order.status !== 'pending' &&
      order.status !== 'paid' &&
      order.status !== 'need-review'
    "
  >
    <div class="my-6 divide-y-2 space-y-4">
      <dl class="flex flex-col sm:gap-4 lg:gap-6 text-sm">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('number-of-words') }}</dt>
          <dd class="text-gray-700 col-span-2">{{ order.words }}</dd>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('language') }}</dt>
          <dd class="text-gray-700 col-span-2">
            {{ t(order.language as string) }}
          </dd>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('anchor') }}</dt>
          <dd class="text-gray-700 col-span-2">{{ order.anchor }}</dd>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('link') }}</dt>
          <dd class="text-gray-700 col-span-2">{{ order.link }}</dd>
        </div>
        <div v-if="!order.project" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">
            {{ t('preferred-publish-date') }}
          </dt>
          <dd class="text-gray-700 col-span-2">
            {{ order.publishDate?.format(false) }}
          </dd>
        </div>
        
        <div v-if="order.articleLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('article-link') }}</dt>
          <dd class="text-gray-700 col-span-2">
            <a
              :href="order.articleLink"
              target="_blank"
              class="text-primary-500 hover:text-primary-600 hover:underline"
            >
              {{ order.articleLink }}
            </a>
          </dd>
        </div>
        <div v-if="order.pictureLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('picture-link') }}</dt>
          <dd class="text-gray-700 col-span-2">
            {{ order.pictureLink }}
          </dd>
        </div>
      </dl>
    </div>
    <div v-if="order.publicationguidelines" class="mb-4">
      <h4 class="font-medium text-gray-900 flex">
        {{ t('publication-guidelines') }}:
      </h4>
      <p class="mt-2 text-sm text-gray-600 max-w-2xl" v-html="order.publicationguidelines"></p>
    </div>
    <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
    <div v-if="!changing && currentArticle">
      <div v-if="order.refused && order.status === 'work-in-progress'">{{ t('article-refused') }} <a href="#" class="underline" @click="openChat">{{ t('open-the-chat') }}</a></div>
      <div v-else>{{ t('article-received-publisher') }}</div>
      <div class="my-4">
        <a :href="currentArticle" class="btn gradient !ml-0">
          {{ t('download-article') }}
        </a>
        <a href="" class="btn gradient" @click.prevent="changing = !changing">
          {{ t('change-article') }}
        </a>
      </div>
    </div>
    <div v-else>
      <FormRow
        label=""
        type="file"
        accepts=".docx"
        @change="article = $event as unknown as File"
      />
    </div>
    <div class="flex justify-end items-center gap-2 mt-6">
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        :disabled="loading"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
  </template>
  <div
    v-else-if="order.status === 'paid' && order.advertiserInfo"
    class="flex justify-end items-center gap-2 mt-6"
  >
    <button
      :disabled="loading"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="() => orderDone()"
    >
      {{ t('take-order') }}
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import FormRow from '../form/FormRow.vue'
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
  listAll,
  deleteObject,
} from '@firebase/storage'
import { useFirebase } from '../../plugins/firebase'
import Article from '../../models/article'
import Chat from '../../models/chat'
import ChatPopupVue from './ChatPopup.vue'

const { t } = useI18n()
const store = useStore<State>()

const props = defineProps<{
  product: Article
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderDone: Function
}>()
const order = store.state.popup.args.order as Order

const firebase = useFirebase()
const storage = firebase.getStorage()

const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'articles/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.uid as string),
  )
  if (el)
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `articles/${el.name}`),
    )
}
getCurrentArticle()
const article = ref<File>()
const done = ref(false)
const loading = ref(false)
const save = async () => {
  if (!article.value) return
  loading.value = true
  const list = await listAll(storageRef(storage, 'articles/'))
  list.items.forEach((item) =>
    item.name.startsWith(order.uid as string)
      ? deleteObject(storageRef(storage, `articles/${item.name}`))
      : void 0,
  )
  uploadBytes(
    storageRef(
      storage,
      `articles/${order.uid}.${article.value.name.split('.').pop()}`,
    ),
    await article.value.arrayBuffer(),
  )
  order.article = true
  await firebase.function('orderChangeNotification', { order: order.uid })
  await order.save()
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  if (order.status === 'work-in-progress') props.orderDone()
  setTimeout(() => (done.value = false), 2000)
}

const openChat = async () => {
  let model = await Chat.find(order.uid)
  if (!model) {
    model = new Chat({
      uid: order.uid,
      advertiser: order.owner,
      publisher: order.publisher,
      createdAt: new Date()
    })
    model.save()
  }
  store.commit('popup/open', { popup: ChatPopupVue, chat: model })
}

const linkedInfo = ref<{
  domain: string
  publicationguidelines: string
}>()

const fetchLinked = () => {
  firebase.function('getLinkedOrder', { uid: order.uid }).then((res) => {
    if(res) linkedInfo.value = res as { domain: string; publicationguidelines: string}
  })
}
fetchLinked()
</script>
