<template>
  <Heading :text="t('development')" />
  <div class="grid grid-cols-4 gap-3">
    <button
      v-for="test of [
        { name: 'updateWebsites', action: updateWebsites },
        { name: 'resetWebsitesMetrics', action: resetWebsitesMetrics },
        {
          name: 'notificationTest',
          action: notificationTest,
        },
        {
          name: 'processBlchecker',
          action: processBlchecker,
        },
        {
          name: 'fixStates',
          action: processFixStates,
        },
        {
          name: 'fixCanceledOrders',
          action: processFixCanceledOrders,
        },
        {
          name: 'fixStates (save)',
          action: processFixStatesSave,
        },
        {
          name: 'fixUsers',
          action: processFixUsers,
        },
        {
          name: 'calculateBalance',
          action: processCalculateBalance,
        },
        {
          name: 'statsProductsRank',
          action: processStatsProductsRank,
        },
        {
          name: 'processLateApproves',
          action: processLateApproves,
        },
        {
          name: 'processLatePublications',
          action: processLatePublications,
        },
        {
          name: 'processPendingOrders',
          action: processPendingOrders,
        },
        {
          name: 'checkForBrokenCampaignOrders',
          action: processBrokenCampaignOrders,
        },
        {
          name: 'campaignInfoToOrder',
          action: processCampaignInfoToOrder,
        },
        {
          name: 'processCampaignInfoToOrderFix',
          action: processCampaignInfoToOrderFix,
        },
        {
          name: 'debugFirstOrderSurvey',
          action: debugFirstOrderSurvey,
        },
        {
          name: 'fixOrdersWithoutTxn',
          action: fixOrdersWithoutTxn,
        },
        {
          name: 'cancelOrdersByOrderGroup',
          action: cancelOrdersByOrderGroup,
        },
        {
          name: 'cancelOrdersByOrderGroupTwo',
          action: cancelOrdersByOrderGroupTwo,
        },
        {
          name: 'cancelTransactionsByOrderUids',
          action: cancelTransactionsByOrderUids,
        },
        {
          name: 'affiliateTransactionsFix',
          action: affiliateTransactionsFix,
        },
        {
          name: 'fixOldTransactionsAdjustment',
          action: fixOldTransactionsAdjustment,
        },
        {
          name: 'fixFattureInCloudDown',
          action: fixFattureInCloudDown,
        },
        {
          name: 'fixFattureInCloudDownCompleted',
          action: fixFattureInCloudDownCompleted,
        },
        {
          name: 'processOrdersLinkedFix',
          action: processOrdersLinkedFix,
        },
        {
          name: 'bulkDeleteMediasByUser',
          action: bulkDeleteMediasByUser,
        },
        {
          name: 'rejecTransactionByOrderId',
          action: rejecTransactionByOrderId,
        },
      ]"
      :key="test.name"
      class="btn gradient"
      @click="test.action()"
    >
      {{ test.name }}
    </button>
  </div>
  <div class="mt-4">
    <h2>Logs</h2>
    <p v-for="log of logs" :key="log" v-html="log.split('\n').join('<br />')" />
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import Heading from '../../components/Heading.vue'
import User, { Meta } from '../../models/user'
import { useFirebase } from '../../plugins/firebase'
import { useI18n } from 'vue-i18n'
import store from '../../store'
import Loader from '../../components/Loader.vue'
import { signInWithCustomToken, UserCredential } from '@firebase/auth'
import { useStorage } from '../../plugins/storage'
import Order from '../../models/order'
import Project from '../../models/project'
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from '@firebase/firestore'
import advertiser from '../../router/advertiser'
import publisher from '../../router/publisher'
import Product from '../../models/product'

const { t } = useI18n()

const firebase = useFirebase()
const firestore = firebase.getFirestore()
const auth = firebase.getAuth()
const logs = ref<string[]>([])
const storage = useStorage()

const processLoginByUid = () => {
  const uid = prompt('uid?')
  store.commit('popup/open', { popup: Loader })
  firebase.function('processLoginByUid', { uid }).then((res) => {
    signInWithCustomToken(auth, res as string)
      .then((credential: UserCredential) => {
        console.log(credential)
        if (credential.user.emailVerified) {
          storage?.set('email', credential.user.email)
          location.href = '/'
        }
      })
      .catch((error) => {
        console.error(error)
      })
    store.commit('popup/close')
  })
}

const processBlchecker = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('manualProcessBlchecker')
    .then(() => store.commit('popup/close'))
}

const processLateApproves = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase.function('manualProcessLateApproves').then((res) => {
    logs.value = res as string[]
    store.commit('popup/close')
  })
}

const processLatePublications = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase.function('manualProcessLatePublications').then((res) => {
    logs.value = res as string[]
    store.commit('popup/close')
  })
}

const processPendingOrders = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase.function('manualProcessPendingOrders').then((res) => {
    logs.value = res as string[]
    store.commit('popup/close')
  })
}

const processFixStates = () => {
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('fixStates', {
      do: false,
    })
    .then((res) => {
      logs.value = res as string[]
      store.commit('popup/close')
    })
}

const processFixCanceledOrders = () => {
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('fixCanceledOrders', {
      do: false,
    })
    .then((res) => {
      logs.value = res as string[]
      store.commit('popup/close')
    })
}

const processFixStatesSave = () => {
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('fixStates', {
      do: true,
    })
    .then((res) => {
      logs.value = res as string[]
      store.commit('popup/close')
    })
}

const processStatsProductsRank = () => {
  store.commit('popup/open', { popup: Loader })
  firebase.function('statsProductsRank').then((res) => {
    logs.value = res as string[]
    store.commit('popup/close')
  })
}

const processFixUsers = () => {
  store.commit('popup/open', { popup: Loader })
  firebase.function('fixUsers').then(() => store.commit('popup/close'))
}

const updateWebsites = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase.function('manualUpdateWebsites').then((r) => {
    store.commit('popup/close')
  })
}

const resetWebsitesMetrics = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  firebase.function('resetWebsitesMetrics').then((r) => {
    store.commit('popup/close')
  })
}

const notificationTest = () => {
  if (!confirm('sicuro?')) return
  store.commit('popup/open', { popup: Loader })
  user.value?.notify({ text: 'Ciao sono una notifica di test' })
  store.commit('popup/close')
}

const processCalculateBalance = () => {
  const uid = prompt('uid', '8jdNWwoXNSVokhcyOz5pERt7Jde2')
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('calculateBalance', {
      user: uid,
    })
    .then((r) => {
      logs.value = []
      const trans = (r as any).sort((a: any, b: any) =>
        a.createdAt._seconds < b.createdAt._seconds ? -1 : 1,
      )
      const _transactions = []
      const sep = '   '
      let balance = 0
      for (let t of r as any) {
        _transactions.push(t)
        const _old = balance

        if (t.status !== 'completed') continue
        if (t.type === 'withdrawal') balance += t.amount as number
        else if (t.type === 'refund') balance += t.amount as number
        else if (t.type === 'order') balance += t.amount as number
        else if (t.type === 'deposit') balance += t.amount as number
        else if (t.type === 'promo') balance += t.amount as number
        else if (t.type === 'purchase' && t.paymentMethod === 'balance')
          balance += t.amount as number
        else if (t.type === 'adjustment') balance += t.amount as number
        else if (t.type === 'affiliate') balance += t.amount as number

        logs.value?.push(
          new Date(t.createdAt._seconds * 1000).format(true) +
            'type: ' +
            t.type +
            sep +
            'pmethod: ' +
            t.paymentMethod +
            sep +
            'amount: ' +
            t.amount.format() +
            sep +
            'status: ' +
            t.status +
            sep +
            'descr: ' +
            t.description +
            sep +
            'balance: ' +
            (balance == _old
              ? '<span style="color: red">' + balance.format() + '</span>'
              : balance.format()),
        )
      }
      logs.value?.push('balance: ' + balance.format())
      store.commit('popup/close')
    })
}

const processBrokenCampaignOrders = () => {
  Order.all().then((orders) => {
    Project.all().then((projects) => {
      const res = []
      const _projects = []
      for (const project of projects as Project[]) {
        if (
          project.campaigns?.filter(
            (i) => i.setup.filter((i: any) => !i.needArticle).length,
          ).length
        )
          _projects.push(project)
      }
      for (const project of _projects) {
        for (const campaign of (project.campaigns ?? []).filter(
          (i: any) => i.started,
        )) {
          for (const setup of campaign.setup.filter((i: any) => !i.needArticle))
            res.push(setup.order)
        }
      }
      for (const order of res) {
        const _order = (orders as Order[]).find((i) => i.uid === order)
        if (!_order) console.log(order + ' not found!')
        else {
          if (!_order.advertiserInfo) console.log(order + ' problema!')
        }
      }
    })
  })
}

const processCampaignInfoToOrder = () => {
  const order_uid = prompt('order uid', '')
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('ordersCampaignInfo', {
      orders: [order_uid],
    })
    .then((r) => {
      console.log(r)
      store.commit('popup/close')
    })
    .catch((e) => {
      console.log(e)
      store.commit('popup/close')
    })
}

const processCampaignInfoToOrderFix = () => {
  const yes = prompt('fixare?', 'no')
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('ordersCampaignInfoFix', {
      do: yes === 'yes' ? true : false,
    })
    .then((r) => {
      console.log(r)
      store.commit('popup/close')
    })
    .catch((e) => {
      console.log(e)
      store.commit('popup/close')
    })
}

const processOrdersLinkedFix = () => {
  const yes = prompt('fixare?', 'no')
  store.commit('popup/open', { popup: Loader })
  firebase
    .function('ordersLinkedFix', {
      do: yes === 'yes' ? true : false,
    })
    .then((r) => {
      console.log(r)
      store.commit('popup/close')
    })
    .catch((e) => {
      console.log(e)
      store.commit('popup/close')
    })
}
const debugFirstOrderSurvey = () => {
  if (!confirm('sicuro?')) return
  firebase
    .function('debugFirstOrderSurvey')
    .then((r) => {
      console.log(r)
      logs.value = [JSON.stringify(r, null, 2)] as string[]
    })
    .catch((e) => {
      console.log(e)
    })
}

const affiliateTransactionsFix = async () => {
  alert('NO')
  return
  const users = (await User.all()) as User[]
  let _transactions = []
  for (let user of users ?? []) {
    for (let trans of user.meta?.transactions ?? []) {
      _transactions.push({ ...trans, user: user })
    }
  }
  const toUpdate: Record<string, number> = {}
  for (const trans of _transactions.filter(
    (i: any) => i.type === 'affiliate',
  )) {
    const uid = trans.parent as string
    if (!toUpdate[uid]) toUpdate[uid] = 0
    //const userBalance = (await getDoc(doc(firestore, `/users/${trans.parent}`))).data()?.balance
    toUpdate[uid] = toUpdate[uid] + trans.amount
  }
  for (const user in toUpdate) {
    const userBalance = (await getDoc(doc(firestore, `/users/${user}`))).data()
      ?.balance
    const newBalance = Math.round((userBalance + toUpdate[user]) * 100) / 100
    console.log(
      user,
      'oldBalance',
      userBalance,
      'newBalance',
      newBalance,
      /*await updateDoc(doc(firestore, `/users/${user}`), {
        balance: newBalance,
      }),*/ 'NOT UPDATED',
    )
  }
  console.log(toUpdate)
}

const fixOrdersWithoutTxn = async () => {
  const owner = 'runBA3UHLUf3ACoxyPF0DJU7Upp1'
  const orders = await Order.all([where('owner', '==', owner)])
  const transactions = await User.findTransactions(owner)
  const publisherTransactions: Record<string, any> = {}
  const orphanOrders = []
  for (const order of orders || []) {
    const transaction = transactions.find(
      (i: any) =>
        i.orderId === order.uid ||
        i.description.match(new RegExp(order.uid as string, 'i')),
    )
    if (!transaction) orphanOrders.push(order)
  }
  for (const order of orphanOrders) {
    console.log(
      order.uid,
      order.price,
      order.createdAt.toDateString(),
      order.campaign ? 'CAMPAGNA' : 'NIENTE CAMPAGNA',
    )
    if (!publisherTransactions[order.publisher]) {
      publisherTransactions[order.publisher] = await User.findTransactions(
        order.publisher,
      )
    }
    //ordine orfano, ovvero mancano transazioni collegate a questo ordine
    const advertiserTransaction = {
      status: order.status === 'refunded' ? 'rejected' : 'completed',
      amount: -order.price,
      ...(order.campaign
        ? { campaign: order.campaign, project: order.project }
        : {}),
      type: 'purchase',
      description: `Order #${order.uid}`,
      createdAt: order.createdAt,
      paymentMethod: order.paypalSession
        ? 'paypal'
        : order.stripeSession
        ? 'stripe'
        : 'balance',
      parent: owner,
      orderId: order.uid,
      custom: {
        date: new Date(),
        text: 'AddedBecauseCampaignProblem',
      },
      previousBalance: await User.getBalance(owner),
    }

    if (advertiserTransaction.status === 'completed') {
      await User.addToBalance(
        advertiserTransaction.parent,
        advertiserTransaction.amount,
      )
      const advertiserCurrentBalance = await User.getBalance(
        advertiserTransaction.parent,
      )
      if (
        advertiserCurrentBalance !=
        advertiserTransaction.previousBalance + advertiserTransaction.amount
      )
        console.log(
          'ora è',
          advertiserCurrentBalance,
          'ma doveva esssere',
          advertiserTransaction.previousBalance + advertiserTransaction.amount,
        )
    }

    await User.createTransaction(
      advertiserTransaction.parent,
      advertiserTransaction,
    )

    console.log(advertiserTransaction)

    if (
      publisherTransactions[order.publisher].find(
        (i: any) =>
          i.orderId === order.uid ||
          i.description.match(new RegExp(order.uid as string, 'i')),
      )
    ) {
      console.log('transazione publisher', order.uid)
      continue
    } else {
      const publisherTransaction = {
        createdAt: order.createdAt,
        description: `Order #${order.uid}`,
        orderId: order.uid,
        status:
          order.status === 'refunded'
            ? 'rejected'
            : order.status === 'completed'
            ? 'completed'
            : 'pending',
        amount: order.price - order.price * order.owner_fee,
        parent: order.publisher,
        type: 'order',
        paymentMethod: 'order',
        custom: {
          date: new Date(),
          text: 'AddedBecauseCampaignProblem',
        },
        previousBalance: await User.getBalance(order.publisher),
      }

      if (order.status === 'completed') {
        await User.addToBalance(order.publisher, publisherTransaction.amount)
        const publisherCurrentBalance = await User.getBalance(order.publisher)
        if (
          publisherCurrentBalance !=
          publisherTransaction.previousBalance + publisherTransaction.amount
        )
          console.log(
            'ora è',
            publisherCurrentBalance,
            'ma doveva esssere',
            publisherTransaction.previousBalance + publisherTransaction.amount,
          )
      }

      await User.createTransaction(
        publisherTransaction.parent,
        publisherTransaction,
      )

      console.log(publisherTransaction)
    }
  }

  console.log(orphanOrders.length, 'done')
}

const cancelOrdersByOrderGroup = async () => {
  const orderGroup = prompt('order group', '')
  if (!orderGroup || orderGroup === '') return
  const orders = await Order.all([where('orderGroup', '==', orderGroup)])
  if (!orders) {
    alert('no orders!')
    return
  }
  for (const order of orders) {
    await updateDoc(doc(firestore, `/orders/${order.uid}`), {
      status: 'canceled',
    })
  }
  console.log(`did ${orders.length} orders`)
}

const cancelOrdersByOrderGroupTwo = async () => {
  const orderGroup = prompt('order group', '')
  if (!orderGroup || orderGroup === '') return
  const orders = await Order.all([where('orderGroup', '==', orderGroup)])
  if (!orders) {
    alert('no orders!')
    return
  }

  const ownerTransactions = await User.findTransactions(orders[0].owner)

  for (const order of orders) {
    const publisherTransactions = await User.findTransactions(order.publisher)
    const publisherTransaction = publisherTransactions.find(
      (i: any) => i.orderId === order.uid,
    )

    const ownerTransaction = ownerTransactions.find(
      (i: any) => i.orderId === order.uid,
    )

    if (publisherTransaction) {
      await deleteDoc(
        doc(
          firestore,
          `/users/${order.publisher}/transactions/${publisherTransaction.uid}`,
        ),
      )
    } else console.log('no publisher transaction', order.uid)
    if (ownerTransaction) {
      await deleteDoc(
        doc(
          firestore,
          `/users/${order.owner}/transactions/${ownerTransaction.uid}`,
        ),
      )
    } else console.log('no owner transaction', order.uid)

    await deleteDoc(doc(firestore, `/orders/${order.uid}`))
  }
  alert('finish')
}

const cancelTransactionsByOrderUids = async () => {
  const data = prompt('uids', '')
  const uids = data?.split('\n')
  const users = (await User.all()) as any[]
  const metas = await User.allMetas(users)
  const transactions = metas.reduce((acc, user: any) => {
    return acc.concat(user.meta?.transactions ?? [])
  }, [])

  console.log(transactions)
  ;(window as any).lolo = transactions

  for (const uid of uids ?? []) {
    const transaction = transactions.find((t: any) => t.orderId === uid)
    console.log(transaction)
  }

  console.log('finish')
}

const rejecTransactionByOrderId = async () => {
  const orderId = prompt('orderId', '')
  const order = await getDoc(doc(firestore, `/orders/${orderId}`))
  if (!order.exists) {
    alert('no order')
    return
  }
  const owner = order.data()?.owner
  const transactions = await User.findTransactions(owner)
  const transaction = transactions.find(
    (t: any) => t.orderId === orderId,
  ) as any
  if (!transaction) {
    alert('no transaction')
    return
  }
  if (transaction.status !== 'rejected')
    await updateDoc(
      doc(firestore, `/users/${owner}/transactions/${transaction.uid}`),
      {
        status: 'rejected',
      },
    )

  const publisher = order.data()?.publisher
  const publisherTransactions = await User.findTransactions(publisher)
  const publisherTransaction = publisherTransactions.find(
    (t: any) => t.orderId === orderId,
  ) as any
  if (!publisherTransaction) {
    alert('no publisher transaction')
    return
  }
  if (publisherTransaction.status !== 'rejected')
    await updateDoc(
      doc(
        firestore,
        `/users/${publisher}/transactions/${publisherTransaction.uid}`,
      ),
      {
        status: 'rejected',
      },
    )
  alert('finish')
}

const user = ref<User>()
User.getCurrent().then((res) => (user.value = res))

const fixOldTransactionsAdjustment = async () => {
  const firestore = firebase.getFirestore()

  const users = await User.allLight<User>()
  console.log(users)
  const transactions: Record<User['uid'], Meta['transactions']> = {}

  // Create an array to store all the transaction fetch promises
  const transactionFetchPromises = users.map(async (user) => {
    const userTransactions = (
      (await User.findTransactions(user.uid)) as Meta['transactions']
    ).filter((t) => t.type === 'adjustment' && !t.parent)
    if (userTransactions.length !== 0 && user.uid)
      transactions[user.uid] = userTransactions
  })

  // Wait for all transaction fetch operations to complete
  await Promise.all(transactionFetchPromises)

  console.log(transactions)
  const transactionsLength = Object.values(transactions)
    .map((t) => t.length)
    .reduce((a, b) => a + b, 0)
  store.commit('popup/close')
  if (transactionsLength === 0)
    return alert('Nessuna transazione da aggiustare')
  if (
    !confirm(
      `Ci sono ${transactionsLength} transazioni da aggiustare, guarda in console. Vuoi procedere con il fix?`,
    )
  )
    return

  store.commit('popup/open', { popup: Loader })

  const updatePromises = []

  // Iterate over each user and their transactions
  for (const [userId, userTransactions] of Object.entries(transactions)) {
    for (const transaction of userTransactions) {
      updatePromises.push(
        updateDoc(
          doc(
            collection(firestore, 'users'),
            userId,
            'transactions',
            transaction.uid,
          ),
          {
            parent: userId,
          },
        ),
      )
    }
  }

  // Wait for all update operations to complete
  console.log(await Promise.all(updatePromises))

  store.commit('popup/close')
}

const fixFattureInCloudDown = async () => {
  store.commit('popup/open', { popup: Loader })

  const startDate = new Date('2023-06-26T00:00:00Z')

  const orderQuery = query(
    collection(firestore, '/orders'),
    where('status', '==', 'pending'),
    where('paymentMethod', '==', 'wire'),
    where('createdAt', '>=', startDate),
  )

  const orderQuerySnapshot = await getDocs(orderQuery)
  const orderDocs = orderQuerySnapshot.docs

  const promises = orderDocs.map(async (orderDoc) => {
    const order = orderDoc.data()

    const transactionQuery = query(
      collection(firestore, `users/${order.owner}/transactions`),
      where('orderId', '==', orderDoc.id),
      where('type', '==', 'purchase'),
      where('status', '==', 'pending'),
      limit(1),
    )

    const transactionDocSnap = await getDocs(transactionQuery)
    if (transactionDocSnap.empty) {
      return { id: orderDoc.id, order }
    }
  })

  const results = (await Promise.all(promises)).filter(
    (result) => result !== undefined,
  )

  console.log(results)
  store.commit('popup/close')

  if (
    !confirm(
      `Ci sono ${results.length} ordini da aggiustare, guarda in console. Vuoi procedere con il fix?`,
    )
  )
    return

  store.commit('popup/open', { popup: Loader })
  const ordersWithoutTransactions = results.map((result) => result?.id)

  firebase
    .function('wireOrderFattureincloudDown', ordersWithoutTransactions)
    .then(console.log)
  store.commit('popup/close')
}

const fixFattureInCloudDownCompleted = async () => {
  store.commit('popup/open', { popup: Loader })

  const users = await User.allLight<User>()

  const startDate = new Date('2023-06-26T00:00:00Z')

  const orderQuery = query(
    collection(firestore, '/orders'),
    where('paymentMethod', '==', 'wire'),
    where('createdAt', '>=', startDate),
  )

  const orderQuerySnapshot = await getDocs(orderQuery)
  const orderDocs = orderQuerySnapshot.docs

  const promises = orderDocs.map(async (orderDoc) => {
    const order = orderDoc.data()

    const transactionQuery = query(
      collection(firestore, `users/${order.owner}/transactions`),
      where('orderId', '==', orderDoc.id),
      where('type', '==', 'purchase'),
      limit(1),
    )

    const transactionDocSnap = await getDocs(transactionQuery)
    const transaction: any = transactionDocSnap.docs[0]
      ? {
          uid: transactionDocSnap.docs[0].id,
          ...transactionDocSnap.docs[0].data(),
        }
      : null
    if (
      transaction &&
      transaction.status === 'completed' &&
      !transaction.invoice
    ) {
      return { id: orderDoc.id, order, transaction: transaction }
    } else if (!transaction) {
      console.log(orderDoc.id, 'no transaction')
    }
  })

  const results = (await Promise.all(promises)).filter((result) => {
    return result !== undefined
  })

  const groupedOrders: Record<any, any> = {}
  for (const res of results) {
    if (!res) continue
    if (!groupedOrders[res.order.orderGroup])
      groupedOrders[res.order.orderGroup] = {
        orders: [],
        date: res.order.createdAt.toDate(),
        user: users.find((i) => i.uid === res.order.owner)?.email,
        total: 0,
      }
    groupedOrders[res.order.orderGroup].orders.push(res)
    groupedOrders[res.order.orderGroup].total += res.order.price
  }

  console.log(groupedOrders)

  for (const group in groupedOrders) {
    if (
      !confirm(
        `fixare ${group}? Ordini: ${groupedOrders[group].orders.length} - Utente: ${groupedOrders[group].user}`,
      )
    )
      continue
    const orderGroupToFix = group

    const ordersToFix = groupedOrders[orderGroupToFix].orders
    if (!ordersToFix) return

    const invoiceUrl = prompt('invoice url', '')
    if (!invoiceUrl) return
    for (const o of ordersToFix) {
      console.log(
        await updateDoc(
          doc(
            firestore,
            `/users/${o.order.owner}/transactions/${o.transaction.uid}`,
          ),
          {
            invoice: invoiceUrl,
          },
        ),
      )
    }
  }

  store.commit('popup/close')
}

const bulkDeleteMediasByUser = async () => {
  const uid = prompt(
    "Stai per cancellare tutti i media di un utente. Inserisci l'uid di seguito o annulla",
    '',
  )
  if (!uid || uid === '') return

  const products = await Product.all([where('owner', '==', uid)])
  const pIds = products?.filter((i) => !i.canceled).map((p) => p.uid) ?? []
  for (const pId of pIds) {
    await firebase.function('mediaDelete', {
      product: pId,
    })
    console.log(pId, 'deleted')
  }
  alert('finish!')
}
</script>
