<template>
  <Popup>
    <div class="flex flex-col -mx-8 min-h-[32rem] md:max-h-[32rem]">
      <div class="-mt-8 py-4 px-6 border-b">
        <span v-if="order?.product_collection === 'articles'">{{
          t('copywriting-service')
        }}</span>
        <span v-if="order?.product_collection === 'presses'">{{
          t('press-release')
        }}</span>
        <span v-else>{{ product?.name }}</span> - {{ t(order?.status ?? '')
        }}<br />
        <small class="text-gray-600">{{ order?.uid }}</small
        ><br />
        <template v-if="isAdmin || isSoftAdmin">
          <strong>Advertiser</strong>: {{ chat.advertiser }}<br />
          <strong>Publisher</strong>: {{ chat.publisher }}
        </template><br />
        <small class="text-red-500">{{ t('chat-warning') }}</small>
      </div>
      <div
        ref="msgContainer"
        class="flex flex-col py-4 gap-2 flex-grow flex-shrink overflow-scroll px-6"
      >
        <div v-if="!chat.messages.length">{{ t('no-messages') }}</div>
        <template v-else>
          <template
            v-for="(message, i) of chat.messages"
            :key="message.date.toUTCString()"
          >
            <div
              v-if="
                !chat.messages[i - 1] ||
                chat.messages[i - 1].date.getDay() !== message.date.getDay() ||
                message.date.getTime() - chat.messages[i - 1].date.getTime() >
                  24 * 60 * 60 * 1000
              "
              class="mx-auto rounded-xl bg-gray-100 py-1 px-3 text-gray-600 text-xs"
            >
              {{ message.date.format(false) }}
            </div>
            <div
              v-if="isAdmin || isSoftAdmin"
              class="px-3 py-1 rounded-xl max-w-sm"
              :class="{
                'bg-gray-100 mr-auto rounded-bl':
                  message.from === chat.publisher,
                'static-gradient text-right ml-auto rounded-br':
                  message.from === chat.advertiser,
              }"
            >
              <small class="block">
                <span v-if="message.from === chat.advertiser">{{
                  t('advertiser')
                }}</span>
                <span v-else-if="message.from === chat.publisher">{{
                  t('publisher')
                }}</span>
              </small>
              {{ message.content }}
              <time
                class="block text-left text-[10px] -mt-0.5 -mb-0.5 opacity-75"
                :class="{
                  '': user?.uid !== message.from,
                  'text-right': user?.uid === message.from,
                }"
              >
                {{
                  message.date
                    .format()
                    .split(' ')[1]
                    .split(':')
                    .slice(0, 2)
                    .join(':')
                }}
              </time>
            </div>
            <div
              v-else
              class="px-3 py-1 rounded-xl max-w-sm"
              :class="{
                'bg-gray-100 mr-auto rounded-bl': user?.uid !== message.from,
                'static-gradient text-right ml-auto rounded-br':
                  user?.uid === message.from,
              }"
            >
              {{ message.content }}
              <time
                class="block text-left text-[10px] -mt-0.5 -mb-0.5 opacity-75"
                :class="{
                  '': user?.uid !== message.from,
                  'text-right': user?.uid === message.from,
                }"
              >
                {{
                  message.date
                    .format()
                    .split(' ')[1]
                    .split(':')
                    .slice(0, 2)
                    .join(':')
                }}
              </time>
            </div>
          </template>
        </template>
      </div>
      <div
        v-if="!store.state.popup.args.readonly"
        class="flex items-center gap-4 -mb-8 py-4 px-6 border-t bg-gray-50 rounded-b"
      >
        <textarea
          ref="input"
          v-model="currentMessage"
          type="textarea"
          name=""
          :placeholder="t('chat-placeholder')"
          class="resize-none shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm bg-gray-50 focus:bg-white border border-gray-300 rounded-md flex-grow disabled:bg-gray-100"
          :disabled="loading"
        />
        <button
          :disabled="loading || currentMessage === ''"
          class="disabled:opacity-50"
          @click="send"
        >
          <PaperAirplaneIcon
            class="w-12 h-12 gradient rounded-full p-3 rotate-90"
          />
        </button>
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { PaperAirplaneIcon } from '@heroicons/vue/solid'
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import Chat from '../../models/chat'
import User from '../../models/user'
import Popup from './Popup.vue'
import { State } from '../../store'
import Order from '../../models/order'
import Product from '../../models/product'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore<State>()
const chat = ref<Chat>(store.state.popup.args.chat)
const currentMessage = ref()
const user = ref<User>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')
const order = ref<Order>()
const product = ref<Product>()
Order.find(chat.value.uid).then((res) => {
  order.value = res as Order
  Product.find(order.value.product).then(
    (res) => (product.value = res as Product),
  )
})

const loading = ref(false)
const msgContainer = ref<HTMLDivElement>()
const input = ref<HTMLTextAreaElement>()
onMounted(() => {
  msgContainer.value?.scrollTo(0, 999999)
  if (!store.state.popup.args.readonly) input.value?.focus()
})
const send = async () => {
  if (!currentMessage.value || currentMessage.value.length < 15) {
    alert(t('chat-placeholder'))
    return
  }
  loading.value = true
  await chat.value.sendMessage({
    from: user.value?.uid as string,
    content: currentMessage.value.parseDeniedWords(),
  })
  currentMessage.value = ''
  loading.value = false
  msgContainer.value?.scrollTo(0, 999999)
}
</script>
