<template>
  <div>
    <button
      class="btn bg-red-500 text-white !m-0 text-center"
      @click="exportCsv"
    >
      {{ t('export-all-to-csv') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const props = defineProps<{
  data: object[]
  filename: string
}>()
const exportCsv = () => {
  if (props.data.length === 0) {
    alert(t('no-data'))
    return
  }
  const headers = Object.keys(props.data[0]).join(',')
  const csv = props.data.map((row) => Object.values(row).join(',')).join('\n')
  // Create a blob
  const blob = new Blob([headers, '\n', csv], {
    type: 'text/csv;charset=utf-8;',
  })
  const url = URL.createObjectURL(blob)

  // Create a link to download it
  const pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', `${props.filename}.csv`)
  pom.click()
}
</script>

<style scoped></style>
