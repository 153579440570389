import { DefineComponent } from 'vue'
import { Module } from 'vuex'

export interface PopupState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
  popup?: DefineComponent<{}, {}, any>
  args: any
}

export default {
  namespaced: true,
  strict: true,
  state: {
    popup: undefined,
    args: undefined,
  },
  mutations: {
    open(state, { popup, ...args }: PopupState) {
      state.popup = popup
      state.args = args
    },
    close(state) {
      state.popup = undefined
      state.args = undefined
    },
  },
} as Module<PopupState, any>
