export const trackingSignup = () => {
  try {
    window.dataLayer.push({ event: 'sign_up' })
  } catch (e) {
    console.log(e)
  }
}

export const trackingAddToCart = (item?: any) => {
  try {
    window.dataLayer.push({
      event: 'add_to_cart',
      ...(item && item.collection === 'products'
        ? { items: [{ item_id: item.uid, item_name: item.name }] }
        : { items: [] }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const trackingRemoveFromCart = (item?: any) => {
  try {
    window.dataLayer.push({
      event: 'remove_from_cart',
      ...(item && item.collection === 'products'
        ? { items: [{ item_id: item.uid, item_name: item.name }] }
        : { items: [] }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const trackingBeginCheckout = (items?: any[]) => {
  try {
    window.dataLayer.push({
      event: 'begin_checkout',
      ...(items && items.length
        ? {
            items: [
              items
                .filter((i: any) => i.collection === 'products')
                .map((i: any) => {
                  return {
                    item_id: i.uid,
                    item_name: i.name,
                  }
                }),
            ],
          }
        : { items: [] }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const trackingPurchase = (items?: any[]) => {
  try {
    window.dataLayer.push({
      event: 'purchase',
      ...(items && items.length
        ? {
            value: items
              .filter((i) => i.collection === 'products')
              .reduce((a: number, b: any) => {
                return a + b.price
              }, 0),
            currency: 'EUR',
            items: [
              items
                .filter((i: any) => i.collection === 'products')
                .map((i: any, index: number) => {
                  return {
                    index,
                    quantity: 1,
                    item_id: i.uid,
                    item_name: i.name,
                    price: i.price,
                  }
                }),
            ],
          }
        : { items: [] }),
    })
  } catch (e) {
    console.log(e)
  }
}

export const trackingViewItemList = (items?: any[]) => {
  try {
    window.dataLayer.push({
      event: 'view_item_list',
      ...(items && items.length
        ? {
            items: [
              items
                .filter((i: any) => i.collection === 'products')
                .map((i: any) => {
                  return {
                    item_id: i.uid,
                    item_name: i.name,
                  }
                }),
            ],
          }
        : { items: [] }),
    })
  } catch (e) {
    console.log(e)
  }
}
