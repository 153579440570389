<template>
  <div
    class="group relative flex items-center gap-2 bg-gray-100 px-4 py-2 rounded hover:bg-gray-200"
  >
    <div class="w-6 h-[1.125rem] rounded shadow-sm overflow-hidden">
      <img :src="flags[i18n.locale.value]" class="h-full w-full object-cover" />
    </div>
    <ChevronDownIcon class="w-4 h-4" />
    <div class="absolute top-8 pt-2 left-0 hidden group-hover:block">
      <div
        class="flex-col bg-white min-w-20 rounded shadow overflow-hidden flex"
      >
        <button
          v-for="locale of i18n.availableLocales"
          :key="locale"
          :value="locale"
          href="#"
          class="flex items-center gap-2 py-2 px-4 hover:bg-gray-100 uppercase text-sm"
          @click="changeLocale(locale)"
        >
          <div class="w-6 h-[1.125rem] rounded shadow-sm overflow-hidden">
            <img :src="flags[locale]" class="h-full w-full object-cover" />
          </div>
          {{ locale }}
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ChevronDownIcon } from '@heroicons/vue/outline'
import it from 'flag-icons/flags/4x3/it.svg'
import en from 'flag-icons/flags/4x3/us.svg'
import pt from 'flag-icons/flags/4x3/pt.svg'
import de from 'flag-icons/flags/4x3/de.svg'
import es from 'flag-icons/flags/4x3/es.svg'
import fr from 'flag-icons/flags/4x3/fr.svg'

const flags = { it, en, pt, de, es, fr } as Record<string, string>

const i18n = useI18n()
const changeLocale = (locale: string) => {
  i18n.locale.value = locale
  localStorage.setItem('locale', locale)
}
</script>
