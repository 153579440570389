import { createRouter, createWebHistory } from 'vue-router'
import { firebase } from '../plugins/firebase'
import routes from './routes'

const router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const auth = firebase.getAuth()
  const isLogged = auth.currentUser !== null

  const claims = (await auth.currentUser?.getIdTokenResult())?.claims || {}

  if (
    !isLogged &&
    !(claims.admin || claims.softAdmin) &&
    to.path.startsWith('/app')
  )
    return next({
      name: 'Login',
      query: {
        redirect: to.path,
        ...to.query,
      },
    })

  if (isLogged && claims.admin && (to.path.startsWith('/app/admin') || to.path.startsWith('/app/soft-admin')))
    return next()
  else if (isLogged && claims.admin && !to.path.startsWith('/app/admin'))
    return next({
      path: '/app/admin',
    })
  else if (
    isLogged &&
    claims.softAdmin &&
    to.path.startsWith('/app/soft-admin')
  )
    return next()
  else if (
    isLogged &&
    claims.softAdmin &&
    !to.path.startsWith('/app/soft-admin')
  )
    return next({
      path: '/app/soft-admin',
    })

  return next()
})

export default router
