<template>
  <FiltersVue
    @filter="
      (event) =>
        typeof event === 'string'
          ? (filterByName = event)
          : Object.assign(filtersF, event)
    "
  />
  <div v-if="products.length">
    <ProductItem
      v-for="product in products.slice(
        (pagination.current - 1) * pagination.resultsPerPage,
        ((pagination.current - 1) * pagination.resultsPerPage) + pagination.resultsPerPage
      )"
      :key="product.uid"
      :product="product"
      :users="props.users"
      @update="fetch"
    />
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
  <div v-else>No products to show.</div>
</template>
<script setup lang="ts">
import { limit, orderBy, where } from '@firebase/firestore'
import { onMounted, reactive, ref, watch } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import Pagination from '../Pagination.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'
import FiltersVue from '../Filters.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  users: any
}>()
const { t } = useI18n()
const filterByName = ref<string>('')
const filtersF = reactive({
  price: [0, 5000] as [number, number],
  da: [0, 100] as [number, number],
  ascore: [0, 100] as [number, number],
  za: [0, 100] as [number, number],
  type: '',
  category: '',
  country: '',
  gambling: false,
  cbd: false,
  adult: false,
  trading: false,
  gnews: '',
})

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const last = ref<Product | undefined>(undefined)
const products = ref<Product[]>([])

const common_query = [where('approved', '==', true)]

/*Product.count(common_query).then((total) => {
  pagination.value.total = total
  pagination.value.totalPages = Math.ceil(
    total / pagination.value.resultsPerPage,
  )
})*/

const next = async () => {
  pagination.value.current++
  /*console.log("pagina attuale", pagination.value.current)
  console.log("parto da", (pagination.value.current - 1) * pagination.value.resultsPerPage)
  console.log("per page", pagination.value.resultsPerPage)*/
}

const prev = async () => {
  pagination.value.current--
}


const fetch = async () => {
  /*console.log("pagina attuale", pagination.value.current)
  console.log("parto da", (pagination.value.current - 1) * pagination.value.resultsPerPage)
  console.log("per page", pagination.value.resultsPerPage)*/
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  last.value = products.value[products.value.length - 1]
  const prods = (await Product.searchUnlimited(
    [...common_query, orderBy('createdAt', 'desc'), where('approved', '==', true)],
    filterByName.value !== '' ? filterByName.value : filtersF,
  )) as Product[]
  last.value = prods[prods.length - 1] ?? undefined
  products.value = prods
  pagination.value.current = 1
  pagination.value.total = prods.length
  pagination.value.totalPages = Math.ceil(
    pagination.value.total / pagination.value.resultsPerPage,
  )
  store.commit('popup/close')
}

watch([filtersF, filterByName], () => {
  last.value = undefined
  fetch()
})

onMounted(fetch)
</script>
