<template>
  <div
    :class="[
      ...(grid
        ? ['grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-8']
        : []),
    ]"
  >
    <button
      v-for="(val, i) in values"
      :key="val"
      type="button"
      class="btn ml-0 mr-2 mb-2"
      :class="
        i === modelValue
          ? 'static-gradient text-white'
          : 'bg-gray-100 hover:bg-gray-200'
      "
      @click="$emit('update:modelValue', i)"
    >
      <span v-if="i18n">{{ t(val) }}</span>
      <span v-else>{{ val }}</span>
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps<{
  values: string[]
  modelValue: number
  i18n?: boolean
  grid?: boolean
}>()
defineEmits(['update:modelValue'])
</script>
