import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  setDoc,
} from '@firebase/firestore'
import { firebase } from '../plugins/firebase'
import Model from './model'
import Order from './order'

interface Campaign {
  uid?: string
  name: string
  notes: string
  products: string[]
  setup: {
    date?: Date
    image?: string
    anchor?: string
    publicationguidelines?: string
    extras?: boolean[]
    needArticle: boolean
    words?: number
    link?: number
    lang?: string
    article?: string
    articleFile?: File
    order?: string | Order
    orderArticle?: string | Order
  }[]
  started?: boolean
  completed?: boolean
}

interface ProjectModel {
  uid?: string
  name: string
  domain: string
  notes: string
  owner: string
  campaigns?: Campaign[]
}

export default class Project extends Model implements ProjectModel {
  public collection = 'projects'
  static collection = 'projects'
  public preload = ['campaigns']
  public static preload = ['campaigns']
  public name: string
  public domain: string
  public notes: string
  public owner: string
  public campaigns?: Campaign[]

  public constructor(attributes: {
    uid?: string
    name: string
    domain: string
    notes: string
    owner: string
    campaigns?: Campaign[]
  }) {
    super()
    this.uid = attributes.uid
    this.name = attributes.name
    this.domain = attributes.domain
    this.notes = attributes.notes
    this.owner = attributes.owner
    this.campaigns = attributes.campaigns
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as ProjectModel
    return new this({
      uid: id,
      ...attrs,
    })
  }

  public async upsertCampaign(campaign: Campaign) {
    const firestore = firebase.getFirestore()
    // eslint-disable-next-line prefer-const
    let { uid, ...data } = campaign
    if (!uid)
      uid = (
        await addDoc(
          collection(firestore, `${this.collection}/${this.uid}/campaigns`),
          data,
        )
      ).id
    else
      await setDoc(
        doc(
          collection(firestore, `${this.collection}/${this.uid}/campaigns`),
          uid,
        ),
        data,
        { merge: true },
      )
    return {
      uid,
      ...(
        await getDoc(
          doc(
            collection(firestore, `${this.collection}/${this.uid}/campaigns`),
            uid,
          ),
        )
      ).data(),
    }
  }

  public async deleteCampaign(uid: string) {
    const firestore = firebase.getFirestore()
    await deleteDoc(
      doc(
        collection(firestore, `${this.collection}/${this.uid}/campaigns`),
        uid,
      ),
    )
  }
}
