import { RouteRecordRaw } from 'vue-router'
import admin from './admin'
import softAdmin from './soft-admin'
import AppLayout from '../layouts/AppLayout.vue'
import Login from '../pages/Login.vue'
import Logout from '../pages/Logout.vue'

export default [
  {
    path: '/',
    name: 'Home',
    redirect: () => ({ path: '/app' }),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/app',
    name: 'App',
    component: AppLayout,
    children: [
      ...admin,
      ...softAdmin,
    ],
  },
] as RouteRecordRaw[]
