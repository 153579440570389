<script lang="ts" setup>
import { useFirebase } from '../plugins/firebase'

const firebase = useFirebase()
;(async () => {
  await firebase.getAuth().signOut()
  localStorage.clear()
  location.href = '/'
})()
</script>
