<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ t('press-release') }} {{ product.name }}
        </h4>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
        <div v-if="order.pictureLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="text-sm text-gray-600">
            {{ t('picture-image-link') }}
          </dt>
          <dd class="text-gray-700 text-sm col-span-2">
            {{ order.pictureLink }}
          </dd>
        </div>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <template v-if="order.status === 'work-in-progress'">
    <div v-if="order.publicationguidelines" class="mb-4">
      <h4 class="font-medium text-gray-900 flex">
        {{ t('publication-guidelines') }}:
      </h4>
      <p class="mt-2 text-sm text-gray-600 max-w-2xl">
        {{ order.publicationguidelines }}
      </p>
    </div>
    <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
    <template v-if="currentArticle">
      <hr class="my-4" />
      <a :href="currentArticle" class="btn gradient">
        {{ t('download-article') }}
      </a>
      <div>
        <FormRow
          v-model="info"
          :label="t('press-info')"
          name="press-info"
          type="textarea"
          :placeholder="t('press-info-placeholder')"
        />
      </div>
    </template>
    <template v-else>
      {{ t('press-order-article-missing-publisher') }}
    </template>
    <div v-if="currentArticle" class="flex justify-end items-center gap-2 mt-6">
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        :disabled="loading"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
  </template>
  <div
    v-else-if="order.status === 'paid' && order.article"
    class="flex justify-end items-center gap-2 mt-6"
  >
    <button
      :disabled="loading"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="() => orderDone()"
    >
      {{ t('take-order') }}
    </button>
  </div>
  <div
    v-else-if="order.status === 'need-review' || order.status === 'completed'"
  >
    {{ order.info }}
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import { ref as storageRef, getDownloadURL, listAll } from '@firebase/storage'
import { useFirebase } from '../../plugins/firebase'
import Press from '../../models/press'
import FormRow from '../form/FormRow.vue'

const { t } = useI18n()
const store = useStore<State>()

const props = defineProps<{
  product: Press
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderDone: Function
}>()
const order = store.state.popup.args.order as Order

const firebase = useFirebase()
const storage = firebase.getStorage()

const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'presses/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.uid as string),
  )
  if (el)
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `presses/${el.name}`),
    )
}
const info = ref(order.info)
getCurrentArticle()
const done = ref(false)
const loading = ref(false)
const save = async () => {
  loading.value = true
  order.info = info.value
  await firebase.function('orderChangeNotification', { order: order.uid })
  await order.save()
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  if (order.status === 'work-in-progress') props.orderDone()
  setTimeout(() => (done.value = false), 2000)
}
</script>
