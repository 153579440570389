<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ product.name }}
          <div class="flex gap-1 mx-2">
            <img
              v-for="ty of types"
              :key="(ty as any)"
              :src="`/assets/icons/${ty}.svg`"
              width="16"
              height="16"
            />
          </div>
        </h4>
        <a
          v-if="product.website[0]"
          :href="
            !product.website[0].match(/http/i)
              ? `https://${product.website[0]}`
              : product.website[0]
          "
          class="underline text-sm"
          target="_BLANK"
          >{{ product.website[0] }}</a
        >
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
        <div
          v-if="(order.product_object as Product).publicationguidelines"
          class="mb-4"
        >
          <h4 class="mt-2 text-sm text-gray-600 max-w-2xlx">
            {{ t('publication-guidelines') }}:
          </h4>
          <p
            class="mt-2 text-sm text-gray-600 max-w-2xl"
            v-html="(order.product_object as Product).publicationguidelines"
          ></p>
        </div>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <div class="my-6 divide-y-2 space-y-4">
    <dl class="flex flex-col sm:gap-4 lg:gap-6 text-sm">
      <div
        v-for="(extra, i) in product.extras ?? []"
        :key="extra.name"
        class="grid grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">{{ extra.name }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ (order.extras ?? [])![i] ? t('yes') : t('no') }}
        </dd>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('anchor') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.anchor }}</dd>
      </div>
      <div v-if="order.link" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('link') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.link }}</dd>
      </div>
      <div
        v-if="order.publishDate"
        class="grid grid-cols-1 lg:grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">
          {{ t('preferred-publish-date') }}
        </dt>
        <dd class="text-gray-700 col-span-2">
          {{ order.publishDate?.format(false) }}
        </dd>
      </div>

        <FormRow
          v-if='needChange'
          :model-value="order.articleLink"
          @update:model-value="newUrl = $event"
          name="article-link"
          :label="t('insert-article-link')"
          type="url"
        />

      <div
          v-if="order.articleLink && !needChange"
          class="grid grid-cols-1 lg:grid-cols-3 gap-4"
        >
          <dt class="font-medium text-gray-900">{{ t('article-link') }}</dt>
          <dd class="text-gray-700 col-span-2 flex items-center gap-4">
            <a
              :href="order.articleLink"
              target="_blank"
              class="text-primary-500  hover:text-primary-600 hover:underline"
            >
              {{ order.articleLink }}
            </a>
            <span @click='needChange = true' class='cursor-pointer block bg-secondary-500 h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white'>
              <PencilIcon class='h-4 w-4'/>
            </span>
          </dd>
        </div>

      <div
        v-if="order.pictureLink"
        class="grid grid-cols-1 lg:grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">{{ t('picture-link') }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ order.pictureLink }}
        </dd>
      </div>
    </dl>
  </div>
  <div v-if="order.info" class="mb-4">
    <h4 class="font-medium text-gray-900 flex">{{ t('order-info') }}:</h4>
    <p class="mt-2 text-sm text-gray-600 max-w-2xl">{{ order.info }}</p>
  </div>
  <div v-if="order.status === 'paid'">
    <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
    <div v-if="!changing && currentArticle">
      <div class="my-4 text-emerald-500">{{ t('article-received') }}</div>
      <a :href="currentArticle" class="btn primary m-0">
        {{ t('download-article') }}
      </a>
      <a href="" class="btn secondary" @click.prevent="changing = !changing">
        {{ t('change-article') }}
      </a>
    </div>
    <FormRow
      v-if="changing || !currentArticle"
      label=""
      type="file"
      accepts=".doc,.docx"
      @change="(e: any) => {
        article = e as unknown as File;
        order.advertiserInfo = false
        order.article = false
      }"
    />
    <FormRow
      v-model="info.anchor"
      name="anchor"
      :label="t('anchor')"
      type="text"
    />
    <FormRow v-model="info.link" name="link" :label="t('link')" type="text" />
    <FormRow
      v-model="info.pictureLink"
      name="picture-link"
      :label="t('picture-link')"
      type="text"
    />
    <FormRow
      v-if="info.pictureLink"
      name="picture-terms"
      :label="t('picture-terms')"
      required
    >
      <label>
        <input v-model="info.accept" type="checkbox" class="rounded mr-1" />
        {{ t('picture-accept-terms') }}
      </label>
    </FormRow>
    <FormRow
      v-model="info.publishDate"
      name="publish-date"
      :label="t('preferred-publish-date')"
      type="date"
    />
  </div>
  <div
    v-if="
      order.status !== 'work-in-progress' &&
      order.status !== 'need-review' &&
      order.status !== 'completed'
    "
    class="flex justify-end items-center gap-2 mt-6"
  >
    <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
    <button
      v-if="
        (article && !order.advertiserInfo) ||
        (order.advertiserInfo && changing && article) ||
        isAdmin ||
        isSoftAdmin
      "
      :disabled="loading || (!!info.pictureLink && !info.accept)"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="save"
    >
      {{ t('save') }}
    </button>
  </div>
  <div class='flex justify-end items-center mt-6'>
    <button
      v-if="needChange"
      class="flex items-center gap-1 btn bg-secondary-500 text-white disabled:opacity-50"
      @click="updateUrl"
    >
      {{ t('save') }}
    </button>
    <div
      v-if="order.status === 'need-review' || order.status === 'completed'"
      class="flex  gap-2 "
    >
      <button
        v-if="order.status === 'need-review' || isAdmin"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderRefused"
      >
        {{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}
      </button>
      <button
        v-if="order.status === 'need-review'"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderDone"
      >
        {{ t('confirm-order') }}
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import Product from '../../models/product'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import { computed } from '@vue/reactivity'
import FormRow from '../form/FormRow.vue'
import { useFirebase } from '../../plugins/firebase'
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
  listAll,
  deleteObject,
} from '@firebase/storage'
import LoaderVue from '../Loader.vue'
import { useRoute } from 'vue-router'
import { PencilIcon } from '@heroicons/vue/solid'

const { t } = useI18n()
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const props = defineProps<{
  product: Product
  orderDone: () => void
  orderRefused: () => void
}>()

const order = ref<Order>(store.state.popup.args.order as Order)
const orderDone = store.state.popup.args.orderDone
const types = computed(() =>
  [
    'website',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'reddit',
    'telegram',
  ].filter(
    (type) => (props.product as unknown as Record<string, []>)[type]?.length,
  ),
)
const newUrl = ref<string>('')
const needChange = ref<boolean>(false)
const updateUrl = async () => {
  store.commit('popup/open', { popup: LoaderVue })
  loading.value = true

  order.value.articleLink = newUrl.value
  needChange.value = true
  await order.value.save()
  await getCurrentArticle()
  loading.value = false
  setTimeout(() => {
    done.value = false
    store.commit('popup/close')
  }, 2000)
}


const firebase = useFirebase()
const storage = firebase.getStorage()
const erroredFile = ref<boolean>(false)

const info = ref({
  publishDate: order.value.publishDate?.toISOString().split('T')[0],
  link: order.value.link,
  anchor: order.value.anchor,
  pictureLink: order.value.pictureLink,
  accept: order.value.pictureLink ? true : false,
})
const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'articles/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.value.uid as string),
  )
  if (el) {
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `articles/${el.name}`),
    )
  } else erroredFile.value = true
}
getCurrentArticle()
const article = ref<File>()
const done = ref(false)
const loading = ref(false)
const save = async () => {
  if (!article.value && !order.value.article) {
    console.log(article.value)
    alert('missing article')
    return
  }
  store.commit('popup/open', { popup: LoaderVue })
  loading.value = true
  if (article.value && !order.value.article) {
    const list = await listAll(storageRef(storage, 'articles/'))
    list.items.forEach((item) =>
      item.name.startsWith(order.value.uid as string)
        ? deleteObject(storageRef(storage, `articles/${item.name}`))
        : void 0,
    )
    await uploadBytes(
      storageRef(
        storage,
        `articles/${order.value.uid}.${article.value.name.split('.').pop()}`,
      ),
      await article.value.arrayBuffer(),
    )
    order.value.article = true
  }
  order.value.advertiserInfo = true
  order.value.advertiserInfoDate = new Date()
  order.value.link = info.value.link
  order.value.anchor = info.value.anchor
  order.value.pictureLink = info.value.pictureLink
  order.value.publishDate = info.value.publishDate
    ? new Date(info.value.publishDate)
    : undefined
  await order.value.save()
  await firebase.function('orderChangeNotification', { order: order.value.uid })
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  setTimeout(() => {
    done.value = false
    store.commit('popup/close')
  }, 2000)
}
</script>
