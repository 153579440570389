<template>
  <Popup>
    <h2>Export Orders</h2>
    <hr class="my-2" />
    <form @submit.prevent="exportToCSV">
      <FormRow
        v-model="status"
        type="select"
        :label="t('status')"
        :required="true"
      >
        <option v-for="status in statuses" :key="status" :value="status">
          {{ t(status) }}
        </option></FormRow
      >
      <FormRow
        v-model="date_from"
        type="date"
        :label="t('from')"
        :required="true"
      />
      <FormRow
        v-model="date_to"
        type="date"
        :label="t('to')"
        :required="true"
      />
      <div class="text-right">
        <button class="mt-4 btn gradient">
          {{ t('export') }}
        </button>
      </div>
    </form>
  </Popup>
</template>
<script lang="ts" setup>
import { useFirebase } from '../../plugins/firebase'
import Popup from './Popup.vue'
import Order from '../../models/order'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import FormRow from '../form/FormRow.vue'
import { useStore } from 'vuex'
import { State } from '../../store'
import LoaderVue from '../Loader.vue'

const store = useStore<State>()
const { t } = useI18n()
const firebase = useFirebase()
const statuses = [
  'all',
  'pending',
  'paid',
  'work-in-progress',
  'need-review',
  'completed',
  'refunded',
  'canceled',
]
const status = ref<Order['status'] | 'all'>('all')
const date_from = ref<string>()
const date_to = ref<string>()

const exportToCSV = async() => {
  try {
    //store.commit('popup/open', { popup: LoaderVue })
    const res = await firebase.function('exportOrdersToCSV', {
        status: status.value,
        date_from: date_from.value,
        date_to: date_to.value
    })
    const tmp_a = document.createElement('a')
    tmp_a.href = window.URL.createObjectURL(
      new Blob([(res as string[]).join('\n')]),
    )
    tmp_a.download = 'export-orders-' + date_from.value + '-' + date_to.value + '.tsv'
    tmp_a.click()
    //store.commit('popup/close')
  } catch (e) {
    console.error(e)
    alert('fai screenshot console')
  }
}
</script>
