<template>
  <div>
    <Heading :text="t('logs')" />
    <div class="overflow-x-scroll">
      <table class="relative w-full text-sm whitespace-nowrap">
        <tr>
          <th
            v-for="col of ['status', 'date', 'user', 'message', 'function']"
            :key="col"
            class="h-20 p-0 font-semibold text-center"
          >
            <div class="h-20 p-0 pt-10 bg-white">
              <div
                class="w-full h-10 p-2 bg-gray-100 border font-bold text-center whitespace-nowrap"
              >
                <span>{{ t(col) }}</span>
              </div>
            </div>
          </th>
        </tr>
        <tr v-for="log of logs" :key="log.uid">
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              <AnimatePing
                title="Log status"
                :class="{
                  'bg-yellow-500': !log.solved,
                  'bg-emerald-500': log.solved,
                }"
              />
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{ log.createdAt.format(true) }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{
                users.find((i) => i.uid === log.user)?.displayName || log.user
              }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              <template v-if="typeof log.message !== 'string'">
                {{ log.message.message }}
                <a
                  class="underline cursor-pointer"
                  @click="openLogPopup(log.message)"
                  >show more</a
                >
              </template>
              <template v-else>
                {{ log.message }}
              </template>
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{ log.function }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Heading from '../../components/Heading.vue'
import AnimatePing from '../../components/AnimatePing.vue'
import store from '../../store'
import LoaderVue from '../../components/Loader.vue'
import Log from '../../models/log'
import LogPopup from '../../components/popups/LogPopup.vue'
import User from '../../models/user'

const i18n = useI18n()
const { t } = i18n
const logs = ref<Log[]>([])
const users = ref<User[]>([])
// const firebase = useFirebase()

const getLogs = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  users.value = (await User.allLight()) as User[]
  logs.value = (await Log.all()) as Log[]
  store.commit('popup/close')
}

const openLogPopup = (message: any) => {
  store.commit('popup/open', {
    popup: LogPopup,
    message: message,
  })
}

onMounted(getLogs)
</script>
