import { DocumentData, Timestamp } from '@firebase/firestore'
import Model from './model'

interface PressModel {
  uid?: string
  owner: string
  name: string
  short_description: string
  description: string
  price: number
  languages: string[]
  createdAt: Date
}

export default class Press extends Model implements PressModel {
  public collection = 'presses'
  static collection = 'presses'

  public owner: string
  public name: string
  public short_description: string
  public description: string
  public price: number
  public languages: string[]
  createdAt: Date

  public constructor(attributes: PressModel) {
    super()
    this.uid = attributes.uid
    this.name = attributes.name
    this.short_description = attributes.short_description
    this.description = attributes.description
    this.price = attributes.price
    this.owner = attributes.owner
    this.languages = attributes.languages
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as PressModel
    return new this({
      uid: id,
      ...attrs,
    })
  }
}
