<template>
  <Heading :text="product.uid ? t('edit-product') : t('add-product')" />
  <form ref="form" @submit.prevent="save">
    <div>
      <FormRow
        v-model="product.owner"
        name="owner"
        :label="t('owner')"
        type="vselect"
        :v-select-value="
          users
            ?.filter((i) => i.uid === product.owner)
            ?.map((i) => ({
              value: i.uid,
              label: i.displayName + ' - ' + i.email,
            }))[0]
        "
        required
        :options="
          users?.map((i) => ({
            value: i.uid,
            label: i.displayName + ' - ' + i.email,
          }))
        "
      />
      <FormRow
        :label="t('screenshot')"
        type="file"
        accepts=".jpg,.png"
        info="project-screenshot"
        :current-image="currentScreenshot"
        @change="screenshot = $event as unknown as File"
      />
      <FormRow
        v-model="product.country"
        name="country"
        :label="t('language')"
        :v-select-value="
          countries
            .filter((i) => i.code === product.country)
            .map((i) => ({ value: i.code, label: i.name }))[0]
        "
        :sublabel="
          product.old_object?.country !== product.country
            ? '<span class=\'text-red-500\'>Old value: ' +
              product.old_object.country +
              '</span>'
            : ''
        "
        type="vselect"
        info="product-language"
        :options="countries.map((i) => ({ label: i.name, value: i.code }))"
        required
      />
      <FormRow
        v-model="product.type"
        name="type"
        info="product-media-type"
        :label="t('media-type')"
        type="select"
        required
      >
        <option value="" disabled selected>{{ t('select-type') }}</option>
        <option value="blog">{{ t('blog') }}</option>
        <option value="newspaper">{{ t('newspaper') }}</option>
        <option value="social">{{ t('social') }}</option>
      </FormRow>
      <FormRow
        v-if="product.categories"
        v-model="product.categories"
        name="categories"
        info="product-category"
        :label="t('categories')"
        :v-select-value="
          product.categories.map((i) => ({ value: i, label: t(i) }))
        "
        type="vselect"
        :multiple="true"
        :options="
          categories
            .sort((a, b) => a.localeCompare(b))
            .map((i) => ({ value: i, label: t(i) }))
        "
        required
        :sublabel="
          product.categories?.join(',') !== product.old_object.categories?.join(',')
            ? product.old_object.categories?.map((i: string) => t(i)).join(', ') : ''
        "
      />
      <FormRow
        v-model="product.website[0]"
        name="website"
        info="product-"
        :label="t('Website')"
        :placeholder="handles.website"
        type="text"
      />
      <FormRow
        v-model="product.name"
        name="name"
        info="product-media-name"
        :label="t('media-name')"
        type="text"
        required
      />
      <FormRow
        v-model="product.price"
        name="price"
        :label="t('price')"
        info="product-price"
        type="number"
        step="0.01"
        :sublabel="
          product.price && product.price > 0
            ? t('rankister-commission') +
              ': &euro; ' +
              (product?.price * orderFee).format(2) +
              '&emsp;' +
              t('net-price') +
              ': &euro; ' +
              (product?.price - product?.price * orderFee)?.format(2) +
              (product.old_object?.price != product.price
                ? '<span class=\'block text-red-500\'>Old value: &euro; ' +
                  (product.old_object?.price || 0).format() +
                  '</span>'
                : '')
            : ''
        "
        required
      />
      <FormRow
        v-model="product.description"
        name="description"
        :label="t('description')"
        info="product-description"
        :placeholder="t('product-description-placeholder')"
        type="editor"
        :sublabel="
          product.old_object?.description != product.description
            ? '<span class=\'text-red-500\'>Old value: ' +
              product.old_object.description +
              '</span>'
            : ''
        "
      />
      <FormRow
        v-model="product.publicationguidelines"
        name="publication-guidelines"
        :label="t('publication-guidelines')"
        info="product-publication-guidelines"
        :placeholder="t('product-publication-guidelines-placeholder')"
        type="editor"
        :sublabel="
          product.old_object?.publicationguidelines !=
          product.publicationguidelines
            ? '<span class=\'text-red-500\'>Old value: ' +
              product.old_object.publicationguidelines +
              '</span>'
            : ''
        "
      />
      <FormRow
        v-model="product.publicationduration"
        name="publication-duration"
        :label="t('publication-duration')"
        info="product-publication-duration"
        type="select"
        :sublabel="
          product.old_object?.publicationduration != product.publicationduration
            ? '<span class=\'text-red-500\'>Old value: ' +
              product.old_object.publicationduration +
              '</span>'
            : ''
        "
      >
        <option value="" disabled selected>
          {{ t('select-publication-duration') }}
        </option>
        <option
          v-for="duration of ['1-year', '2-years', 'unlimited']"
          :key="duration"
          :value="duration"
        >
          {{ t('duration-' + duration) }}
        </option>
      </FormRow>
      <FormRow
        v-model="product.avgpubtime"
        name="publication-time"
        :label="t('average-publication-time')"
        info="product-average-publication-time"
        type="select"
        :sublabel="
          product.old_object?.avgpubtime != product.avgpubtime
            ? '<span class=\'text-red-500\'>Old value: ' +
              product.old_object.avgpubtime +
              '</span>'
            : ''
        "
      >
        <option value="" disabled selected>
          {{ t('select-publication-time') }}
        </option>
        <option v-for="time of ['24h', '48h', '96h']" :key="time" :value="time">
          {{ t('time-' + time) }}
        </option>
      </FormRow>
      <!--<FormRow
        :model-value="product.writeArticle ? product.writeArticlePrice : ''"
        name="write-article-price"
        :label="t('write-article-price')"
        type="number"
        step="0.01"
        :disabled="!product.writeArticle"
        @change="product.writeArticlePrice = Number(($event.target as HTMLInputElement).value)"
      >
        <label for="write-article" class="flex items-center mt-2">
          <input
            id="write-article"
            :value="product.writeArticle"
            name="write-article"
            type="checkbox"
            class="rounded mr-2 text-primary-500 focus:ring-primary-500"
            @change="product.writeArticle = !($event.target as HTMLInputElement).checked"
          />
          {{ t('not-offered') }}
        </label>
      </FormRow>-->
      <FormRow
        v-model="product.extras"
        :types="['text', 'number']"
        name="extra-price"
        info="product-extra"
        :label="t('extra')"
        step="0.01"
      />
      <div class="my-2 text-md">
        <div
          v-for="e in product.extras.filter((i) => i.name !== '')"
          :key="e.name"
          class="flex items-center gap-3"
        >
          <strong>{{ e.name }}</strong>
          <span
            >&euro;
            {{ parseFloat(e.price as unknown as string)?.format(2) }}</span
          >
        </div>
      </div>
      <FormRow
        v-model="(product as unknown as Record<string, string[]>).facebook"
        name="facebook"
        info="product-"
        :label="t('Facebook')"
        :placeholder="handles.facebook"
        type="text"
      />
      <FormRow
        v-model="(product as unknown as Record<string, string[]>).instagram"
        name="instagram"
        info="product-"
        :label="t('Instagram')"
        :placeholder="handles.instagram"
        type="text"
      />
      <FormRow
        v-model="(product as unknown as Record<string, string[]>).tiktok"
        name="tiktok"
        info="product-"
        :label="t('TikTok')"
        :placeholder="handles.tiktok"
        type="text"
      />
      <FormRow
        v-model="(product as unknown as Record<string, string[]>).reddit"
        name="reddit"
        info="product-"
        :label="t('Reddit')"
        :placeholder="handles.reddit"
        type="text"
      />
      <FormRow
        v-model="(product as unknown as Record<string, string[]>).telegram"
        name="telegram"
        info="product-"
        :label="t('Telegram')"
        :placeholder="handles.telegram"
        type="text"
      />
      <FormRow :label="t('sensitive-topics')" info="product-sensitive-topics">
        <div class="grid grid-cols-2 xl:grid-cols-4">
          <div class="flex items-center my-2">
            <input
              id="gambling"
              v-model="product.accepts.gambling"
              name="gambling"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="gambling" class="ml-2 block text-sm text-gray-900">
              {{ t('gambling') }}
            </label>
          </div>
          <div class="flex items-center my-2">
            <input
              id="cbd"
              v-model="product.accepts.cbd"
              name="cbd"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="cbd" class="ml-2 block text-sm text-gray-900">
              {{ t('cbd') }}
            </label>
          </div>
          <div class="flex items-center my-2">
            <input
              id="adult"
              v-model="product.accepts.adult"
              name="adult"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="adult" class="ml-2 block text-sm text-gray-900">
              {{ t('adult') }}
            </label>
          </div>
          <div class="flex items-center my-2">
            <input
              id="trading"
              v-model="product.accepts.trading"
              name="trading"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="trading" class="ml-2 block text-sm text-gray-900">
              {{ t('trading') }}
            </label>
          </div>
        </div>
      </FormRow>
      <FormRow :label="t('google-news')" info="product-google-news">
        <div class="grid grid-cols-2 xl:grid-cols-4">
          <div class="flex items-center my-2">
            <input
              id="google_news"
              v-model="product.google_news"
              name="google_news"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="google_news" class="ml-2 block text-sm text-gray-900">
              {{ t('yes') }}
            </label>
          </div>
        </div>
      </FormRow>
      <FormRow :label="t('admin-functions')">
        <div class="grid grid-cols-2 xl:grid-cols-4">
          <div class="flex items-center my-2">
            <input
              id="adminFeatured"
              v-model="product.adminFeatured"
              name="adminFeatured"
              type="checkbox"
              class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
            />
            <label for="adminFeatured" class="ml-2 block text-sm text-gray-900">
              {{ t('admin-featured') }}
            </label>
          </div>
        </div>
      </FormRow>
    </div>
    <Banner v-if="duplicate">{{
      t('product-already-in-our-catalog-contact-us-if-you-re-owner')
    }}</Banner>
    <div class="flex justify-end">
      <button class="btn gradient mt-4">{{ t('save-product') }}</button>
    </div>
  </form>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { State } from '../../store'
import { useRoute, useRouter } from 'vue-router'
import Heading from '../../components/Heading.vue'
import Product from '../../models/product'
import User from '../../models/user'
import FormRow from '../../components/form/FormRow.vue'
import { useI18n } from 'vue-i18n'
import categories from '../../plugins/categories'
import Banner from '../../components/Banner.vue'
import Setting from '../../models/setting'
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
} from '@firebase/storage'
import countries from '../../plugins/countries'
import LoaderVue from '../../components/Loader.vue'
import { useFirebase } from '../../plugins/firebase'

const screenshot = ref<File>()
const currentScreenshot = ref<string>()
const i18n = useI18n()
const { t } = i18n
const route = useRoute()
const duplicate = ref<boolean>(false)
const orderFee = ref<number>(0)
const firebase = useFirebase()
const storage = firebase.getStorage()
const store = useStore<State>()
const users = ref<User[]>()
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const handles = {
  Website: 'Domain Name',
  Facebook: 'URL',
  Instagram: 'URL',
  Youtube: 'URL',
  Tiktok: 'URL',
  Reddit: 'URL',
  Telegram: 'Username',
} as Record<string, string>
const tabs = Object.keys(handles)

const inputs = tabs.reduce(
  (acc, type) => ({ ...acc, [type.toLowerCase()]: [] }),
  {
    price: '',
    extras: [{ name: '', price: '' }] as { name: string; price: string }[],
    writeArticlePrice: 0,
    writeArticle: false,
    accepts: {
      gambling: false,
      cbd: false,
      adult: false,
      trading: false,
    },
    metrics: {},
    updates: {},
    updated: false,
    approved: false,
    screenshot: '',
    google_news: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    adminFeatured: false,
  },
) as Record<string, unknown>

const product = ref<Product>(new Product(inputs as unknown as Product))


onMounted(() => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  Promise.all([
    Product.find(route.params.id as string),
    User.getCurrent(),
    Setting.findOrCreate('order-fee'),
    User.allLight()
  ]).then(([prod, user, orderfee, usrs]) => {
    users.value = usrs as User[]
    orderFee.value = user?.meta?.orderFee || orderfee.value
    product.value =
      prod ??
      new Product({
        ...inputs,
        owner: user.uid,
        stats: {
          orders: 0,
          revenue: 0,
        },
      } as Product)
    if (product.value.uid && product.value.screenshot.includes('custom-')) {
      ;(async () => {
        currentScreenshot.value = await getDownloadURL(
          storageRef(storage, product.value.screenshot),
        )
      })()
    }
    if (!product.value.categories.length && product.value.category)
      product.value.categories = [product.value.category]
    if (product.value.extras.length === 0)
      product.value.extras = [
        { name: '', price: '' },
      ] as unknown as typeof product.value.extras
    if (product.value.old_object && product.value.old_object.old_object)
      delete product.value.old_object.old_object

    store.commit('popup/close')
  })
})

const router = useRouter()
const form = ref<HTMLFormElement>()

const save = async () => {
  if (!form.value?.reportValidity()) return
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const query = product.value.uid ? { updated: '' } : { created: '' }
  product.value.price = Number(product.value.price)
  product.value.writeArticlePrice =
    +product.value.writeArticle * Number(product.value.writeArticlePrice ?? 0)
  product.value.extras = product.value.extras
    .filter((el) => el.name && el.price !== undefined)
    .map((el) => ({
      name: el.name,
      price: Number(el.price),
    }))

  if (product.value.website && product.value.website[0] === '')
    product.value.website = []

  for (let k in product.value.website) {
    if (!product.value.website[k].match(/http/i))
      product.value.website[k] = `https://${product.value.website[k]}`
  }

  if (product.value?.uid) {
    product.value.updatedAt = new Date()
    await product.value?.save()
    router.push({
      path: isAdmin
        ? '/app/admin/products'
        : isSoftAdmin
        ? '/app/soft-admin/products'
        : '/app/publisher/products',
      query,
    })
  } else {
    const duplicates = await new Promise((resolve) => {
      Product.all().then((prods) => resolve(prods))
    })
    const check = (duplicates as Product[]).filter(
      (i) => i.website[0] === product.value.website[0],
    )
    if (check.length > 0) {
      duplicate.value = true
    } else {
      await product.value?.save()
    }
  }
  if (screenshot.value) {
    await uploadBytes(
      storageRef(
        storage,
        `websites/custom-${product.value.uid}.${screenshot.value.name
          .split('.')
          .pop()}`,
      ),
      await screenshot.value.arrayBuffer(),
    )
    product.value.screenshot = `websites/custom-${
      product.value.uid
    }.${screenshot.value.name.split('.').pop()}`
    await product.value.save()
  }
  router.push({
    path: isAdmin
      ? '/app/admin/products'
      : isSoftAdmin
      ? 'app/soft-admin/products'
      : '/app/publisher/products',
    query,
  })
  store.commit('popup/close')
}
</script>
