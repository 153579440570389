import { App, inject } from 'vue'

const id = Symbol('storage')

class Storage {
  private storage: any | null

  public constructor() {
    this.storage = {}
  }

  public set(key: string, value: unknown) {
    return localStorage.setItem(key, JSON.stringify(value))
  }

  public async get(key: string): Promise<unknown> {
    return localStorage.getItem(key)
  }

  public async has(key: string) {
    return this.get(key) !== null
  }

  public del(key: string) {
    return localStorage.removeItem(key)
  }

  public keys() {
    return Object.keys(localStorage)
  }

  public clear() {
    return localStorage.clear()
  }
}

export const storage = new Storage()

export const useStorage = () => inject<Storage>(id) as Storage

export default () => ({
  install(app: App) {
    app.provide(id, storage)
  },
})
