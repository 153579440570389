import { App, inject } from 'vue'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from '@firebase/auth'
import {
  getFirestore,
  connectFirestoreEmulator,
  enableIndexedDbPersistence,
  initializeFirestore,
  CACHE_SIZE_UNLIMITED,
} from '@firebase/firestore'
import { getStorage, connectStorageEmulator } from '@firebase/storage'
import {
  getFunctions,
  connectFunctionsEmulator,
  HttpsCallableOptions,
  httpsCallable,
} from '@firebase/functions'
import UnexpectedError from '../components/popups/UnexpectedError.vue'
import { useStore } from 'vuex'

const id = Symbol('firebase')

class Firebase {
  private firebase
  private auth
  private firestore
  private storage
  private functions

  constructor(config: FirebaseOptions) {
    this.firebase = initializeApp(config)

    this.auth = getAuth(this.firebase)
    //this.firestore = getFirestore(this.firebase)
    this.firestore = initializeFirestore(this.firebase, {
      useFetchStreams: false,
    } as any)
    this.storage = getStorage(this.firebase)
    this.functions = getFunctions(this.firebase, 'europe-west1')

    if (import.meta.env.VITE_ENV === 'local') {
      console.info('DEBUGGING: Using Firebase Emulators')
      connectAuthEmulator(this.auth, 'http://localhost:9099')
      connectFirestoreEmulator(this.firestore, 'localhost', 8080)
      connectStorageEmulator(this.storage, 'localhost', 9199)
      connectFunctionsEmulator(this.functions, 'localhost', 5001)
    }

    /*enableIndexedDbPersistence(this.firestore).catch((err) => {
      console.log('cacheee', err.code)
    })*/
  }

  public getAuth() {
    return this.auth
  }

  public getFirestore() {
    return this.firestore
  }

  public getStorage() {
    return this.storage
  }

  public function<R, T = unknown>(
    name: string,
    input?: T,
    options?: HttpsCallableOptions,
  ) {
    return new Promise<R>((resolve, reject) => {
      const fn = httpsCallable<T, R & { status: string }>(
        this.functions,
        name,
        options,
      )
      fn(input)
        .then((response) => {
          //console.info(name, response)
          const { data } = response
          if (data?.status === 'error') reject(data)
          else resolve(data)
        })
        .catch((err) =>
          import('../store').then((store) => {
            store.default.commit('popup/open', {
              popup: UnexpectedError,
              err,
            })
            reject(err)
          }),
        )
    })
  }
}

export const firebase = new Firebase(
  import.meta.env.DEV &&
  import.meta.env.VITE_ENV !== 'local' &&
  import.meta.env.VITE_ENV !== 'prod'
    ? {
        apiKey: 'AIzaSyBtuBJBRgRNpEAn4O_agUTk1OUtzRc8lc4',
        authDomain: 'rankister-dev.firebaseapp.com',
        projectId: 'rankister-dev',
        storageBucket: 'rankister-dev.appspot.com',
        messagingSenderId: '759741257783',
        appId: '1:759741257783:web:f3dfeb2f509508f39c47b2',
      }
    : {
        apiKey: 'AIzaSyDs8Af4sNAeaPzzdJ6dHDkYGKwhUcVUIkY',
        authDomain: 'rankister-9d391.firebaseapp.com',
        projectId: 'rankister-9d391',
        storageBucket: 'rankister-9d391.appspot.com',
        messagingSenderId: '23472454122',
        appId: '1:23472454122:web:f6c6f9e54f815777541a93',
      },
)

export const useFirebase = () => inject<Firebase>(id) as Firebase

export default () => ({
  install(app: App) {
    app.provide(id, firebase)
  },
})
