import { Module } from 'vuex'

export interface SnackbarState {
  text: string | null
}

export default {
  namespaced: true,
  strict: true,
  state: {
    text: null,
  },
  mutations: {
    open(state, text: string) {
      state.text = text
      setTimeout(() => (state.text = null), 2000)
    },
  },
} as Module<SnackbarState, any>
