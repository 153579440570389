<template>
  <div>
    <Heading :text="t('backlinks-checker')" />
    <input type="text" v-model="filterByUid" placeholder="Filter by order id"/> <button @click="getOrders">SEARCH</button>
    
    <div class="grid lg:grid-cols-3">
      <div></div>
      <div></div>
      <div class="space-y-2">
        <div class="w-full">
          <AnimatePing title="pending" class="bg-yellow-500" />{{
            t('blchecker-status-pending')
          }}
        </div>
        <div class="w-full">
          <AnimatePing title="active" class="bg-emerald-500" />{{
            t('blchecker-status-active')
          }}
        </div>
        <div class="w-full">
          <AnimatePing title="inactive" class="bg-red-500" />{{
            t('blchecker-status-inactive')
          }}
        </div>
      </div>
    </div>
    <div v-if="orders" class="overflow-x-scroll">
      <table class="relative w-full text-sm whitespace-nowrap">
        <tr>
          <th
            v-for="col of [
              'status',
              '#',
              'date',
              'media-name',
              'link',
              'last-update',
              '',
            ]"
            :key="col"
            class="h-20 p-0 font-semibold text-center"
          >
            <div class="h-20 p-0 pt-10 bg-white">
              <div
                class="w-full h-10 p-2 bg-gray-100 border font-bold text-center whitespace-nowrap"
              >
                <span>{{ t(col) }}</span>
              </div>
            </div>
          </th>
        </tr>
        <tr
          v-for="order of orders.slice(
            (pagination.current - 1) * pagination.resultsPerPage,
            (pagination.current - 1) * pagination.resultsPerPage +
              pagination.resultsPerPage,
          )"
          :key="order.uid"
        >
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              <AnimatePing
                :title="order.blchecker?.status ?? 'pending'"
                :class="{
                  'bg-red-500': order.blchecker?.status === 'inactive',
                  'bg-yellow-500':
                    order.blchecker?.status === 'pending' ||
                    !order.blchecker?.status,
                  'bg-emerald-500': order.blchecker?.status === 'active',
                }"
              />
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{ order.uid }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{ order.createdAt.format(false) }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{ (order.product_object as Product).name }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly truncate ... max-w-sm">
              <a target="_BLANK" class="underline" :href="order.link">{{
                order.anchor
              }}</a>
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              {{
                order.blchecker?.date
                  ? new Date(order.blchecker?.date as Date).format(false)
                  : ''
              }}
            </div>
          </td>
          <td class="p-2 text-sm border">
            <div class="flex items-center justify-evenly">
              <a :href="order.articleLink" target="_BLANK">
                <ExternalLinkIcon class="w-6 h-6 text-gray-400" />
              </a>
              <button @click="update(order.uid as string)">
                <RefreshIcon class="w-6 h-6 text-green-500" />
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <Pagination
      :pagination="pagination"
      :hide-total="true"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
</template>

<script lang="ts" setup>
import { where, orderBy } from '@firebase/firestore'
import { ExternalLinkIcon, RefreshIcon } from '@heroicons/vue/outline'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import Heading from '../../components/Heading.vue'
import Order from '../../models/order'
import Product from '../../models/product'
import AnimatePing from '../../components/AnimatePing.vue'
import { useFirebase } from '../../plugins/firebase'
import store from '../../store'
import LoaderVue from '../../components/Loader.vue'
import Pagination from '../../components/Pagination.vue'

const i18n = useI18n()
const { t } = i18n
const orders = ref<Order[]>()
const firebase = useFirebase()
const filterByUid = ref<string>('')
const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 40,
})

const update = (order: string) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  firebase
    .function('processBlcheckerSingle', { order: order })
    .then((res) => {
      getOrders()
    })
    .catch(() => store.commit('popup/close'))
}

const getOrders = () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  Order.all([
    where('status', '==', 'completed'),
    where('product_collection', '==', 'products'),
    orderBy('createdAt', 'desc'),
    orderBy('blchecker', 'desc'),
  ]).then((items) => {
    orders.value = (items as Order[]).filter((i) => i.link).sort((a: Order, b: Order) => {
      if(a.blchecker?.status === 'inactive' && b.blchecker?.status !== 'active') return -1
      if(a.blchecker?.status === 'pending' && b.blchecker?.status === 'active') return -1
      if(a.blchecker?.status === 'active' && b.blchecker?.status === 'inactive') return 1
      if(a.blchecker?.status === 'active' && b.blchecker?.status === 'pending') return 1

      return 1
    }).filter((i: Order) => {
      return filterByUid.value === '' || i.uid?.includes(filterByUid.value)
    })
    pagination.value.total = orders.value.length
    pagination.value.totalPages = Math.ceil(
      pagination.value.total / pagination.value.resultsPerPage,
    )
    store.commit('popup/close')
  })
}

getOrders()

const next = async () => {
  pagination.value.current++
  document.getElementById('topPage')?.scrollIntoView()
}

const prev = async () => {
  pagination.value.current--
  document.getElementById('topPage')?.scrollIntoView()
}
</script>
