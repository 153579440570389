export default [
  'adult',
  'pets-nature',
  'arts-entertainment',
  'beauty-cosmetics',
  'business',
  'home-garden',
  'food-beverages',
  'technology',
  'society',
  'e-commerce-shopping',
  'finance',
  'games',
  'gambling-betting',
  'hobbies-entertainment',
  'industry-engineering',
  'jobs-career',
  'law-government',
  'lifestyle',
  'fashion-clothes',
  'news-media',
  'health',
  'science-education',
  'sports',
  'motor',
  'travel-hotels-tourism',
  'wedding-love-couples',
  'films-tv',
  'dating',
  'marketing-seo',
  'women-men-children',
]