import { App, inject } from 'vue'

const id = Symbol('platform')

class Platform {
  private info: any | null = null

  app() {
    return this.info?.platform !== 'web'
  }

  appAndroid() {
    return this.info?.platform === 'android'
  }

  appIos() {
    return this.info?.platform === 'ios'
  }

  web() {
    return this.info?.platform === 'web'
  }
}

export const platform = new Platform()

export const usePlatform = () => inject<Platform>(id) as Platform

export default () => ({
  install(app: App) {
    app.provide(id, platform)
  },
})
