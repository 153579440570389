<template>
  <Popup>
    <div class="lg:flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            {{ product.name }}
            <div class="flex items-center gap-1 mx-2">
              <a
                v-for="t of types"
                :key="t"
                target="_BLANK"
                :href="!((product as any)[t][0]).match(/http/i) ? `https://${((product as any)[t][0])}`: ((product as any)[t][0])"
              >
                <img :src="`/assets/icons/${t}.svg`" width="16" height="16" />
              </a>
            </div>
          </h4>
          <p class="mt-2 text-sm text-gray-600 max-w-2xl">
            {{ product.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-5 mt-2">
      <div>
        <strong>{{ t('general') }}</strong>
        <FormRow v-model="product.metrics.da" :label="t('da')" type="text" />
        <FormRow v-model="product.metrics.ascore" :label="t('ascore')" type="text" />
        <FormRow v-model="product.metrics.za" :label="t('za')" type="text" />
        <FormRow v-model="product.metrics.wr" label="Wolf Rank" type="text" />
      </div>
      <div>
        <strong>{{ t('backlinks') }}</strong>
        <FormRow
          v-model="product.metrics.backlinks.dofollow"
          :label="t('dofollow')"
          type="text"
        />
        <FormRow
          v-model="product.metrics.backlinks.nofollow"
          :label="t('nofollow')"
          type="text"
        />
        <FormRow
          v-model="product.metrics.backlinks.total"
          :label="t('total')"
          type="text"
        />
        <FormRow
          v-model="product.metrics.backlinks.gov"
          :label="t('gov')"
          type="text"
        />
        <FormRow
          v-model="product.metrics.backlinks.edu"
          :label="t('edu')"
          type="text"
        />
      </div>
    </div>
    <div class="flex justify-end items-center gap-2 mt-6">
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        :disabled="loading"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Product from '../../models/product'
import { State } from '../../store'
import Popup from './Popup.vue'
import { PlusIcon } from '@heroicons/vue/outline'
import { useI18n } from 'vue-i18n'
import FormRow from '../form/FormRow.vue'
import { ref } from 'vue'

const { t } = useI18n()
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isPublisher = store.state.AccountType.type === 'publisher'

const product = store.state.popup.args.product as Product
if (!product.metrics)
  product.metrics = {
    likes: 0,
    followers: 0,
    ip: '',
    ascore: 0,
    da: 0,
    tf: 0,
    cf: 0,
    za: 0,
    wr: 0,
    backlinks: {
      total: 0,
      nofollow: 0,
      dofollow: 0,
      gov: 0,
      edu: 0,
      refpages: 0,
      links_internal: 0,
      links_external: 0,
    },
  }
if (!product.metrics.backlinks)
  product.metrics.backlinks = {
    total: 0,
    nofollow: 0,
    dofollow: 0,
    gov: 0,
    edu: 0,
    refpages: 0,
    links_internal: 0,
    links_external: 0,
  }
const types = [
  'website',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'reddit',
  'telegram',
].filter((type) => (product as unknown as Record<string, []>)[type].length)

const done = ref(false)
const loading = ref(false)
const save = () => {
  loading.value = true
  product?.save()
  done.value = true
  loading.value = false
}
</script>
