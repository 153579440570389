import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  query,
  QueryConstraint,
  setDoc,
  where,
} from '@firebase/firestore'
import { firebase } from '../plugins/firebase'
import User from './user'

type ModelImplementation<T extends Model> = {
  uid?: string
  instantiate?: (uid: string, model: DocumentData) => T
  collection: string
  preload?: string[]
  last?: Model
}

export default abstract class Model {
  public uid?: string

  /**
   * Find one model by uid
   */
  public static async find<T extends Model>(
    this: ModelImplementation<T>,
    uid?: string,
  ) {
    if (!this.instantiate) return null
    const firestore = firebase.getFirestore()
    if (!uid) return null

    const model = (
      await getDoc(doc(collection(firestore, this.collection), uid))
    ).data()

    let preloaded = {} as Record<string, string>
    if (this.preload)
      preloaded = (
        await Promise.all(
          this.preload.map(async (name) =>
            (
              await getDocs(
                collection(firestore, `${this.collection}/${uid}/${name}`),
              )
            ).docs.map((d) => ({ uid: d.id, ...d.data() })),
          ),
        )
      ).reduce(
        (acc, items, i) => ({
          ...acc,
          [(this.preload as string[])[i]]: items,
        }),
        {},
      )

    if (!model) return null
    return this.instantiate(uid, { ...model, ...preloaded })
  }

  /**
   * Return all models
   */
  static async all<T extends Model>(
    this: ModelImplementation<T>,
    filter_query?: QueryConstraint[],
  ) {
    if (!this.instantiate) return null
    const firestore = firebase.getFirestore()

    const q = query(
      collection(firestore, this.collection),
      ...(filter_query ? filter_query : []),
    )
    const data = await getDocs(q)
    const models = data.docs.map(async (d) => {
      const doc = d.data()
      let preloaded = {} as Record<string, string>
      if (this.preload)
        preloaded = (
          await Promise.all(
            this.preload.map(async (name) =>
              (
                await getDocs(
                  collection(firestore, `${this.collection}/${d.id}/${name}`),
                )
              ).docs.map((d) => ({ uid: d.id, ...d.data() })),
            ),
          )
        ).reduce(
          (acc, items, i) => ({
            ...acc,
            [(this.preload as string[])[i]]: items,
          }),
          {},
        )

      return (this.instantiate as (uid: string, model: DocumentData) => T)(
        d.id,
        {
          ...doc,
          ...preloaded,
        },
      )
    })

    return (await Promise.all(models)) as T[]
  }

  /**
   * Return all models (total number)
   */
  static async count<T extends Model>(
    this: ModelImplementation<T>,
    filter_query?: QueryConstraint[],
  ) {
    if (!this.instantiate) return 0
    const firestore = firebase.getFirestore()

    const q = query(
      collection(firestore, this.collection),
      ...(filter_query ? filter_query : []),
    )

    return (await getDocs(q)).size
  }

  /**
   * Return owned models
   */
  static async own<T extends Model>(
    this: ModelImplementation<T>,
    filter_query?: QueryConstraint[],
  ) {
    if (!this.instantiate) return null
    const firestore = firebase.getFirestore()

    const user = await User.getCurrent()
    const q = query(
      collection(firestore, this.collection),
      where('owner', '==', user.uid),
      ...(filter_query ?? []),
    )
    const data = await getDocs(q)
    const models = data.docs.map(async (d) => {
      const doc = d.data()
      let preloaded = {} as Record<string, string>
      if (this.preload)
        preloaded = (
          await Promise.all(
            this.preload.map(async (name) =>
              (
                await getDocs(
                  collection(firestore, `${this.collection}/${d.id}/${name}`),
                )
              ).docs.map((d) => ({ uid: d.id, ...d.data() })),
            ),
          )
        ).reduce(
          (acc, items, i) => ({
            ...acc,
            [(this.preload as string[])[i]]: items,
          }),
          {},
        )

      return (this.instantiate as (uid: string, model: DocumentData) => T)(
        d.id,
        {
          ...doc,
          ...preloaded,
        },
      )
    })

    const res = (await Promise.all(models)) as T[]
    return res
  }

  /**
   * Return filtered models
   */
  static async filter<T extends Model>(
    this: ModelImplementation<T>,
    ...filter_query: QueryConstraint[]
  ) {
    if (!this.instantiate) return null
    const firestore = firebase.getFirestore()

    const q = query(
      collection(firestore, this.collection),
      ...filter_query,
      limit(5),
    )
    const data = await getDocs(q)
    const models = data.docs.map(async (d) => {
      const doc = d.data()
      let preloaded = {} as Record<string, string>
      if (this.preload)
        preloaded = (
          await Promise.all(
            this.preload.map(async (name) =>
              (
                await getDocs(
                  collection(firestore, `${this.collection}/${d.id}/${name}`),
                )
              ).docs.map((d) => ({ uid: d.id, ...d.data() })),
            ),
          )
        ).reduce(
          async (acc, items, i) => ({
            ...acc,
            [(this.preload as string[])[i]]: items,
          }),
          {},
        )

      return (this.instantiate as (uid: string, model: DocumentData) => T)(
        d.id,
        {
          ...doc,
          ...preloaded,
        },
      )
    })

    const res = (await Promise.all(models)) as T[]
    return res
  }

  public async save<T extends Model>(this: ModelImplementation<T>) {
    const firestore = firebase.getFirestore()
    const {
      uid,
      collection: coll,
      ...model
    } = { ...this } as { uid: string; collection: string } & Record<
      string,
      unknown
    >

    const data = Object.keys(model as Record<string, unknown>)
      .filter((key) => model[key] !== undefined)
      .reduce((acc, key) => ({ ...acc, [key]: model[key] }), {})
    if (!this.uid) await addDoc(collection(firestore, coll), data)
    else await setDoc(doc(collection(firestore, coll), this.uid), data)
  }

  public async delete<T extends Model>(this: ModelImplementation<T>) {
    const firestore = firebase.getFirestore()
    if (!this.uid) return
    return await deleteDoc(
      doc(collection(firestore, this.collection), this.uid),
    )
  }
}
