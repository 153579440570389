import {
  collection,
  DocumentData,
  getDocs,
  orderBy,
  query,
  Timestamp,
  where,
} from '@firebase/firestore'
import User from './user'
import Model from './model'
import { firebase } from '../plugins/firebase'

interface OrderModel {
  uid?: string
  owner: string
  status: 'pending' | 'paid' | 'work-in-progress' | 'need-review' | 'completed' | 'canceled' | 'refunded'
  refused: boolean
  product: string
  product_object: object
  product_collection: string
  publisher: string
  extras?: boolean[]
  words?: number
  language?: string
  article?: boolean
  link?: string
  anchor?: string
  pictureLink?: string
  price: number
  info: string
  stripeSession?: string
  paypalSession?: string
  articleLink?: string
  publishDate?: Date
  createdAt: Date
  linkedOrder: string
  publicationguidelines?: string
  paymentMethod?: string
  advertiserInfo?: boolean
  advertiserInfoDate?: Date
  blchecker?: {
    status: string
    date: Date
  }
  updates: {
    status: 'paid' | 'work-in-progress' | 'need-review' | 'completed'
    date: Date
  }[]
  owner_fee: number
  orderGroup: string
  project?: string
  campaign?: string
  feedback?: {
    rating: number
    comment?: string
    date: Timestamp
  }
}

export default class Order extends Model implements OrderModel {
  public collection = 'orders'
  static collection = 'orders'

  public uid?: string
  public owner: string
  public status: OrderModel['status']
  public refused: boolean
  public product: string
  public product_object: object
  public product_collection: string
  public publisher: string
  public extras?: boolean[]
  public words?: number
  public language?: string
  public article?: boolean
  public link?: string
  public anchor?: string
  public pictureLink?: string
  public price: number
  public info: string
  public stripeSession?: string
  public paypalSession?: string
  public articleLink?: string
  public publishDate?: Date
  public createdAt: Date
  public linkedOrder: string
  public publicationguidelines?: string
  public paymentMethod?: string
  public advertiserInfo?: boolean
  public advertiserInfoDate?: Date
  public blchecker?: OrderModel['blchecker']
  public updates: OrderModel['updates']
  public owner_fee: number
  public orderGroup: string
  public project?: string
  public campaign?: string
  public feedback?: {
    rating: number
    comment?: string
    date: Timestamp
  }

  public constructor(attributes: OrderModel) {
    super()
    this.uid = attributes.uid
    this.product = attributes.product
    this.product_object = attributes.product_object
    this.product_collection = attributes.product_collection
    this.price = attributes.price
    this.info = attributes.info
    this.owner = attributes.owner
    this.status = attributes.status
    this.refused = attributes.refused
    this.publisher = attributes.publisher
    this.stripeSession = attributes.stripeSession
    this.paypalSession = attributes.paypalSession
    this.extras = attributes.extras
    this.words = attributes.words
    this.language = attributes.language
    this.article = attributes.article ?? false
    this.link = attributes.link
    this.articleLink = attributes.articleLink
    this.anchor = attributes.anchor
    this.updates = attributes.updates
    this.pictureLink = attributes.pictureLink
    this.publicationguidelines = attributes.publicationguidelines
    this.paymentMethod = attributes.paymentMethod
    this.advertiserInfo = attributes.advertiserInfo
    this.advertiserInfoDate = attributes.advertiserInfoDate
    this.blchecker = attributes.blchecker
    this.publishDate =
      attributes.publishDate instanceof Timestamp
        ? attributes.publishDate.toDate()
        : attributes.publishDate
    this.linkedOrder = attributes.linkedOrder ?? ''
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
    this.owner_fee = attributes.owner_fee ?? 0
    this.orderGroup = attributes.orderGroup
    this.project = attributes.project
    this.campaign = attributes.campaign
    this.feedback = attributes.feedback
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as OrderModel
    return new this({
      uid: id,
      ...attrs,
    })
  }

  static async viewable() {
    const firestore = firebase.getFirestore()
    const user = (await User.getCurrent()).uid

    const q = query(
      collection(firestore, 'orders'),
      orderBy('createdAt', 'desc'),
      where('publisher', '==', user),
    )
    const res = (await getDocs(q)).docs.map((d) =>
      this.instantiate(d.id, d.data()),
    )
    return res
  }
}
