<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ t('press-release') }} {{ props.product.name }}
        </h4>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
      </div>
      <div v-if="order.pictureLink" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="text-sm text-gray-600">
          {{ t('picture-image-link') }}
        </dt>
        <dd class="text-gray-700 text-sm col-span-2">
          {{ order.pictureLink }}
        </dd>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <template v-if="order.status !== 'pending'">
    <div v-if="order.status === 'paid'">
      <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
      <div v-if="!changing && currentArticle">
        <div class="text-sm text-gray-800 my-2">
          {{ t('article-received') }}
        </div>
        <div class="flex items-center gap-3">
          <a :href="currentArticle" class="btn gradient !m-0">
            {{ t('download-article') }}
          </a>
          <a
            href=""
            class="btn gradient !m-0"
            @click.prevent="changing = !changing"
          >
            {{ t('change-article') }}
          </a>
        </div>
      </div>
      <div v-else>
        <FormRow
          label=""
          type="file"
          @change="article = $event as unknown as File"
        />
        <FormRow
          v-model="pictureLink"
          name="picture-link"
          :label="t('picture-link')"
          type="text"
        />
        <FormRow
          v-if="pictureLink"
          name="picture-terms"
          :label="t('picture-terms')"
          required
        >
          <label>
            <input
              v-model="pictureLinkAccept"
              type="checkbox"
              class="rounded mr-1"
            />
            {{ t('picture-accept-terms') }}
          </label>
        </FormRow>
      </div>
    </div>
    <div>
      {{ order.info }}
    </div>
    <div
      v-if="order.status === 'paid' && !currentArticle"
      class="flex justify-end items-center gap-2 mt-6"
    >
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        :disabled="loading"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
    <div
      v-if="order.status === 'need-review' || order.status === 'completed'"
      class="flex justify-end items-center gap-2 mt-6"
    >
      <button
        v-if="order.status === 'need-review' || isAdmin"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderRefused"
      >
        {{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}
      </button>
      <button
        v-if="order.status === 'need-review'"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderDone"
      >
        {{ t('confirm-order') }}
      </button>
    </div>
  </template>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import FormRow from '../form/FormRow.vue'
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
  listAll,
  deleteObject,
} from '@firebase/storage'
import { useFirebase } from '../../plugins/firebase'
import Press from '../../models/press'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore<State>()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const props = defineProps<{
  product: Press
  orderChange: () => void
  orderDone: () => void
  orderRefused: () => void
}>()
const order = store.state.popup.args.order as Order

const firebase = useFirebase()
const storage = firebase.getStorage()

const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'presses/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.uid as string),
  )
  if (el)
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `presses/${el.name}`),
    )
}
getCurrentArticle()
const article = ref<File>()
const pictureLink = ref<string>(order.pictureLink ?? '')
const pictureLinkAccept = ref<boolean>(false)

const done = ref(false)
const loading = ref(false)
const save = async () => {
  if (!article.value) return
  if (pictureLink.value !== '' && !pictureLinkAccept.value) return
  loading.value = true
  const list = await listAll(storageRef(storage, 'presses/'))
  list.items.forEach((item) =>
    item.name.startsWith(order.uid as string)
      ? deleteObject(storageRef(storage, `presses/${item.name}`))
      : void 0,
  )
  uploadBytes(
    storageRef(
      storage,
      `presses/${order.uid}.${article.value.name.split('.').pop()}`,
    ),
    await article.value.arrayBuffer(),
  )
  order.pictureLink = pictureLink.value
  order.article = true
  order.advertiserInfo = true
  order.advertiserInfoDate = new Date()
  await order.save()
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  await props.orderChange()
}
</script>
