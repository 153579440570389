<template>
  <div v-if="products.length">
    <ProductItem
      v-for="product in products.slice(
        (pagination.current - 1) * pagination.resultsPerPage,
        (pagination.current - 1) * pagination.resultsPerPage +
          pagination.resultsPerPage,
      )"
      :key="product.uid"
      :product="product"
      :users="props.users"
      @update="update"
    />
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
  <div v-else>No products to show.</div>
</template>
<script setup lang="ts">
import { QueryConstraint, where } from '@firebase/firestore'
import { ref } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import Pagination from '../Pagination.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'

const props = defineProps<{
  users: any
}>()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const products = ref<Product[]>([])

const common_query = [
  where('approved', '==', false),
  where('updated', '==', true)
]

const next = async () => {
  pagination.value.current++
}

const prev = async () => {
  pagination.value.current--
}

const fetch = async (query: QueryConstraint[] = []) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const prods = ((await Product.all([...query])) as Product[]).filter(
    (i) => !i.rejected && !i.canceled,
  )

  pagination.value.total = prods.length
  pagination.value.totalPages = Math.ceil(
    prods.length / pagination.value.resultsPerPage,
  )
  
  products.value = prods as Product[]
  store.commit('popup/close')
}

const update = async () => {
  fetch([...common_query])
}
update()
</script>
