<template>
  <Popup>
    <div>
      <span v-if="loading">{{ t('loading') }}</span>
      <div v-else v-for="rating in ratings" :key="rating">
        Rating: {{ rating.rating }}<br />
        Comment: {{ rating.comment }}<br />
        Date: {{ rating.date.toDate().format() }}
        <hr class="my-2" />
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import User from '../../models/user'

const { t } = useI18n()
const store = useStore<State>()

const ratings = ref<any[]>([])
const loading = ref<boolean>(false)
const uid = store.state.popup.args.uid
const preloadedRatings = store.state.popup.args.ratings
onMounted(async () => {
  if(!preloadedRatings)
    User.getUserReviews(uid).then((rts) => (ratings.value = rts))
  else ratings.value = preloadedRatings
})
</script>
