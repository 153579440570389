<template>
  <div v-if="type === 'avatar'" v-bind="{}" class="flex items-center">
    <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
      <slot />
    </span>
    <label
      :for="name"
      class="cursor-pointer ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
    >
      {{ t('change') }}
      <input
        :id="name"
        :name="name"
        type="file"
        class="hidden"
        @input="
          $emit(
            'update:modelValue',
            (($event.target as HTMLInputElement).files as FileList)[0],
          )
        "
      />
    </label>
  </div>
  <div v-else-if="type === 'vselect'" class="max-w-lg flex">
    <vselect
      v-bind="$attrs"
      :model-value="vSelectValue"
      class="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
      @update:model-value="
        $emit(
          'update:modelValue',
          Array.isArray($event)
            ? $event.map((i: any) => i.value)
            : $event.value,
        )
      "
    />
    <slot />
  </div>
  <div v-else-if="type === 'select'" class="max-w-lg flex">
    <select
      v-bind="$attrs"
      :id="name"
      :name="name"
      class="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
      :value="modelValue"
      @input="
        $emit('update:modelValue', ($event.target as HTMLSelectElement).value)
      "
    >
      <slot />
    </select>
  </div>
  <template v-else-if="type === 'textarea'">
    <textarea
      v-bind="$attrs"
      :id="name"
      :name="name"
      rows="3"
      :minlength="minlength"
      class="max-w-lg shadow-sm block w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md"
      :value="(modelValue as string)"
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement).value)
      "
    />

    <div v-if="minlength">
      <span
        class="text-xs text-red-500"
        v-if="String(modelValue).length < minlength"
      >
        {{ $tc('survey-min-chars', minlength) }}</span
      >
    </div>
  </template>
  <div v-else-if="type === 'checkbox'" class="max-w-lg flex">
    <input
      v-bind="$attrs"
      :id="name"
      :name="name"
      type="checkbox"
      class="block focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
      :checked="modelValue as boolean"
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement).checked)
      "
    />
  </div>
  <div v-else class="max-w-lg flex flex-col">
    <div class="mt-1 relative rounded-md shadow-sm">
      <div
        v-if="before || $slots.before"
        class="absolute inset-y-0 left-0 pl-3 flex items-center"
      >
        <span class="text-gray-500 sm:text-sm">
          {{ before }} <slot v-if="!before" name="before" />
        </span>
      </div>
      <input
        v-bind="$attrs"
        :id="name"
        :type="type"
        :name="name"
        :value="modelValue"
        :class="{ 'pl-7': before, 'pr-12': after }"
        class="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
        @input="
          $emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
      <div
        v-if="after || $slots.after"
        class="absolute inset-y-0 right-0 pr-3 flex items-center"
      >
        <span class="text-gray-500 sm:text-sm">
          {{ after }} <slot v-if="!after" name="after" />
        </span>
      </div>
    </div>
    <slot />
  </div>
</template>
<script lang="ts" setup>
import { propsToAttrMap } from '@vue/shared'
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import vselect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const { t } = useI18n()
defineProps<{
  name: string
  type: string
  modelValue?: string | number | File | string[] | boolean
  vSelectValue?: { label: string; value: string }[]
  before?: string
  after?: string
  minlength?: number
}>()
defineEmits(['update:modelValue'])
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
