<template>
  <div class="lg:flex flex-grow items-center gap-6">
    <div class="flex-auto flex flex-col">
      <div>
        <h4 class="font-medium text-gray-900 flex">
          {{ product.name }}
          <div class="flex gap-1 mx-2">
            <img
              v-for="ty of types"
              :key="(ty as any)"
              :src="`/assets/icons/${ty}.svg`"
              width="16"
              height="16"
            />
          </div>
        </h4>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t('order-date') }}: {{ order.createdAt.format() }}
        </p>
        <p class="mt-2 text-sm text-gray-600 max-w-2xl">
          {{ t(order.status) }}
        </p>
      </div>
    </div>
    <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
      {{ Number(order.price).format() }} €
      <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
        {{ t('order-id') }}: {{ order.uid }}
      </p>
    </div>
  </div>
  <div class="my-6 divide-y-2 space-y-4">
    <dl class="flex flex-col sm:gap-4 lg:gap-6 text-sm">
      <div
        v-for="(extra, i) in product.extras ?? []"
        :key="extra.name"
        class="grid grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">{{ extra.name }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ (order.extras ?? [])![i] ? t('yes') : t('no') }}
        </dd>
      </div>
      <!--<div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('anchor') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.anchor }}</dd>
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">{{ t('link') }}</dt>
        <dd class="text-gray-700 col-span-2">{{ order.link }}</dd>
      </div>-->
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <dt class="font-medium text-gray-900">
          {{ t('publish-date') }}
        </dt>
        <dd class="ml-2 col-span-2 font-bold text-red-500">
          {{ order.publishDate?.format(false) }}
        </dd>
      </div>
      <div
        v-if="order.articleLink"
        class="grid grid-cols-1 lg:grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">{{ t('article-link') }}</dt>
        <dd class="text-gray-700 col-span-2">
          <a
            :href="order.articleLink"
            target="_blank"
            class="text-primary-500 hover:text-primary-600 hover:underline"
          >
            {{ order.articleLink }}
          </a>
        </dd>
      </div>
      <div
        v-if="order.pictureLink"
        class="grid grid-cols-1 lg:grid-cols-3 gap-4"
      >
        <dt class="font-medium text-gray-900">{{ t('picture-link') }}</dt>
        <dd class="text-gray-700 col-span-2">
          {{ order.pictureLink }}
        </dd>
      </div>
    </dl>
  </div>
  <div v-if="order.info" class="mb-4">
    <h4 class="font-medium text-gray-900 flex">{{ t('order-info') }}:</h4>
    <p class="mt-2 text-sm text-gray-600 max-w-2xl">{{ order.info }}</p>
  </div>
  <div v-if="order.refused">
    {{ t('media-refused') }}
    <a href="#" class="underline" @click="openChat">{{ t('open-the-chat') }}</a>
  </div>
  <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
  <template v-if="currentArticle">
    <hr class="my-4" />
    <a :href="currentArticle" class="btn primary m-0">
      {{ t('download-article') }}
    </a>
  </template>
  <template v-else>
    <span class="font-bold text-red-500">{{
      t('product-order-article-missing-publisher')
    }}</span>
  </template>
  <template
    v-if="
      order.status !== 'pending' &&
      order.status !== 'paid' &&
      order.status !== 'need-review'
    "
  >
    <FormRow
      v-if="order.status !== 'completed'"
      v-model="info.articleLink"
      name="article-link"
      :label="t('insert-article-link')"
      type="url"
    />
    <div
      v-if="order.status !== 'completed'"
      class="flex justify-end items-center gap-2 mt-6"
    >
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        v-if="info.articleLink"
        :disabled="loading"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
  </template>
  <div class="flex justify-end items-center gap-2 mt-6">
    <button
    v-if="(isAdmin || isSoftAdmin) || order.status === 'paid'"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="orderRefused"
    >
      {{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}
    </button>
    <button
      v-if="order.status === 'paid' && currentArticle"
      :disabled="loading"
      class="flex items-center gap-1 btn gradient disabled:opacity-50"
      @click="() => orderDone()"
    >
      {{ t('take-order') }}
    </button>
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import Product from '../../models/product'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import { computed } from '@vue/reactivity'
import FormRow from '../form/FormRow.vue'
import { useFirebase } from '../../plugins/firebase'
import { ref as storageRef, getDownloadURL, listAll } from '@firebase/storage'
import LoaderVue from '../Loader.vue'
import Chat from '../../models/chat'
import ChatPopupVue from './ChatPopup.vue'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore<State>()

const props = defineProps<{
  product: Product
  // eslint-disable-next-line @typescript-eslint/ban-types
  orderDone: Function
  orderRefused?: () => void
}>()

const order = store.state.popup.args.order as Order
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const types = computed(() =>
  [
    'website',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'reddit',
    'telegram',
  ].filter((type) => (props as unknown as Record<string, []>)[type]?.length),
)

const firebase = useFirebase()
const storage = firebase.getStorage()

const info = ref({
  articleLink: order.articleLink,
})
const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'articles/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.uid as string),
  )
  if (el)
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `articles/${el.name}`),
    )
}
getCurrentArticle()
const done = ref(false)
const loading = ref(false)
const save = async () => {
  if (!props.product) return
  store.commit('popup/open', { popup: LoaderVue })
  loading.value = true
  order.articleLink = info.value.articleLink
  await firebase.function('orderChangeNotification', { order: order.uid })
  await order.save()
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  if (order.status === 'work-in-progress' && info.value.articleLink !== '')
    props.orderDone()
  setTimeout(() => {
    done.value = false
    store.commit('popup/close')
  }, 2000)
}
const openChat = async () => {
  let model = await Chat.find(order.uid)
  if (!model) {
    model = new Chat({
      uid: order.uid,
      advertiser: order.owner,
      publisher: order.publisher,
      createdAt: new Date(),
    })
    model.save()
  }
  store.commit('popup/open', { popup: ChatPopupVue, chat: model })
}
</script>
