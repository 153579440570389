<template>
  <component :is="mobile ? 'details' : 'div'" :open="true">
    <summary v-if="mobile">
      {{ t('filters') }}
    </summary>
    <div class="flex flex-wrap justify-between gap-2 my-4">
      <div class="flex flex-wrap flex-col gap-8 w-full">
        <form
          class="w-full flex gap-1"
          @submit.prevent="
            !props.query ? $emit('filter', filterByName) : redirectToCatalog()
          "
        >
          <input
            v-model="filterByName"
            type="text"
            :placeholder="t('search-by-name') + '...'"
            class="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
          />
          <button
            class="flex items-center btn gradient text-sm uppercase"
          >
            {{ t('submit') }}
          </button>
          <button
            class="flex items-center btn secondary text-sm uppercase"
            @click="filterByName = ''"
          >
            {{ t('reset') }}
          </button>
        </form>
        <div class="flex flex-wrap gap-4 items-center">
          <div class="grid grid-cols-2 gap-3">
            <div>
              {{ t('price-min') }}
              <FormInput
                v-model="filters.price[0]"
                type="text"
                name="pricemin"
              />
            </div>
            <div>
              {{ t('price-max') }}
              <FormInput
                v-model="filters.price[1]"
                type="text"
                name="pricemax"
              />
            </div>
          </div>
          <!--<RangeInput
            v-model="filters.price"
            :max="5000"
            name="price"
            :label="t('price')"
          />-->
          <RangeInput v-model="filters.da" name="da" :label="t('da')" />
          <RangeInput
            v-if="filters.country !== 'it'"
            v-model="filters.ascore"
            name="ascore"
            :label="t('ascore')"
          />
          <RangeInput v-else v-model="filters.za" name="za" :label="t('za')" />
        </div>
        <div class="flex flex-wrap items-center gap-4 w-full">
          <FormInput v-model="filters.type" type="select" name="type">
            <option value="" selected>{{ t('media-type') }}</option>
            <option value="blog">{{ t('blog') }}</option>
            <option value="newspaper">{{ t('newspaper') }}</option>
            <option value="social">{{ t('social') }}</option>
          </FormInput>
          <FormInput v-model="filters.category" type="select" name="category">
            <option value="" selected>{{ t('category') }}</option>
            <option
              v-for="category of categories.sort((a, b) => a.localeCompare(b))"
              :key="category"
              :value="category"
            >
              {{ t(category) }}
            </option>
          </FormInput>
          <FormInput v-model="filters.country" type="select" name="country">
            <option value="" selected>{{ t('country') }}</option>
            <option
              v-for="country of countries"
              :key="country.code"
              :value="country.code"
            >
              {{ country.name }}
            </option>
          </FormInput>
          <FormInput v-model="filters.gnews" type="select" name="google-news">
            <option value="" selected>{{ t('google-news') }}</option>
            <option value="yes" selected>{{ t('yes') }}</option>
            <option value="no" selected>{{ t('no') }}</option>
          </FormInput>
          <div class="top-[-5px] relative">
            <sup
              class="uppercase text-sm top-[5px]"
              style="font-style: small-caps"
              >{{ t('accepts') }}</sup
            >
            <div class="flex flex-wrap items-center justify-between gap-3">
              <label class="flex items-center">
                <input
                  v-model="filters.gambling"
                  type="checkbox"
                  class="mr-2 rounded"
                />
                {{ t('gambling') }}
              </label>
              <label class="flex items-center">
                <input
                  v-model="filters.cbd"
                  type="checkbox"
                  class="mr-2 rounded"
                />
                {{ t('cbd') }}
              </label>
              <label class="flex items-center">
                <input
                  v-model="filters.adult"
                  type="checkbox"
                  class="mr-2 rounded"
                />
                {{ t('adult') }}
              </label>
              <label class="flex items-center">
                <input
                  v-model="filters.trading"
                  type="checkbox"
                  class="mr-2 rounded"
                />
                {{ t('trading') }}
              </label>
            </div>
          </div>
          <div v-if="props.query" class="flex justify-end items-center w-full">
            <button class="btn gradient" @click="redirectToCatalog">
              {{ t('search') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script lang="ts" setup>
import { ref, reactive, watch, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import FormInput from '../components/form/FormInput.vue'
import categories from '../plugins/categories'
import RangeInput from '../components/form/RangeInput.vue'
import { useRoute, useRouter } from 'vue-router'

import countries from '../plugins/countries'

const router = useRouter()
const route = useRoute()
const props = defineProps<{
  query?: boolean
}>()

const i18n = useI18n()
const { t } = i18n

const emit = defineEmits(['filter'])
const mobile = ref(window.innerWidth < 1024)
const updateMobile = () => (mobile.value = window.innerWidth < 1024)
onMounted(() => window.addEventListener('resize', updateMobile))
onUnmounted(() => window.removeEventListener('resize', updateMobile))

let filters = reactive({
  price: [0, 5000] as [number, number],
  da: [0, 100] as [number, number],
  ascore: [0, 100] as [number, number],
  za: [0, 100] as [number, number],
  type: '',
  category: '',
  country: '',
  gambling: false,
  cbd: false,
  adult: false,
  trading: false,
  gnews: '',
})

const filterByName = ref<string>('')

watch(filters, () => {
  emit('filter', filters)
})

const redirectToCatalog = () => {
  if (filterByName.value !== '')
    localStorage.setItem(
      'filter-query',
      JSON.stringify({ filterByName: filterByName.value }),
    )
  else localStorage.setItem('filter-query', JSON.stringify(filters))
  router.push({ path: '/app/advertiser/products', query: { fromStorage: 'yes' } })
}

onMounted(() => {
  if(route.query.fromStorage === 'yes') {
    let storageFilters = (JSON.parse(localStorage.getItem('filter-query') ?? '{}'))
    if(storageFilters.filterByName) {
      filterByName.value = storageFilters.filterByName
      emit('filter', storageFilters.filterByName)
    } else {
      filters.price = storageFilters.price
      filters.da = storageFilters.da
      filters.ascore = storageFilters.ascore
      filters.za = storageFilters.za
      filters.type = storageFilters.type
      filters.category = storageFilters.category
      filters.country = storageFilters.country
      filters.gambling = storageFilters.gambling
      filters.cbd = storageFilters.cbd
      filters.adult = storageFilters.adult
      filters.trading = storageFilters.trading
      filters.gnews = storageFilters.gnews
    }
  }
})
</script>
