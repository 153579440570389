<template>
  <Popup>
    <div v-if="campaign">
      <div
        v-if="
          'extras' in campaign.products[index] &&
          campaign.products[index].extras.length
        "
        class="flex gap-6 items-center"
      >
        <h5>{{ t('extra') }}:</h5>
        <div class="flex flex-wrap gap-4">
          <label
            v-for="(extra, i) of campaign.products[index].extras"
            :key="extra.name"
            class="flex items-center gap-1"
          >
            <input
              v-model="campaign.setup[index].extras[i]"
              type="checkbox"
              class="rounded text-primary-500 focus:ring-gradient"
            />
            {{ extra.name }}
            <template v-if="extra.price">
              (+{{ extra.price.format() }} €)
            </template>
            <template v-else> (gratis) </template>
          </label>
        </div>
      </div>
      <hr
        v-if="
          'extras' in campaign.products[index] &&
          campaign.products[index].extras.length
        "
        class="my-4"
      />
      {{ t('campaign-row-publication-guidelines') }}
      <FormRow
        v-model="campaign.setup[index].publicationguidelines"
        name="name"
        type="textarea"
        :placeholder="t('publication-guidelines')"
      />
      <div class="flex justify-end my-4">
        <button
          class="flex items-center gap-1 btn gradient disabled:opacity-50"
          @click="save"
        >
          {{ t('save') }}
        </button>
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import FormRow from '../form/FormRow.vue'
import Product from '../../models/product'
import Order from '../../models/order'

const { t } = useI18n()
const store = useStore<State>()

interface Setup {
  date?: Date
  image?: string
  anchor?: string
  publicationguidelines?: string
  extras: boolean[]
  needArticle: boolean
  words?: number
  link?: number
  lang?: string
  article?: string
  articleFile?: File
  order?: string | Order
  orderArticle?: string | Order
}

interface Campaign {
  uid: string
  name: string
  notes: string
  products: Product[]
  setup: Setup[]
  started?: boolean
  completed?: boolean
}

const index = store.state.popup.args.index
const campaign = ref<Campaign>(store.state.popup.args.campaign)
const update = store.state.popup.args.update
const save = () => {
  update(campaign.value)
}
</script>
