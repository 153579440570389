<template>
  <Popup>
    <div>
      {{ t('infobox-' + info) }}
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore<State>()

const info = store.state.popup.args.info
</script>
