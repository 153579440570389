<template>
  <div :class="store.state.AccountType.type">
    <MobileNav
      :menu-full="menuFull"
      :nav="navigation"
      :is-admin="isAdmin"
      :open="sidebarOpen"
      :user="user"
      @close="sidebarOpen = false"
    />
    <div class="fixed w-full flex-shrink-0 flex h-16 bg-white shadow z-10">
      <button
        type="button"
        class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gradient md:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex items-center">
        <Logo class="h-8 ml-2" />
      </div>
      <div class="flex-1 px-4 flex justify-between">
        <div class="flex-1 flex"></div>
        <div class="ml-4 flex items-center md:ml-6">
          <Language />
        </div>
      </div>
    </div>
    <DesktopNav
      :nav="navigation"
      :is-admin="isAdmin"
      :user="user"
      :menu-full="menuFull"
      @toggle-menu-full="menuFull = !menuFull"
    />
    <div
      class="pt-16 flex flex-col flex-1"
      :class="menuFull ? 'md:pl-64' : 'md:pl-12'"
    >
      <main>
        <div class="py-6">
          <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <OrderIncomplete />
            <RouterView @update="user?.refresh()" />
          </div>
        </div>
      </main>
    </div>
  </div>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <component :is="store.state.popup.popup" v-if="store.state.popup.popup" />
  </transition>
  <Snackbar />
</template>

<script lang="ts" setup>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'
// import { useI18n } from 'vue-i18n'
import { RouterView, useRoute, useRouter } from 'vue-router'
import { MenuAlt2Icon } from '@heroicons/vue/outline'
import { useStore } from 'vuex'
import { State } from '../store'
import User from '../models/user'
import Language from '../components/Language.vue'
import MobileNav from './MobileNav.vue'
import DesktopNav from './DesktopNav.vue'
import { NavItem, adminNav, softAdminNav } from './navs'
import {
  adminNav as PersonalAdminNav,
  softAdminNav as PersonalSoftAdminNav,
} from './personal/navs'
import OrderIncomplete from '../components/OrderIncomplete.vue'
import Logo from '../components/Logo.vue'
import Snackbar from '../components/Snackbar.vue'

// const { t } = useI18n()
const user = ref<User>()
const router = useRouter()

const mobile = ref(window.innerWidth < 1024)
const updateMobile = () => (mobile.value = window.innerWidth < 1024)
onMounted(() => window.addEventListener('resize', updateMobile))
onUnmounted(() => window.removeEventListener('resize', updateMobile))
const isPersonal = computed(() =>
  route.fullPath.startsWith('/app/admin/personal'),
)

const updateUser = () => {
  User.getCurrent().then(async (res) => {
    user.value = res
    if (!isPersonal.value)
      navigation.value = isAdmin.value ? adminNav : softAdminNav
    else
      navigation.value = isAdmin.value ? PersonalAdminNav : PersonalSoftAdminNav
  })
}

updateUser()

const sidebarOpen = ref(false)
const menuFull = ref<boolean>(
  JSON.parse(localStorage.getItem('menuFull') ?? 'true'),
)
watch(menuFull, (val) => localStorage.setItem('menuFull', JSON.stringify(val)))

const route = useRoute()
const isAdmin = computed(() => route.fullPath.startsWith('/app/admin'))
// const isSoftAdmin = computed(() => route.fullPath.startsWith('/app/soft-admin'))
const store = useStore<State>()
const navigation = ref<NavItem[]>([])
store.watch(
  (state) => state.AccountType.type,
  () => (navigation.value = softAdminNav),
)
watch(route, () => {
  if (
    user.value &&
    (!user.value?.meta?.invoicingAccounts.length ||
      user.value?.meta?.disabled ||
      !(
        user.value?.meta?.phone !== '' &&
        user.value?.meta?.firstName !== '' &&
        user.value?.meta?.lastName !== ''
      ))
  )
    router.replace('/app/profile')
  updateUser()
  if (!isPersonal.value)
    navigation.value = isAdmin.value ? adminNav : softAdminNav
  else
    navigation.value = isAdmin.value ? PersonalAdminNav : PersonalSoftAdminNav
})
</script>
