<template>
  <Popup>
    <h3>Ooops!</h3>
    <div>
      <p v-html="t('unexpected-error-occurred')"></p>
      <hr class="block my-4" />
      <div v-if="!errorSent">
        <button
          class="btn bg-red-500 text-white !m-0 text-center"
          :disabled="loading"
          @click="sendError"
        >
          <svg
            v-if="loading"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span v-else>
            {{ t('send-us-error-report') }}
          </span></button
        ><br />
        <small>{{ t('error-report-descr') }}</small>
      </div>
      <div v-else>
        Report sent!
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import User from '../../models/user'
import { useFirebase } from '../../plugins/firebase'
import { State } from '../../store'
import Popup from './Popup.vue'

const errorSent = ref<boolean>(false)
const firebase = useFirebase()
const { t } = useI18n()
const store = useStore<State>()
const error = store.state.popup.args.err
const loading = ref<boolean>(false)

const sendError = () => {
  loading.value = true
  User.getCurrent().then((res) =>
    firebase
      .function('errorsToDev', {
        user: res.uid,
        url: window.location.href,
        error:
          typeof error === 'object'
            ? { message: error.message, stack: error.stack }
            : error,
      })
      .then(() => (errorSent.value = true)),
  )
}
</script>
