import { createI18n } from 'vue-i18n'
import it from './it'
import en from './en'
import es from './es'
import fr from './fr'
import pt from './pt'
import de from './de'

export default createI18n({
  locale: localStorage.getItem('locale') ?? 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages: {
    en,
    it,
    es,
    fr,
    pt,
    de,
  },
})
