<template>
  <Heading :text="project?.uid ? t('edit-project') : t('create-project')" />
  <RouterLink
    to="/app/advertiser/projects"
    class="flex items-center gap-2 top-4 sm:top-6 left-4 sm:left-6 text-sm"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-4 w-4"
      fill="black"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
    {{ t('back-to') }} {{ t('projects') }}
  </RouterLink>
  <div v-if="project">
    <FormRow
      v-model="project.name"
      name="name"
      type="text"
      :placeholder="t('project-name')"
    />
    <FormRow
      v-model="project.domain"
      name="domain"
      type="text"
      :placeholder="t('project-domain')"
    />
    <FormRow
      v-model="project.notes"
      name="notes"
      type="textarea"
      :placeholder="t('project-notes')"
    />
  </div>
  <div class="flex justify-end">
    <RouterLink
      to="/app/advertiser/projects"
      class="btn bg-red-500 text-sm mb-4 text-white"
      >{{ t('cancel') }}</RouterLink
    >
    <button class="btn gradient text-sm mb-4" @click="save">
      {{ project?.uid ? t('edit-project') : t('create-project') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
import Heading from '../../../components/Heading.vue'
import { useI18n } from 'vue-i18n'
import Project from '../../../models/project'
import FormRow from '../../../components/form/FormRow.vue'
import { useRoute, useRouter } from 'vue-router'
import { ref } from 'vue'
import User from '../../../models/user'
import { useStore } from 'vuex'
import { State } from '../../../store'
import LoaderVue from '../../../components/Loader.vue'
import popup from '../../../store/popup'

const { t } = useI18n()

const router = useRouter()
const route = useRoute()
const project = ref<Project>()
const store = useStore<State>()

store.commit('popup/open', {
  popup: LoaderVue,
})

User.getCurrent().then((user) => {
  if (route.params.project)
    Project.find(route.params.project as string).then((res) => {
      project.value = res as Project
      store.commit('popup/close')
    })
  else
    project.value = new Project({
      name: '',
      domain: '',
      notes: '',
      owner: user.uid,
    })
  store.commit('popup/close')
})

const save = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  await project.value?.save()
  router.push('/app/advertiser/projects')
}
</script>
