<template>
  <div class="w-72 mx-auto text-center relative h-8 text-sm">
    {{ label }}:
    {{ selected[0] }}
    -
    {{ selected[1] }}
    <div
      class="absolute bottom-1.5 w-full h-1 cursor-pointer rounded-full shadow-none border-0 bg-gradient-to-r from-primary-500 to-secondary-500"
    />
    <input
      ref="minInput"
      :value="selected[0]"
      type="range"
      :min="min"
      :max="(avg as any)"
      :step="step"
      class="absolute left-0 bottom-0 focus:outline-none appearance-none w-full bg-transparent"
      :style="(minStyles as any)"
      @input="update"
    />
    <input
      ref="maxInput"
      :value="selected[1]"
      type="range"
      :min="(avg as any)"
      :max="max"
      :step="step"
      class="absolute right-0 bottom-0 focus:outline-none appearance-none w-full bg-transparent"
      :style="maxStyles as any"
      @input="update"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed } from '@vue/reactivity'
import { ref } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: Array as unknown as () => [number, number],
    default: () => undefined,
  },
})

const emit = defineEmits(['update:model-value'])

const selected = ref(props.modelValue ?? [props.min, props.max])

const minInput = ref<HTMLInputElement>()
const maxInput = ref<HTMLInputElement>()
const avg = computed(() =>
  Math.floor((selected.value[0] + selected.value[1]) / 2),
)
const minStyles = computed(() => ({
  width: ((avg.value - props.min) / (props.max - props.min)) * 288 + 'px',
  minWidth: '16px',
  maxWidth: '272px',
}))
const maxStyles = computed(() => ({
  width: ((props.max - avg.value) / (props.max - props.min)) * 288 + 'px',
  minWidth: '16px',
  maxWidth: '272px',
}))
const update = () => {
  selected.value = [
    Math.floor(Number(minInput.value?.value ?? props.min)),
    Math.floor(Number(maxInput.value?.value ?? props.max)),
  ]
  emit('update:model-value', selected.value)
}
</script>
<style>
input[type='range']::-ms-thumb {
  @apply relative h-4 w-4 cursor-pointer appearance-none -mt-1.5 rounded-full shadow-sm border-none;
}
input[type='range']::-webkit-slider-thumb {
  @apply relative h-4 w-4 cursor-pointer appearance-none -mt-1.5 rounded-full shadow-sm border-none;
}
input[type='range']::-moz-range-thumb {
  @apply relative h-4 w-4 cursor-pointer appearance-none -mt-1.5 rounded-full shadow-sm border-none;
}

input[type='range']:first-of-type::-ms-thumb {
  @apply bg-primary-400;
}
input[type='range']:first-of-type::-webkit-slider-thumb {
  @apply bg-primary-400;
}
input[type='range']:first-of-type::-moz-range-thumb {
  @apply bg-primary-400;
}
input[type='range']:last-of-type::-ms-thumb {
  @apply bg-secondary-400;
}
input[type='range']:last-of-type::-webkit-slider-thumb {
  @apply bg-secondary-400;
}
input[type='range']:last-of-type::-moz-range-thumb {
  @apply bg-secondary-400;
}
</style>
