import {
  collection,
  DocumentData,
  getDocs,
  query,
  Timestamp,
  where,
} from '@firebase/firestore'
import User from './user'
import Model from './model'
import { firebase } from '../plugins/firebase'

interface LogModel {
  uid?: string
  url?: string
  env: string
  user?: string
  message: {
    message: string
    stack: string
  }|string
  createdAt: Date
  solved: boolean
  function?: string
}

export default class Log extends Model implements LogModel {
  public collection = 'logs'
  static collection = 'logs'

  public uid?: string
  public url?: string
  public env: string
  public user?: string
  public message: {
    message: string
    stack: string
  }|string
  public createdAt: Date
  public solved: boolean
  public function?: string

  public constructor(attributes: LogModel) {
    super()
    this.uid = attributes.uid
    this.url = attributes.url
    this.env = attributes.env
    this.user = attributes.user
    this.message = attributes.message
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
    this.solved = attributes.solved
    this.function = attributes.function
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as LogModel
    return new this({
      uid: id,
      ...attrs,
    })
  }
}
