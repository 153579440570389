<template>
  <div class="fixed bg-black bg-opacity-25 -inset-8 p-8 z-50" @click.self="close">
    <div
      :class="{
        'xl:max-w-screen-md': size === 'md',
        'xl:max-w-screen-lg': size === 'lg',
      }"
      class="max-w-[95vw] max-h-[90vh] overflow-scroll absolute bg-white p-8 rounded w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
    >
      <button class="absolute top-2 right-2" @click="close">
        <XIcon class="w-6 h-6" />
      </button>
      <slot />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { XIcon } from '@heroicons/vue/outline'

defineProps({
  size: {
    type: String,
    required: false,
    default: 'md',
  },
})

const store = useStore()
const close = () => store.commit('popup/close')
</script>
