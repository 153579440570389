<template>
  <Popup>
    <div>
      Message: <strong>{{ message.message }}</strong>
      <hr />
      Stack:<br />
      <div v-if="message.stack" v-html="message.stack.split('\n').join('<br />')" />
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore<State>()

const message = store.state.popup.args.message
</script>
