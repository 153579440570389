<template>
  <transition
    enter-active-class="transition ease-out"
    enter-from-class="translate-y-full"
    leave-active-class="transition ease-in"
    leave-to-class="translate-y-full"
  >
    <div
      v-if="store.state.snackbar.text"
      class="fixed bottom-0 w-full max-w-screen-md left-1/2 -translate-x-1/2 p-4"
    >
      <div class="w-full gradient z-50 rounded shadow-lg p-4 text-xl">
        {{ t(store.state.snackbar.text) }}
      </div>
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'
import { State } from '../store'

const { t } = useI18n()
const store = useStore<State>()
</script>
