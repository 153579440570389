import {
  collection,
  DocumentData,
  getDocs,
  query,
  where,
} from '@firebase/firestore'
import { firebase } from '../plugins/firebase'
import Model from './model'

interface SettingModel {
  uid?: string
  key: string
  value: any
}

export default class Setting extends Model implements SettingModel {
  public collection = 'settings'
  static collection = 'settings'
  public key: string
  public value: any

  public constructor(attributes: {
    uid?: string
    key: string
    value?: any
  }) {
    super()
    this.uid = attributes.uid
    this.key = attributes.key
    this.value = attributes.value
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as SettingModel
    return new this({
      uid: id,
      ...attrs,
    })
  }

  public static async findOrCreate(key: string) {
    const firestore = firebase.getFirestore()
    const q = query(collection(firestore, 'settings'), where('key', '==', key))
    const setting = await getDocs(q)
    if (setting.docs.length)
      return this.instantiate(setting.docs[0].id, setting.docs[0].data())
    else return new this({ key })
  }
}
