<template>
  <div
    class="max-w-lg w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
    :class="{ 'ring ring-primary-500': dragging }"
    @dragenter.prevent.stop="dragging = true"
    @dragover.prevent.stop="dragging = true"
    @dragleave.prevent.stop="dragging = false"
    @drop.prevent.stop="handle"
  >
    <div class="space-y-1 text-center">
      <img v-if="currentImage" :src="currentImage" />
      <svg
        v-else
        class="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <div class="flex justify-center text-sm text-gray-600">
        <label
          for="file-upload"
          class="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
        >
          <span>{{ t('upload-a-file') }}</span>
          <input
            id="file-upload"
            name="file-upload"
            type="file"
            class="sr-only"
            @change="change"
          />
        </label>
        <p class="pl-1">
          {{ t('or-drag-and-drop') }}
          <span v-if="props.accepts">({{ props.accepts }})</span>
        </p>
      </div>
      <p v-if="file" class="text-sm text-gray-500">
        {{ t('selected') }}: {{ file?.name }}
      </p>
      <p v-if="wrongext" class="text-sm text-red-500">
        {{ t('wrong-file-extension') }}
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  name: string
  required?: boolean
  accepts?: string
  currentImage?: string
}>()

const { t } = useI18n()
const emit = defineEmits(['change'])
const dragging = ref(false)
const wrongext = ref(false)
const file = ref<File>()

const change = (e: any) => {
  wrongext.value = false
  if (props.accepts) {
    let extcheck = false
    for (const ext of props.accepts.split(',')) {
      if (e.target.files[0].name.includes(ext)) extcheck = true
    }
    if (!extcheck) {
      wrongext.value = true
      return
    }
  }
  file.value = e.target.files[0]
  emit('change', e.target.files[0])
}
const handle = (e: DragEvent) => {
  dragging.value = false
  file.value = e.dataTransfer?.files[0]
  emit('change', e.dataTransfer?.files[0])
}
</script>