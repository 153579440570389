<template>
  <Popper :content="t(content)" hover>
    <slot />
  </Popper>
</template>
<script setup lang="ts">
import Popper from 'vue3-popper'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineProps<{
  content: string,
}>()
</script>
<style>
  :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 0px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 16px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
</style>
