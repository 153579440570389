<template>
  <div
    ref="root"
    class="fixed top-0 left-0 z-[100] w-full h-screen bg-white bg-opacity-75 flex items-center justify-center"
  >
    <div>
      <svg
        id="Modalità_Isolamento"
        data-name="Modalità Isolamento"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 145.26 145.26"
        class="w-14 h-14 loader-svg m-auto"
        :class="{ animate: animate === true }"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="2.81"
            y1="92.71"
            x2="142.45"
            y2="52.55"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stop-color="#ffc40c" />
            <stop offset="1" stop-color="#f33" />
          </linearGradient>
        </defs>
        <circle
          class="cls-1"
          style="fill: url(#linear-gradient)"
          cx="72.63"
          cy="72.63"
          r="72.63"
        />
        <rect
          class="cls-2 one"
          style="fill: #fff"
          x="33.99"
          y="58.34"
          width="20.36"
          height="42.81"
          rx="4.84"
        />
        <rect
          class="cls-2 two"
          style="fill: #fff"
          x="62.45"
          y="44.11"
          width="20.36"
          height="57.04"
          rx="4.84"
        />
        <rect
          class="cls-2 three"
          style="fill: #fff"
          x="90.92"
          y="72.57"
          width="20.36"
          height="28.58"
          rx="4.84"
        />
      </svg>
      <div v-if="text" class="text-center text-md font-bold mt-2">
        {{ t(text) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n';
import store from '../store';

const root = ref<HTMLDivElement>()
const animate = ref<boolean>(false)
const text = store.state.popup.args?.text ?? null

const { t } = useI18n()
let start: number, previousTimeStamp: number

function step(timestamp: number) {
  if (start === undefined) {
    start = timestamp
    previousTimeStamp = timestamp
  }
  let elapsed = timestamp - previousTimeStamp

  if (elapsed > 200) {
    previousTimeStamp = timestamp
    animate.value = !animate.value
  }
  if (root.value) requestAnimationFrame(step)
}

requestAnimationFrame(step)
</script>
