<template>
  <Popup>
    <div class="lg:flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            {{ t('change-view-columns') }}
          </h4>
          <div class="mt-2 text-sm text-gray-600 max-w-2xl grid grid-cols-2">
            <div v-for="(value, k) in columns" :key="k" class="my-2">
                <label class="flex items-center gap-1">
                  <input
                    v-model="columns[k]"
                    type="checkbox"
                    class="rounded text-primary-500 focus:ring-gradient"
                  />
                  {{ t(k) }}
                </label>
              </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'
import { watch } from 'vue'

const { t } = useI18n()
const store = useStore<State>()

const columns = store.state.popup.args.columns as object
const columnsUpdate = store.state.popup.args.columnsUpdate

watch(columns, (val) => columnsUpdate(val))
</script>
