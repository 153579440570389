import { DocumentData, Timestamp } from '@firebase/firestore'
import Model from './model'

interface BundleModel {
  uid?: string
  name: string
  products: {
    uid: string
    discount: number
  }[]
  discount: number
  createdAt: Date
  updatedAt: Date
}

export default class Bundle extends Model implements BundleModel {
  public collection = 'bundles'
  static collection = 'bundles'

  public uid?: string
  public name: string
  public products: {
    uid: string
    discount: number
  }[]
  public discount: number
  public createdAt: Date
  public updatedAt: Date

  public constructor(attributes: BundleModel) {
    super()
    this.uid = attributes.uid
    this.name = attributes.name
    this.products = attributes.products
    this.discount = attributes.discount
    this.createdAt =
      attributes.createdAt instanceof Timestamp
        ? attributes.createdAt.toDate()
        : attributes.createdAt
    this.updatedAt =
      attributes.updatedAt instanceof Timestamp
        ? attributes.updatedAt.toDate()
        : attributes.updatedAt
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as BundleModel
    return new this({
      uid: id,
      ...attrs,
    })
  }
}
