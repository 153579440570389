<template>
  <Popup>
    <div class="w-full">
      <PillTabs v-model="type" :values="['Privato', 'Azienda']" />
      <template v-if="type === 0">
        <FormRow
          v-model="ic.firstName"
          name="first-name"
          :label="t('first-name')"
          type="text"
          required
        />
        <FormRow
          v-model="ic.lastName"
          name="last-name"
          :label="t('last-name')"
          type="text"
          required
        />
      </template>
      <template v-else>
        <FormRow
          v-model="ic.organization"
          name="organization"
          :label="t('organization')"
          type="text"
          required
        />
      </template>
      <FormRow
        v-model="ic.vat"
        name="vat"
        :label="type === 0 ? t('fiscal-code') : t('vat-number')"
        type="text"
        required
      />
      <FormRow
        v-model="ic.address"
        name="address"
        :label="t('address')"
        type="text"
        required
      />
      <FormRow
        v-model="ic.city"
        name="city"
        :label="t('city')"
        type="text"
        required
      />
      <FormRow
        v-model="ic.zip"
        name="zip"
        :label="t('postalcode')"
        type="text"
        required
      />
      <FormRow
        v-model="ic.country"
        name="country"
        :label="t('country')"
        type="select"
        required
      >
        <option value="">{{ t('select-country') }}</option>
        <option v-for="c of countries" :key="c.code" :value="c.code">
          {{ c.name }}
        </option></FormRow
      >
      <FormRow
        v-if="states[ic.country as keyof typeof states]"
        v-model="ic.state"
        name="state"
        :label="t('province')"
        type="select"
        required
      >
        <option value="">{{ t('select-state') }}</option>
        <option
          v-for="c, k of states[ic.country as keyof typeof states]"
          :key="k"
          :value="k"
        >
          {{ c }}
        </option></FormRow
      >
      <FormRow
        v-else
        v-model="ic.state"
        name="state"
        :label="t('province')"
        type="text"
        required
      />
      <FormRow
        v-if="ic.country === 'it'"
        v-model="ic.sdi"
        name="sdi"
        :label="t('sdi')"
        type="text"
      />
      <FormRow
        v-if="ic.country === 'it'"
        v-model="ic.pec"
        name="pec"
        :label="t('pec')"
        type="text"
      />
    </div>
    <div class="flex items-center justify-end gap-1 mt-8">
      <button class="btn gradient" @click="save">
        {{ t('save') }}
      </button>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import Popup from './Popup.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import PillTabs from '../PillTabs.vue'
import countries from '../../plugins/countries'
import states from '../../plugins/states'
import FormRow from '../form/FormRow.vue'
import { doc, updateDoc } from '@firebase/firestore'
import { useFirebase } from '../../plugins/firebase'
import Loader from '../Loader.vue'

const type = ref(0)
const { t } = useI18n()
const store = useStore<State>()
const firebase = useFirebase()
const firestore = firebase.getFirestore()
const ic = ref<any>(store.state.popup.args.ic)
const user = store.state.popup.args.user
const save = async () => {
  store.commit('popup/open', { popup: Loader })
  if (ic.value.country === 'it' && ic.value.sdi === '' && ic.value.pec === '')
    ic.value.sdi = '0000000'
  await updateDoc(
    doc(
      firestore,
      `/users/${user.uid}/invoicingAccounts/${ic.value.uid}`,
    ),
    ic.value,
  )
  store.commit('popup/close')
}
</script>
