<template>
  <div
    class="hidden z-10 md:flex md:fixed md:inset-y-0"
    :class="{ 'w-64': menuFull }"
  >
    <div class="flex flex-col flex-grow bg-gray-700 overflow-y-auto">
      <div v-if="menuFull" class="flex-shrink-0 px-4 mt-4">
        <Logo type="white" />
      </div>
      <div v-if="!menuFull" class="flex-shrink-0 px-4 mt-4">
        <Logo type="favicon" />
      </div>
      <div v-if="menuFull" class="w-64"></div>
      <div
        v-if="menuFull && !isAdmin"
        class="mx-4 mt-4 mb-2 bg-gray-600 text-white rounded-md px-4 py-4"
      >
        <Switch />
      </div>
      <div
        v-if="menuFull && !isAdmin"
        class="flex items-center flex-shrink-0 px-4 mt-2 text-white"
      >
        <span v-if="user?.displayName"
          >{{ t('hi-') }} {{ user?.displayName ?? user?.email }}!</span
        >
        <span v-else>{{ t('hi-').slice(0, -1) }}!</span>
      </div>
      <div class="mt-5 flex-1 flex flex-col">
        <nav class="flex flex-col flex-1 pb-4">
          <RouterLink
            v-for="item in nav"
            :key="item.name"
            :to="item.href"
            :class="[
              'group flex items-center px-4 py-4 text-sm animate-bg relative',
              route.path === item.href
                ? 'static-gradient text-white'
                : 'text-white hover:static-gradient font-light',
              !menuFull ? 'justify-center' : '',
            ]"
            @mouseenter="item.preload && item.preload()"
          >
            <component
              v-if="typeof item.icon === 'function'"
              :is="item.icon"
              class="flex-shrink-0 h-6 w-6"
              aria-hidden="true"
            />
            <div v-else v-html="item.icon"></div>
            <div v-if="menuFull" class="ml-3">
              <span class="font-bold">{{ t(item.name) }}</span>
              <div v-if="'text' in item" class="text-[.8em] lowercase">
                {{ item.text ? t(item.text) : '' }}
              </div>
            </div>
            <div
              v-if="item.badge && menuFull"
              class="absolute top-2 right-2 bg-red-500 rounded-md p-1 text-xs"
            >
              {{ item.badge }}
            </div>
          </RouterLink>
          <div class="flex-grow"></div>
          <a
            v-if="user?.isAdmin || user?.isSoftAdmin"
            href="https://app.rankister.com"
            target="_BLANK"
            :class="[
              'text-white hover:static-gradient font-light group flex items-center px-4 py-4 text-sm',
              !menuFull ? 'justify-center' : '',
            ]"
          >
            <UserIcon class="flex-shrink-0 h-6 w-6" aria-hidden="true" />
            <div v-if="menuFull" class="ml-3">
              <span class="font-bold">{{
                route.fullPath.startsWith('/app/admin') ? 'User' : 'Admin'
              }}</span>
            </div>
          </a>
          <button
            :class="[
              'bottom-2 text-white group flex items-center px-4 py-4 text-sm hover:static-gradient w-full',
              !menuFull ? 'justify-center' : '',
            ]"
            @click="$emit('toggle-menu-full')"
          >
            <ChevronRightIcon v-if="!menuFull" class="flex-shrink-0 h-6 w-6" />
            <ChevronLeftIcon v-if="menuFull" class="flex-shrink-0 h-6 w-6" />
            <div v-if="menuFull" class="ml-3">Hide</div>
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Switch from '../components/Switch.vue'
import { RouterLink, useRoute } from 'vue-router'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  IdentificationIcon,
  UserIcon,
} from '@heroicons/vue/outline'
import Logo from '../components/Logo.vue'
import User from '../models/user'
import { NavItem } from './navs'

defineProps<{
  user?: User
  isAdmin: boolean
  nav: NavItem[]
  menuFull: boolean
}>()
defineEmits(['toggle-menu-full'])

const { t } = useI18n()
const route = useRoute()
</script>
