<template>
  <Banner v-if="visible" bg="bg-red-500">
    {{ t('order-incomplete') }}
    <router-link class="underline" to="/app/advertiser/orders">{{
      t('see-orders')
    }}</router-link>
  </Banner>
</template>
<script lang="ts" setup>
import Banner from './Banner.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import Order from '../models/order'
import { useStore } from 'vuex'
import { State } from '../store'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const store = useStore<State>()
const visible = ref(false)
const isAdmin = useRoute().fullPath.startsWith('/app/admin')

if (!isAdmin && store.state.AccountType.type === 'advertiser') {
  Order.own().then((orders) => {
    visible.value = Boolean(
      orders
        ?.filter(
          (ord) =>
            ord.status !== 'completed' &&
            ord.status !== 'canceled' &&
            ord.status !== 'refunded',
        )
        ?.reduce(
          (acc, ord) =>
            acc ||
            (['pending', 'paid'].includes(ord.status) && !ord.advertiserInfo),
          false as boolean,
        ),
    )
  })
}
</script>
