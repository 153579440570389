<template>
  <div class="h-screen flex">
    <div
      class="relative flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24"
    >
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <Logo type="dark" />
          <h2
            class="flex justify-between items-center mt-6 text-3xl font-extrabold text-gray-900"
          >
            {{ t('sign-in-to-your-account') }}
            <Language />
          </h2>
        </div>

        <div class="hidden mt-4">
          <!-- sm:hidden -->
          <img
            class="rounded-md"
            src="https://rankister.com/wp-content/uploads/2022/11/BlackFriday_Rankister_MobileBanner.jpg"
          />
        </div>

        <div class="mt-8">
          <div v-if="currentError" class="text-red-500 my-2">
            <span v-if="!emailVerificationSent">{{ t(currentError) }}</span>
          </div>
          <div class="mt-6">
            <form
              action="#"
              method="POST"
              class="space-y-6"
              @submit.prevent="login"
            >
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ t('email-address') }}
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="space-y-1">
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ t('password') }}
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    v-model="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    class="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                  />
                  <label
                    for="remember-me"
                    class="ml-2 block text-sm text-gray-900"
                  >
                    {{ t('remember-me') }}
                  </label>
                </div>

                <div class="text-sm">
                  <RouterLink
                    to="/password-recover"
                    class="font-medium text-primary-600 hover:text-primary-500"
                  >
                    {{ t('forgot-your-password') }}?
                  </RouterLink>
                </div>
              </div>
              <Recaptcha :handle-success="RecaptchaSuccess" />
              <div>
                <button
                  type="submit"
                  :disabled="loading || !isValid || !Recaptched"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                >
                  {{ t('sign-in') }}
                </button>
                <RouterLink
                  to="/register"
                  class="font-medium text-primary-600 hover:text-primary-500 text-center block mt-4"
                >
                  {{ t('don-t-have-an-account') }}? {{ t('register-now') }}!
                </RouterLink>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        v-if="loading"
        class="flex justify-center items-center absolute inset-0 bg-white bg-opacity-75"
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary-500 text-lg"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Loading...
      </div>
    </div>
    <div class="hidden lg:block">
      <img
        class="h-auto w-full object-cover"
        src="https://rankister.com/wp-content/uploads/2022/12/banner-rankister-desktop.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
  UserCredential,
  AuthError,
  signInWithEmailAndPassword,
} from '@firebase/auth'
import Logo from '../components/Logo.vue'
import { RouterLink } from 'vue-router'
import { useFirebase } from '../plugins/firebase'
import { useStorage } from '../plugins/storage'
import { useI18n } from 'vue-i18n'
import Language from '../components/Language.vue'
import Recaptcha from '../components/Recaptcha.vue'
import VueAdblockDetector from 'vue-adblock-detector'

const Recaptched = ref<boolean>(import.meta.env.DEV ? true : false)
const { t } = useI18n()
const auth = useFirebase().getAuth()
auth.useDeviceLanguage()
const email = ref('')
const password = ref('')
const isValid = computed(
  () => /.+@.+\..+/.test(email.value) && password.value?.length >= 6,
)

const RecaptchaSuccess = (payload: Event) => {
  console.log(payload)
  Recaptched.value = true
}

const loading = ref(false)
const storage = useStorage()
const currentError = ref<null | string>(null)
const currentCredential = ref<null | UserCredential>(null)
const emailVerificationSent = ref<boolean>(false)
const handleRequest = (promise: Promise<UserCredential>) => {
  loading.value = true
  promise
    .then((credential: UserCredential) => {
      currentCredential.value = credential
      if (credential.user.emailVerified)
        storage?.set('email', credential.user.email)
      else currentError.value = 'auth/email-not-verified'
    })
    .catch((error: AuthError) => {
      currentError.value = error.code
      console.error(error)
    })
    .finally(() => (loading.value = false))
}

const login = () => {
  currentError.value = null
  if (!isValid.value) return
  handleRequest(signInWithEmailAndPassword(auth, email.value, password.value))
}

VueAdblockDetector.detectAnyAdblocker().then((detected: boolean) => {
  if (detected) alert(t('adblock-disclaimer'))
})
</script>
