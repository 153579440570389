export default [
  {
    code: 'it',
    flag_1x1: 'flags/1x1/it.svg',
    flag_4x3: 'flags/4x3/it.svg',
    iso: true,
    name: 'Italy',
  },
  {
    code: 'fr',
    flag_1x1: 'flags/1x1/fr.svg',
    flag_4x3: 'flags/4x3/fr.svg',
    iso: true,
    name: 'France',
  },
  {
    code: 'de',
    flag_1x1: 'flags/1x1/de.svg',
    flag_4x3: 'flags/4x3/de.svg',
    iso: true,
    name: 'Germany',
  },
  {
    code: 'es',
    flag_1x1: 'flags/1x1/es.svg',
    flag_4x3: 'flags/4x3/es.svg',
    iso: true,
    name: 'Spain',
  },
  {
    code: 'gb',
    flag_1x1: 'flags/1x1/gb.svg',
    flag_4x3: 'flags/4x3/gb.svg',
    iso: true,
    name: 'United Kingdom',
  },
  {
    code: 'us',
    flag_1x1: 'flags/1x1/us.svg',
    flag_4x3: 'flags/4x3/us.svg',
    iso: true,
    name: 'United States of America',
  },
  {
    code: 'br',
    flag_1x1: 'flags/1x1/br.svg',
    flag_4x3: 'flags/4x3/br.svg',
    iso: true,
    name: 'Brazil',
  },
  {
    code: 'ch_it',
    flag_1x1: 'flags/1x1/ch.svg',
    flag_4x3: 'flags/4x3/ch.svg',
    iso: true,
    name: 'Switzerland (Italian)',
  },
  {
    code: 'ch_de',
    flag_1x1: 'flags/1x1/ch.svg',
    flag_4x3: 'flags/4x3/ch.svg',
    iso: true,
    name: 'Switzerland (Deutch)',
  },
  {
    code: 'ch_fr',
    flag_1x1: 'flags/1x1/ch.svg',
    flag_4x3: 'flags/4x3/ch.svg',
    iso: true,
    name: 'Switzerland (French)',
  },
  {
    code: 'ac',
    flag_1x1: 'flags/1x1/ac.svg',
    flag_4x3: 'flags/4x3/ac.svg',
    iso: false,
    name: 'Ascension Island',
  },
  {
    code: 'ad',
    flag_1x1: 'flags/1x1/ad.svg',
    flag_4x3: 'flags/4x3/ad.svg',
    iso: true,
    name: 'Andorra',
  },
  {
    code: 'ae',
    flag_1x1: 'flags/1x1/ae.svg',
    flag_4x3: 'flags/4x3/ae.svg',
    iso: true,
    name: 'United Arab Emirates',
  },
  {
    code: 'af',
    flag_1x1: 'flags/1x1/af.svg',
    flag_4x3: 'flags/4x3/af.svg',
    iso: true,
    name: 'Afghanistan',
  },
  {
    code: 'ag',
    flag_1x1: 'flags/1x1/ag.svg',
    flag_4x3: 'flags/4x3/ag.svg',
    iso: true,
    name: 'Antigua and Barbuda',
  },
  {
    code: 'ai',
    flag_1x1: 'flags/1x1/ai.svg',
    flag_4x3: 'flags/4x3/ai.svg',
    iso: true,
    name: 'Anguilla',
  },
  {
    code: 'al',
    flag_1x1: 'flags/1x1/al.svg',
    flag_4x3: 'flags/4x3/al.svg',
    iso: true,
    name: 'Albania',
  },
  {
    code: 'am',
    flag_1x1: 'flags/1x1/am.svg',
    flag_4x3: 'flags/4x3/am.svg',
    iso: true,
    name: 'Armenia',
  },
  {
    code: 'ao',
    flag_1x1: 'flags/1x1/ao.svg',
    flag_4x3: 'flags/4x3/ao.svg',
    iso: true,
    name: 'Angola',
  },
  {
    code: 'aq',
    flag_1x1: 'flags/1x1/aq.svg',
    flag_4x3: 'flags/4x3/aq.svg',
    iso: true,
    name: 'Antarctica',
  },
  {
    code: 'ar',
    flag_1x1: 'flags/1x1/ar.svg',
    flag_4x3: 'flags/4x3/ar.svg',
    iso: true,
    name: 'Argentina',
  },
  {
    code: 'as',
    flag_1x1: 'flags/1x1/as.svg',
    flag_4x3: 'flags/4x3/as.svg',
    iso: true,
    name: 'American Samoa',
  },
  {
    code: 'at',
    flag_1x1: 'flags/1x1/at.svg',
    flag_4x3: 'flags/4x3/at.svg',
    iso: true,
    name: 'Austria',
  },
  {
    code: 'au',
    flag_1x1: 'flags/1x1/au.svg',
    flag_4x3: 'flags/4x3/au.svg',
    iso: true,
    name: 'Australia',
  },
  {
    code: 'aw',
    flag_1x1: 'flags/1x1/aw.svg',
    flag_4x3: 'flags/4x3/aw.svg',
    iso: true,
    name: 'Aruba',
  },
  {
    code: 'ax',
    flag_1x1: 'flags/1x1/ax.svg',
    flag_4x3: 'flags/4x3/ax.svg',
    iso: true,
    name: 'Aland Islands',
  },
  {
    code: 'az',
    flag_1x1: 'flags/1x1/az.svg',
    flag_4x3: 'flags/4x3/az.svg',
    iso: true,
    name: 'Azerbaijan',
  },
  {
    code: 'ba',
    flag_1x1: 'flags/1x1/ba.svg',
    flag_4x3: 'flags/4x3/ba.svg',
    iso: true,
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'bb',
    flag_1x1: 'flags/1x1/bb.svg',
    flag_4x3: 'flags/4x3/bb.svg',
    iso: true,
    name: 'Barbados',
  },
  {
    code: 'bd',
    flag_1x1: 'flags/1x1/bd.svg',
    flag_4x3: 'flags/4x3/bd.svg',
    iso: true,
    name: 'Bangladesh',
  },
  {
    code: 'be',
    flag_1x1: 'flags/1x1/be.svg',
    flag_4x3: 'flags/4x3/be.svg',
    iso: true,
    name: 'Belgium',
  },
  {
    code: 'bf',
    flag_1x1: 'flags/1x1/bf.svg',
    flag_4x3: 'flags/4x3/bf.svg',
    iso: true,
    name: 'Burkina Faso',
  },
  {
    code: 'bg',
    flag_1x1: 'flags/1x1/bg.svg',
    flag_4x3: 'flags/4x3/bg.svg',
    iso: true,
    name: 'Bulgaria',
  },
  {
    code: 'bh',
    flag_1x1: 'flags/1x1/bh.svg',
    flag_4x3: 'flags/4x3/bh.svg',
    iso: true,
    name: 'Bahrain',
  },
  {
    code: 'bi',
    flag_1x1: 'flags/1x1/bi.svg',
    flag_4x3: 'flags/4x3/bi.svg',
    iso: true,
    name: 'Burundi',
  },
  {
    code: 'bj',
    flag_1x1: 'flags/1x1/bj.svg',
    flag_4x3: 'flags/4x3/bj.svg',
    iso: true,
    name: 'Benin',
  },
  {
    code: 'bl',
    flag_1x1: 'flags/1x1/bl.svg',
    flag_4x3: 'flags/4x3/bl.svg',
    iso: true,
    name: 'Saint Barth\u00e9lemy',
  },
  {
    code: 'bm',
    flag_1x1: 'flags/1x1/bm.svg',
    flag_4x3: 'flags/4x3/bm.svg',
    iso: true,
    name: 'Bermuda',
  },
  {
    code: 'bn',
    flag_1x1: 'flags/1x1/bn.svg',
    flag_4x3: 'flags/4x3/bn.svg',
    iso: true,
    name: 'Brunei Darussalam',
  },
  {
    code: 'bo',
    flag_1x1: 'flags/1x1/bo.svg',
    flag_4x3: 'flags/4x3/bo.svg',
    iso: true,
    name: 'Bolivia (Plurinational State of)',
  },
  {
    code: 'bq',
    flag_1x1: 'flags/1x1/bq.svg',
    flag_4x3: 'flags/4x3/bq.svg',
    iso: true,
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    code: 'bs',
    flag_1x1: 'flags/1x1/bs.svg',
    flag_4x3: 'flags/4x3/bs.svg',
    iso: true,
    name: 'Bahamas',
  },
  {
    code: 'bt',
    flag_1x1: 'flags/1x1/bt.svg',
    flag_4x3: 'flags/4x3/bt.svg',
    iso: true,
    name: 'Bhutan',
  },
  {
    code: 'bv',
    flag_1x1: 'flags/1x1/bv.svg',
    flag_4x3: 'flags/4x3/bv.svg',
    iso: true,
    name: 'Bouvet Island',
  },
  {
    code: 'bw',
    flag_1x1: 'flags/1x1/bw.svg',
    flag_4x3: 'flags/4x3/bw.svg',
    iso: true,
    name: 'Botswana',
  },
  {
    code: 'by',
    flag_1x1: 'flags/1x1/by.svg',
    flag_4x3: 'flags/4x3/by.svg',
    iso: true,
    name: 'Belarus',
  },
  {
    code: 'bz',
    flag_1x1: 'flags/1x1/bz.svg',
    flag_4x3: 'flags/4x3/bz.svg',
    iso: true,
    name: 'Belize',
  },
  {
    code: 'ca',
    flag_1x1: 'flags/1x1/ca.svg',
    flag_4x3: 'flags/4x3/ca.svg',
    iso: true,
    name: 'Canada',
  },
  {
    code: 'cc',
    flag_1x1: 'flags/1x1/cc.svg',
    flag_4x3: 'flags/4x3/cc.svg',
    iso: true,
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'cd',
    flag_1x1: 'flags/1x1/cd.svg',
    flag_4x3: 'flags/4x3/cd.svg',
    iso: true,
    name: 'Democratic Republic of the Congo',
  },
  {
    code: 'cf',
    flag_1x1: 'flags/1x1/cf.svg',
    flag_4x3: 'flags/4x3/cf.svg',
    iso: true,
    name: 'Central African Republic',
  },
  {
    code: 'cg',
    flag_1x1: 'flags/1x1/cg.svg',
    flag_4x3: 'flags/4x3/cg.svg',
    iso: true,
    name: 'Republic of the Congo',
  },
  {
    code: 'ch',
    flag_1x1: 'flags/1x1/ch.svg',
    flag_4x3: 'flags/4x3/ch.svg',
    iso: true,
    name: 'Switzerland',
  },
  {
    code: 'ci',
    flag_1x1: 'flags/1x1/ci.svg',
    flag_4x3: 'flags/4x3/ci.svg',
    iso: true,
    name: "C\u00f4te d'Ivoire",
  },
  {
    code: 'ck',
    flag_1x1: 'flags/1x1/ck.svg',
    flag_4x3: 'flags/4x3/ck.svg',
    iso: true,
    name: 'Cook Islands',
  },
  {
    code: 'cl',
    flag_1x1: 'flags/1x1/cl.svg',
    flag_4x3: 'flags/4x3/cl.svg',
    iso: true,
    name: 'Chile',
  },
  {
    code: 'cm',
    flag_1x1: 'flags/1x1/cm.svg',
    flag_4x3: 'flags/4x3/cm.svg',
    iso: true,
    name: 'Cameroon',
  },
  {
    code: 'cn',
    flag_1x1: 'flags/1x1/cn.svg',
    flag_4x3: 'flags/4x3/cn.svg',
    iso: true,
    name: 'China',
  },
  {
    code: 'co',
    flag_1x1: 'flags/1x1/co.svg',
    flag_4x3: 'flags/4x3/co.svg',
    iso: true,
    name: 'Colombia',
  },
  {
    code: 'cp',
    flag_1x1: 'flags/1x1/cp.svg',
    flag_4x3: 'flags/4x3/cp.svg',
    iso: false,
    name: 'Clipperton Island',
  },
  {
    code: 'cr',
    flag_1x1: 'flags/1x1/cr.svg',
    flag_4x3: 'flags/4x3/cr.svg',
    iso: true,
    name: 'Costa Rica',
  },
  {
    code: 'cu',
    flag_1x1: 'flags/1x1/cu.svg',
    flag_4x3: 'flags/4x3/cu.svg',
    iso: true,
    name: 'Cuba',
  },
  {
    code: 'cv',
    flag_1x1: 'flags/1x1/cv.svg',
    flag_4x3: 'flags/4x3/cv.svg',
    iso: true,
    name: 'Cabo Verde',
  },
  {
    code: 'cw',
    flag_1x1: 'flags/1x1/cw.svg',
    flag_4x3: 'flags/4x3/cw.svg',
    iso: true,
    name: 'Cura\u00e7ao',
  },
  {
    code: 'cx',
    flag_1x1: 'flags/1x1/cx.svg',
    flag_4x3: 'flags/4x3/cx.svg',
    iso: true,
    name: 'Christmas Island',
  },
  {
    code: 'cy',
    flag_1x1: 'flags/1x1/cy.svg',
    flag_4x3: 'flags/4x3/cy.svg',
    iso: true,
    name: 'Cyprus',
  },
  {
    code: 'cz',
    flag_1x1: 'flags/1x1/cz.svg',
    flag_4x3: 'flags/4x3/cz.svg',
    iso: true,
    name: 'Czech Republic',
  },
  {
    code: 'dg',
    flag_1x1: 'flags/1x1/dg.svg',
    flag_4x3: 'flags/4x3/dg.svg',
    iso: false,
    name: 'Diego Garcia',
  },
  {
    code: 'dj',
    flag_1x1: 'flags/1x1/dj.svg',
    flag_4x3: 'flags/4x3/dj.svg',
    iso: true,
    name: 'Djibouti',
  },
  {
    code: 'dk',
    flag_1x1: 'flags/1x1/dk.svg',
    flag_4x3: 'flags/4x3/dk.svg',
    iso: true,
    name: 'Denmark',
  },
  {
    code: 'dm',
    flag_1x1: 'flags/1x1/dm.svg',
    flag_4x3: 'flags/4x3/dm.svg',
    iso: true,
    name: 'Dominica',
  },
  {
    code: 'do',
    flag_1x1: 'flags/1x1/do.svg',
    flag_4x3: 'flags/4x3/do.svg',
    iso: true,
    name: 'Dominican Republic',
  },
  {
    code: 'dz',
    flag_1x1: 'flags/1x1/dz.svg',
    flag_4x3: 'flags/4x3/dz.svg',
    iso: true,
    name: 'Algeria',
  },
  {
    code: 'ea',
    flag_1x1: 'flags/1x1/ea.svg',
    flag_4x3: 'flags/4x3/ea.svg',
    iso: false,
    name: 'Ceuta & Melilla',
  },
  {
    code: 'ec',
    flag_1x1: 'flags/1x1/ec.svg',
    flag_4x3: 'flags/4x3/ec.svg',
    iso: true,
    name: 'Ecuador',
  },
  {
    code: 'ee',
    flag_1x1: 'flags/1x1/ee.svg',
    flag_4x3: 'flags/4x3/ee.svg',
    iso: true,
    name: 'Estonia',
  },
  {
    code: 'eg',
    flag_1x1: 'flags/1x1/eg.svg',
    flag_4x3: 'flags/4x3/eg.svg',
    iso: true,
    name: 'Egypt',
  },
  {
    code: 'eh',
    flag_1x1: 'flags/1x1/eh.svg',
    flag_4x3: 'flags/4x3/eh.svg',
    iso: true,
    name: 'Western Sahara',
  },
  {
    code: 'er',
    flag_1x1: 'flags/1x1/er.svg',
    flag_4x3: 'flags/4x3/er.svg',
    iso: true,
    name: 'Eritrea',
  },
  {
    code: 'es-ct',
    flag_1x1: 'flags/1x1/es-ct.svg',
    flag_4x3: 'flags/4x3/es-ct.svg',
    iso: false,
    name: 'Catalonia',
  },
  {
    code: 'es-ga',
    flag_1x1: 'flags/1x1/es-ga.svg',
    flag_4x3: 'flags/4x3/es-ga.svg',
    iso: false,
    name: 'Galicia',
  },
  {
    code: 'et',
    flag_1x1: 'flags/1x1/et.svg',
    flag_4x3: 'flags/4x3/et.svg',
    iso: true,
    name: 'Ethiopia',
  },
  {
    code: 'eu',
    flag_1x1: 'flags/1x1/eu.svg',
    flag_4x3: 'flags/4x3/eu.svg',
    iso: false,
    name: 'Europe',
  },
  {
    code: 'fi',
    flag_1x1: 'flags/1x1/fi.svg',
    flag_4x3: 'flags/4x3/fi.svg',
    iso: true,
    name: 'Finland',
  },
  {
    code: 'fj',
    flag_1x1: 'flags/1x1/fj.svg',
    flag_4x3: 'flags/4x3/fj.svg',
    iso: true,
    name: 'Fiji',
  },
  {
    code: 'fk',
    flag_1x1: 'flags/1x1/fk.svg',
    flag_4x3: 'flags/4x3/fk.svg',
    iso: true,
    name: 'Falkland Islands',
  },
  {
    code: 'fm',
    flag_1x1: 'flags/1x1/fm.svg',
    flag_4x3: 'flags/4x3/fm.svg',
    iso: true,
    name: 'Federated States of Micronesia',
  },
  {
    code: 'fo',
    flag_1x1: 'flags/1x1/fo.svg',
    flag_4x3: 'flags/4x3/fo.svg',
    iso: true,
    name: 'Faroe Islands',
  },
  {
    code: 'ga',
    flag_1x1: 'flags/1x1/ga.svg',
    flag_4x3: 'flags/4x3/ga.svg',
    iso: true,
    name: 'Gabon',
  },
  {
    code: 'gb-eng',
    flag_1x1: 'flags/1x1/gb-eng.svg',
    flag_4x3: 'flags/4x3/gb-eng.svg',
    iso: false,
    name: 'England',
  },
  {
    code: 'gb-nir',
    flag_1x1: 'flags/1x1/gb-nir.svg',
    flag_4x3: 'flags/4x3/gb-nir.svg',
    iso: false,
    name: 'Northern Ireland',
  },
  {
    code: 'gb-sct',
    flag_1x1: 'flags/1x1/gb-sct.svg',
    flag_4x3: 'flags/4x3/gb-sct.svg',
    iso: false,
    name: 'Scotland',
  },
  {
    code: 'gb-wls',
    flag_1x1: 'flags/1x1/gb-wls.svg',
    flag_4x3: 'flags/4x3/gb-wls.svg',
    iso: false,
    name: 'Wales',
  },
  {
    code: 'gd',
    flag_1x1: 'flags/1x1/gd.svg',
    flag_4x3: 'flags/4x3/gd.svg',
    iso: true,
    name: 'Grenada',
  },
  {
    code: 'ge',
    flag_1x1: 'flags/1x1/ge.svg',
    flag_4x3: 'flags/4x3/ge.svg',
    iso: true,
    name: 'Georgia',
  },
  {
    code: 'gf',
    flag_1x1: 'flags/1x1/gf.svg',
    flag_4x3: 'flags/4x3/gf.svg',
    iso: true,
    name: 'French Guiana',
  },
  {
    code: 'gg',
    flag_1x1: 'flags/1x1/gg.svg',
    flag_4x3: 'flags/4x3/gg.svg',
    iso: true,
    name: 'Guernsey',
  },
  {
    code: 'gh',
    flag_1x1: 'flags/1x1/gh.svg',
    flag_4x3: 'flags/4x3/gh.svg',
    iso: true,
    name: 'Ghana',
  },
  {
    code: 'gi',
    flag_1x1: 'flags/1x1/gi.svg',
    flag_4x3: 'flags/4x3/gi.svg',
    iso: true,
    name: 'Gibraltar',
  },
  {
    code: 'gl',
    flag_1x1: 'flags/1x1/gl.svg',
    flag_4x3: 'flags/4x3/gl.svg',
    iso: true,
    name: 'Greenland',
  },
  {
    code: 'gm',
    flag_1x1: 'flags/1x1/gm.svg',
    flag_4x3: 'flags/4x3/gm.svg',
    iso: true,
    name: 'Gambia',
  },
  {
    code: 'gn',
    flag_1x1: 'flags/1x1/gn.svg',
    flag_4x3: 'flags/4x3/gn.svg',
    iso: true,
    name: 'Guinea',
  },
  {
    code: 'gp',
    flag_1x1: 'flags/1x1/gp.svg',
    flag_4x3: 'flags/4x3/gp.svg',
    iso: true,
    name: 'Guadeloupe',
  },
  {
    code: 'gq',
    flag_1x1: 'flags/1x1/gq.svg',
    flag_4x3: 'flags/4x3/gq.svg',
    iso: true,
    name: 'Equatorial Guinea',
  },
  {
    code: 'gr',
    flag_1x1: 'flags/1x1/gr.svg',
    flag_4x3: 'flags/4x3/gr.svg',
    iso: true,
    name: 'Greece',
  },
  {
    code: 'gs',
    flag_1x1: 'flags/1x1/gs.svg',
    flag_4x3: 'flags/4x3/gs.svg',
    iso: true,
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'gt',
    flag_1x1: 'flags/1x1/gt.svg',
    flag_4x3: 'flags/4x3/gt.svg',
    iso: true,
    name: 'Guatemala',
  },
  {
    code: 'gu',
    flag_1x1: 'flags/1x1/gu.svg',
    flag_4x3: 'flags/4x3/gu.svg',
    iso: true,
    name: 'Guam',
  },
  {
    code: 'gw',
    flag_1x1: 'flags/1x1/gw.svg',
    flag_4x3: 'flags/4x3/gw.svg',
    iso: true,
    name: 'Guinea-Bissau',
  },
  {
    code: 'gy',
    flag_1x1: 'flags/1x1/gy.svg',
    flag_4x3: 'flags/4x3/gy.svg',
    iso: true,
    name: 'Guyana',
  },
  {
    code: 'hk',
    flag_1x1: 'flags/1x1/hk.svg',
    flag_4x3: 'flags/4x3/hk.svg',
    iso: true,
    name: 'Hong Kong',
  },
  {
    code: 'hm',
    flag_1x1: 'flags/1x1/hm.svg',
    flag_4x3: 'flags/4x3/hm.svg',
    iso: true,
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'hn',
    flag_1x1: 'flags/1x1/hn.svg',
    flag_4x3: 'flags/4x3/hn.svg',
    iso: true,
    name: 'Honduras',
  },
  {
    code: 'hr',
    flag_1x1: 'flags/1x1/hr.svg',
    flag_4x3: 'flags/4x3/hr.svg',
    iso: true,
    name: 'Croatia',
  },
  {
    code: 'ht',
    flag_1x1: 'flags/1x1/ht.svg',
    flag_4x3: 'flags/4x3/ht.svg',
    iso: true,
    name: 'Haiti',
  },
  {
    code: 'hu',
    flag_1x1: 'flags/1x1/hu.svg',
    flag_4x3: 'flags/4x3/hu.svg',
    iso: true,
    name: 'Hungary',
  },
  {
    code: 'ic',
    flag_1x1: 'flags/1x1/ic.svg',
    flag_4x3: 'flags/4x3/ic.svg',
    iso: false,
    name: 'Canary Islands',
  },
  {
    code: 'id',
    flag_1x1: 'flags/1x1/id.svg',
    flag_4x3: 'flags/4x3/id.svg',
    iso: true,
    name: 'Indonesia',
  },
  {
    code: 'ie',
    flag_1x1: 'flags/1x1/ie.svg',
    flag_4x3: 'flags/4x3/ie.svg',
    iso: true,
    name: 'Ireland',
  },
  {
    code: 'il',
    flag_1x1: 'flags/1x1/il.svg',
    flag_4x3: 'flags/4x3/il.svg',
    iso: true,
    name: 'Israel',
  },
  {
    code: 'im',
    flag_1x1: 'flags/1x1/im.svg',
    flag_4x3: 'flags/4x3/im.svg',
    iso: true,
    name: 'Isle of Man',
  },
  {
    code: 'in',
    flag_1x1: 'flags/1x1/in.svg',
    flag_4x3: 'flags/4x3/in.svg',
    iso: true,
    name: 'India',
  },
  {
    code: 'io',
    flag_1x1: 'flags/1x1/io.svg',
    flag_4x3: 'flags/4x3/io.svg',
    iso: true,
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'iq',
    flag_1x1: 'flags/1x1/iq.svg',
    flag_4x3: 'flags/4x3/iq.svg',
    iso: true,
    name: 'Iraq',
  },
  {
    code: 'ir',
    flag_1x1: 'flags/1x1/ir.svg',
    flag_4x3: 'flags/4x3/ir.svg',
    iso: true,
    name: 'Iran (Islamic Republic of)',
  },
  {
    code: 'is',
    flag_1x1: 'flags/1x1/is.svg',
    flag_4x3: 'flags/4x3/is.svg',
    iso: true,
    name: 'Iceland',
  },
  {
    code: 'je',
    flag_1x1: 'flags/1x1/je.svg',
    flag_4x3: 'flags/4x3/je.svg',
    iso: true,
    name: 'Jersey',
  },
  {
    code: 'jm',
    flag_1x1: 'flags/1x1/jm.svg',
    flag_4x3: 'flags/4x3/jm.svg',
    iso: true,
    name: 'Jamaica',
  },
  {
    code: 'jo',
    flag_1x1: 'flags/1x1/jo.svg',
    flag_4x3: 'flags/4x3/jo.svg',
    iso: true,
    name: 'Jordan',
  },
  {
    code: 'jp',
    flag_1x1: 'flags/1x1/jp.svg',
    flag_4x3: 'flags/4x3/jp.svg',
    iso: true,
    name: 'Japan',
  },
  {
    code: 'ke',
    flag_1x1: 'flags/1x1/ke.svg',
    flag_4x3: 'flags/4x3/ke.svg',
    iso: true,
    name: 'Kenya',
  },
  {
    code: 'kg',
    flag_1x1: 'flags/1x1/kg.svg',
    flag_4x3: 'flags/4x3/kg.svg',
    iso: true,
    name: 'Kyrgyzstan',
  },
  {
    code: 'kh',
    flag_1x1: 'flags/1x1/kh.svg',
    flag_4x3: 'flags/4x3/kh.svg',
    iso: true,
    name: 'Cambodia',
  },
  {
    code: 'ki',
    flag_1x1: 'flags/1x1/ki.svg',
    flag_4x3: 'flags/4x3/ki.svg',
    iso: true,
    name: 'Kiribati',
  },
  {
    code: 'km',
    flag_1x1: 'flags/1x1/km.svg',
    flag_4x3: 'flags/4x3/km.svg',
    iso: true,
    name: 'Comoros',
  },
  {
    code: 'kn',
    flag_1x1: 'flags/1x1/kn.svg',
    flag_4x3: 'flags/4x3/kn.svg',
    iso: true,
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'kp',
    flag_1x1: 'flags/1x1/kp.svg',
    flag_4x3: 'flags/4x3/kp.svg',
    iso: true,
    name: 'North Korea',
  },
  {
    code: 'kr',
    flag_1x1: 'flags/1x1/kr.svg',
    flag_4x3: 'flags/4x3/kr.svg',
    iso: true,
    name: 'South Korea',
  },
  {
    code: 'kw',
    flag_1x1: 'flags/1x1/kw.svg',
    flag_4x3: 'flags/4x3/kw.svg',
    iso: true,
    name: 'Kuwait',
  },
  {
    code: 'ky',
    flag_1x1: 'flags/1x1/ky.svg',
    flag_4x3: 'flags/4x3/ky.svg',
    iso: true,
    name: 'Cayman Islands',
  },
  {
    code: 'kz',
    flag_1x1: 'flags/1x1/kz.svg',
    flag_4x3: 'flags/4x3/kz.svg',
    iso: true,
    name: 'Kazakhstan',
  },
  {
    code: 'la',
    flag_1x1: 'flags/1x1/la.svg',
    flag_4x3: 'flags/4x3/la.svg',
    iso: true,
    name: 'Laos',
  },
  {
    code: 'lb',
    flag_1x1: 'flags/1x1/lb.svg',
    flag_4x3: 'flags/4x3/lb.svg',
    iso: true,
    name: 'Lebanon',
  },
  {
    code: 'lc',
    flag_1x1: 'flags/1x1/lc.svg',
    flag_4x3: 'flags/4x3/lc.svg',
    iso: true,
    name: 'Saint Lucia',
  },
  {
    code: 'li',
    flag_1x1: 'flags/1x1/li.svg',
    flag_4x3: 'flags/4x3/li.svg',
    iso: true,
    name: 'Liechtenstein',
  },
  {
    code: 'lk',
    flag_1x1: 'flags/1x1/lk.svg',
    flag_4x3: 'flags/4x3/lk.svg',
    iso: true,
    name: 'Sri Lanka',
  },
  {
    code: 'lr',
    flag_1x1: 'flags/1x1/lr.svg',
    flag_4x3: 'flags/4x3/lr.svg',
    iso: true,
    name: 'Liberia',
  },
  {
    code: 'ls',
    flag_1x1: 'flags/1x1/ls.svg',
    flag_4x3: 'flags/4x3/ls.svg',
    iso: true,
    name: 'Lesotho',
  },
  {
    code: 'lt',
    flag_1x1: 'flags/1x1/lt.svg',
    flag_4x3: 'flags/4x3/lt.svg',
    iso: true,
    name: 'Lithuania',
  },
  {
    code: 'lu',
    flag_1x1: 'flags/1x1/lu.svg',
    flag_4x3: 'flags/4x3/lu.svg',
    iso: true,
    name: 'Luxembourg',
  },
  {
    code: 'lv',
    flag_1x1: 'flags/1x1/lv.svg',
    flag_4x3: 'flags/4x3/lv.svg',
    iso: true,
    name: 'Latvia',
  },
  {
    code: 'ly',
    flag_1x1: 'flags/1x1/ly.svg',
    flag_4x3: 'flags/4x3/ly.svg',
    iso: true,
    name: 'Libya',
  },
  {
    code: 'ma',
    flag_1x1: 'flags/1x1/ma.svg',
    flag_4x3: 'flags/4x3/ma.svg',
    iso: true,
    name: 'Morocco',
  },
  {
    code: 'mc',
    flag_1x1: 'flags/1x1/mc.svg',
    flag_4x3: 'flags/4x3/mc.svg',
    iso: true,
    name: 'Monaco',
  },
  {
    code: 'md',
    flag_1x1: 'flags/1x1/md.svg',
    flag_4x3: 'flags/4x3/md.svg',
    iso: true,
    name: 'Moldova',
  },
  {
    code: 'me',
    flag_1x1: 'flags/1x1/me.svg',
    flag_4x3: 'flags/4x3/me.svg',
    iso: true,
    name: 'Montenegro',
  },
  {
    code: 'mf',
    flag_1x1: 'flags/1x1/mf.svg',
    flag_4x3: 'flags/4x3/mf.svg',
    iso: true,
    name: 'Saint Martin',
  },
  {
    code: 'mg',
    flag_1x1: 'flags/1x1/mg.svg',
    flag_4x3: 'flags/4x3/mg.svg',
    iso: true,
    name: 'Madagascar',
  },
  {
    code: 'mh',
    flag_1x1: 'flags/1x1/mh.svg',
    flag_4x3: 'flags/4x3/mh.svg',
    iso: true,
    name: 'Marshall Islands',
  },
  {
    code: 'mk',
    flag_1x1: 'flags/1x1/mk.svg',
    flag_4x3: 'flags/4x3/mk.svg',
    iso: true,
    name: 'North Macedonia',
  },
  {
    code: 'ml',
    flag_1x1: 'flags/1x1/ml.svg',
    flag_4x3: 'flags/4x3/ml.svg',
    iso: true,
    name: 'Mali',
  },
  {
    code: 'mm',
    flag_1x1: 'flags/1x1/mm.svg',
    flag_4x3: 'flags/4x3/mm.svg',
    iso: true,
    name: 'Myanmar',
  },
  {
    code: 'mn',
    flag_1x1: 'flags/1x1/mn.svg',
    flag_4x3: 'flags/4x3/mn.svg',
    iso: true,
    name: 'Mongolia',
  },
  {
    code: 'mo',
    flag_1x1: 'flags/1x1/mo.svg',
    flag_4x3: 'flags/4x3/mo.svg',
    iso: true,
    name: 'Macau',
  },
  {
    code: 'mp',
    flag_1x1: 'flags/1x1/mp.svg',
    flag_4x3: 'flags/4x3/mp.svg',
    iso: true,
    name: 'Northern Mariana Islands',
  },
  {
    code: 'mq',
    flag_1x1: 'flags/1x1/mq.svg',
    flag_4x3: 'flags/4x3/mq.svg',
    iso: true,
    name: 'Martinique',
  },
  {
    code: 'mr',
    flag_1x1: 'flags/1x1/mr.svg',
    flag_4x3: 'flags/4x3/mr.svg',
    iso: true,
    name: 'Mauritania',
  },
  {
    code: 'ms',
    flag_1x1: 'flags/1x1/ms.svg',
    flag_4x3: 'flags/4x3/ms.svg',
    iso: true,
    name: 'Montserrat',
  },
  {
    code: 'mt',
    flag_1x1: 'flags/1x1/mt.svg',
    flag_4x3: 'flags/4x3/mt.svg',
    iso: true,
    name: 'Malta',
  },
  {
    code: 'mu',
    flag_1x1: 'flags/1x1/mu.svg',
    flag_4x3: 'flags/4x3/mu.svg',
    iso: true,
    name: 'Mauritius',
  },
  {
    code: 'mv',
    flag_1x1: 'flags/1x1/mv.svg',
    flag_4x3: 'flags/4x3/mv.svg',
    iso: true,
    name: 'Maldives',
  },
  {
    code: 'mw',
    flag_1x1: 'flags/1x1/mw.svg',
    flag_4x3: 'flags/4x3/mw.svg',
    iso: true,
    name: 'Malawi',
  },
  {
    code: 'mx',
    flag_1x1: 'flags/1x1/mx.svg',
    flag_4x3: 'flags/4x3/mx.svg',
    iso: true,
    name: 'Mexico',
  },
  {
    code: 'my',
    flag_1x1: 'flags/1x1/my.svg',
    flag_4x3: 'flags/4x3/my.svg',
    iso: true,
    name: 'Malaysia',
  },
  {
    code: 'mz',
    flag_1x1: 'flags/1x1/mz.svg',
    flag_4x3: 'flags/4x3/mz.svg',
    iso: true,
    name: 'Mozambique',
  },
  {
    code: 'na',
    flag_1x1: 'flags/1x1/na.svg',
    flag_4x3: 'flags/4x3/na.svg',
    iso: true,
    name: 'Namibia',
  },
  {
    code: 'nc',
    flag_1x1: 'flags/1x1/nc.svg',
    flag_4x3: 'flags/4x3/nc.svg',
    iso: true,
    name: 'New Caledonia',
  },
  {
    code: 'ne',
    flag_1x1: 'flags/1x1/ne.svg',
    flag_4x3: 'flags/4x3/ne.svg',
    iso: true,
    name: 'Niger',
  },
  {
    code: 'nf',
    flag_1x1: 'flags/1x1/nf.svg',
    flag_4x3: 'flags/4x3/nf.svg',
    iso: true,
    name: 'Norfolk Island',
  },
  {
    code: 'ng',
    flag_1x1: 'flags/1x1/ng.svg',
    flag_4x3: 'flags/4x3/ng.svg',
    iso: true,
    name: 'Nigeria',
  },
  {
    code: 'ni',
    flag_1x1: 'flags/1x1/ni.svg',
    flag_4x3: 'flags/4x3/ni.svg',
    iso: true,
    name: 'Nicaragua',
  },
  {
    code: 'nl',
    flag_1x1: 'flags/1x1/nl.svg',
    flag_4x3: 'flags/4x3/nl.svg',
    iso: true,
    name: 'Netherlands',
  },
  {
    code: 'no',
    flag_1x1: 'flags/1x1/no.svg',
    flag_4x3: 'flags/4x3/no.svg',
    iso: true,
    name: 'Norway',
  },
  {
    code: 'np',
    flag_1x1: 'flags/1x1/np.svg',
    flag_4x3: 'flags/4x3/np.svg',
    iso: true,
    name: 'Nepal',
  },
  {
    code: 'nr',
    flag_1x1: 'flags/1x1/nr.svg',
    flag_4x3: 'flags/4x3/nr.svg',
    iso: true,
    name: 'Nauru',
  },
  {
    code: 'nu',
    flag_1x1: 'flags/1x1/nu.svg',
    flag_4x3: 'flags/4x3/nu.svg',
    iso: true,
    name: 'Niue',
  },
  {
    code: 'nz',
    flag_1x1: 'flags/1x1/nz.svg',
    flag_4x3: 'flags/4x3/nz.svg',
    iso: true,
    name: 'New Zealand',
  },
  {
    code: 'om',
    flag_1x1: 'flags/1x1/om.svg',
    flag_4x3: 'flags/4x3/om.svg',
    iso: true,
    name: 'Oman',
  },
  {
    code: 'pa',
    flag_1x1: 'flags/1x1/pa.svg',
    flag_4x3: 'flags/4x3/pa.svg',
    iso: true,
    name: 'Panama',
  },
  {
    code: 'pe',
    flag_1x1: 'flags/1x1/pe.svg',
    flag_4x3: 'flags/4x3/pe.svg',
    iso: true,
    name: 'Peru',
  },
  {
    code: 'pf',
    flag_1x1: 'flags/1x1/pf.svg',
    flag_4x3: 'flags/4x3/pf.svg',
    iso: true,
    name: 'French Polynesia',
  },
  {
    code: 'pg',
    flag_1x1: 'flags/1x1/pg.svg',
    flag_4x3: 'flags/4x3/pg.svg',
    iso: true,
    name: 'Papua New Guinea',
  },
  {
    code: 'ph',
    flag_1x1: 'flags/1x1/ph.svg',
    flag_4x3: 'flags/4x3/ph.svg',
    iso: true,
    name: 'Philippines',
  },
  {
    code: 'pk',
    flag_1x1: 'flags/1x1/pk.svg',
    flag_4x3: 'flags/4x3/pk.svg',
    iso: true,
    name: 'Pakistan',
  },
  {
    code: 'pl',
    flag_1x1: 'flags/1x1/pl.svg',
    flag_4x3: 'flags/4x3/pl.svg',
    iso: true,
    name: 'Poland',
  },
  {
    code: 'pm',
    flag_1x1: 'flags/1x1/pm.svg',
    flag_4x3: 'flags/4x3/pm.svg',
    iso: true,
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'pn',
    flag_1x1: 'flags/1x1/pn.svg',
    flag_4x3: 'flags/4x3/pn.svg',
    iso: true,
    name: 'Pitcairn',
  },
  {
    code: 'pr',
    flag_1x1: 'flags/1x1/pr.svg',
    flag_4x3: 'flags/4x3/pr.svg',
    iso: true,
    name: 'Puerto Rico',
  },
  {
    code: 'ps',
    flag_1x1: 'flags/1x1/ps.svg',
    flag_4x3: 'flags/4x3/ps.svg',
    iso: true,
    name: 'State of Palestine',
  },
  {
    code: 'pt',
    flag_1x1: 'flags/1x1/pt.svg',
    flag_4x3: 'flags/4x3/pt.svg',
    iso: true,
    name: 'Portugal',
  },
  {
    code: 'pw',
    flag_1x1: 'flags/1x1/pw.svg',
    flag_4x3: 'flags/4x3/pw.svg',
    iso: true,
    name: 'Palau',
  },
  {
    code: 'py',
    flag_1x1: 'flags/1x1/py.svg',
    flag_4x3: 'flags/4x3/py.svg',
    iso: true,
    name: 'Paraguay',
  },
  {
    code: 'qa',
    flag_1x1: 'flags/1x1/qa.svg',
    flag_4x3: 'flags/4x3/qa.svg',
    iso: true,
    name: 'Qatar',
  },
  {
    code: 're',
    flag_1x1: 'flags/1x1/re.svg',
    flag_4x3: 'flags/4x3/re.svg',
    iso: true,
    name: 'R\u00e9union',
  },
  {
    code: 'ro',
    flag_1x1: 'flags/1x1/ro.svg',
    flag_4x3: 'flags/4x3/ro.svg',
    iso: true,
    name: 'Romania',
  },
  {
    code: 'rs',
    flag_1x1: 'flags/1x1/rs.svg',
    flag_4x3: 'flags/4x3/rs.svg',
    iso: true,
    name: 'Serbia',
  },
  {
    code: 'ru',
    flag_1x1: 'flags/1x1/ru.svg',
    flag_4x3: 'flags/4x3/ru.svg',
    iso: true,
    name: 'Russia',
  },
  {
    code: 'rw',
    flag_1x1: 'flags/1x1/rw.svg',
    flag_4x3: 'flags/4x3/rw.svg',
    iso: true,
    name: 'Rwanda',
  },
  {
    code: 'sa',
    flag_1x1: 'flags/1x1/sa.svg',
    flag_4x3: 'flags/4x3/sa.svg',
    iso: true,
    name: 'Saudi Arabia',
  },
  {
    code: 'sb',
    flag_1x1: 'flags/1x1/sb.svg',
    flag_4x3: 'flags/4x3/sb.svg',
    iso: true,
    name: 'Solomon Islands',
  },
  {
    code: 'sc',
    flag_1x1: 'flags/1x1/sc.svg',
    flag_4x3: 'flags/4x3/sc.svg',
    iso: true,
    name: 'Seychelles',
  },
  {
    code: 'sd',
    flag_1x1: 'flags/1x1/sd.svg',
    flag_4x3: 'flags/4x3/sd.svg',
    iso: true,
    name: 'Sudan',
  },
  {
    code: 'se',
    flag_1x1: 'flags/1x1/se.svg',
    flag_4x3: 'flags/4x3/se.svg',
    iso: true,
    name: 'Sweden',
  },
  {
    code: 'sg',
    flag_1x1: 'flags/1x1/sg.svg',
    flag_4x3: 'flags/4x3/sg.svg',
    iso: true,
    name: 'Singapore',
  },
  {
    code: 'sh',
    flag_1x1: 'flags/1x1/sh.svg',
    flag_4x3: 'flags/4x3/sh.svg',
    iso: true,
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    code: 'si',
    flag_1x1: 'flags/1x1/si.svg',
    flag_4x3: 'flags/4x3/si.svg',
    iso: true,
    name: 'Slovenia',
  },
  {
    code: 'sj',
    flag_1x1: 'flags/1x1/sj.svg',
    flag_4x3: 'flags/4x3/sj.svg',
    iso: true,
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'sk',
    flag_1x1: 'flags/1x1/sk.svg',
    flag_4x3: 'flags/4x3/sk.svg',
    iso: true,
    name: 'Slovakia',
  },
  {
    code: 'sl',
    flag_1x1: 'flags/1x1/sl.svg',
    flag_4x3: 'flags/4x3/sl.svg',
    iso: true,
    name: 'Sierra Leone',
  },
  {
    code: 'sm',
    flag_1x1: 'flags/1x1/sm.svg',
    flag_4x3: 'flags/4x3/sm.svg',
    iso: true,
    name: 'San Marino',
  },
  {
    code: 'sn',
    flag_1x1: 'flags/1x1/sn.svg',
    flag_4x3: 'flags/4x3/sn.svg',
    iso: true,
    name: 'Senegal',
  },
  {
    code: 'so',
    flag_1x1: 'flags/1x1/so.svg',
    flag_4x3: 'flags/4x3/so.svg',
    iso: true,
    name: 'Somalia',
  },
  {
    code: 'sr',
    flag_1x1: 'flags/1x1/sr.svg',
    flag_4x3: 'flags/4x3/sr.svg',
    iso: true,
    name: 'Suriname',
  },
  {
    code: 'ss',
    flag_1x1: 'flags/1x1/ss.svg',
    flag_4x3: 'flags/4x3/ss.svg',
    iso: true,
    name: 'South Sudan',
  },
  {
    code: 'st',
    flag_1x1: 'flags/1x1/st.svg',
    flag_4x3: 'flags/4x3/st.svg',
    iso: true,
    name: 'Sao Tome and Principe',
  },
  {
    code: 'sv',
    flag_1x1: 'flags/1x1/sv.svg',
    flag_4x3: 'flags/4x3/sv.svg',
    iso: true,
    name: 'El Salvador',
  },
  {
    code: 'sx',
    flag_1x1: 'flags/1x1/sx.svg',
    flag_4x3: 'flags/4x3/sx.svg',
    iso: true,
    name: 'Sint Maarten',
  },
  {
    code: 'sy',
    flag_1x1: 'flags/1x1/sy.svg',
    flag_4x3: 'flags/4x3/sy.svg',
    iso: true,
    name: 'Syrian Arab Republic',
  },
  {
    code: 'sz',
    flag_1x1: 'flags/1x1/sz.svg',
    flag_4x3: 'flags/4x3/sz.svg',
    iso: true,
    name: 'Swaziland',
  },
  {
    code: 'ta',
    flag_1x1: 'flags/1x1/ta.svg',
    flag_4x3: 'flags/4x3/ta.svg',
    iso: false,
    name: 'Tristan da Cunha',
  },
  {
    code: 'tc',
    flag_1x1: 'flags/1x1/tc.svg',
    flag_4x3: 'flags/4x3/tc.svg',
    iso: true,
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'td',
    flag_1x1: 'flags/1x1/td.svg',
    flag_4x3: 'flags/4x3/td.svg',
    iso: true,
    name: 'Chad',
  },
  {
    code: 'tf',
    flag_1x1: 'flags/1x1/tf.svg',
    flag_4x3: 'flags/4x3/tf.svg',
    iso: true,
    name: 'French Southern Territories',
  },
  {
    code: 'tg',
    flag_1x1: 'flags/1x1/tg.svg',
    flag_4x3: 'flags/4x3/tg.svg',
    iso: true,
    name: 'Togo',
  },
  {
    code: 'th',
    flag_1x1: 'flags/1x1/th.svg',
    flag_4x3: 'flags/4x3/th.svg',
    iso: true,
    name: 'Thailand',
  },
  {
    code: 'tj',
    flag_1x1: 'flags/1x1/tj.svg',
    flag_4x3: 'flags/4x3/tj.svg',
    iso: true,
    name: 'Tajikistan',
  },
  {
    code: 'tk',
    flag_1x1: 'flags/1x1/tk.svg',
    flag_4x3: 'flags/4x3/tk.svg',
    iso: true,
    name: 'Tokelau',
  },
  {
    code: 'tl',
    flag_1x1: 'flags/1x1/tl.svg',
    flag_4x3: 'flags/4x3/tl.svg',
    iso: true,
    name: 'Timor-Leste',
  },
  {
    code: 'tm',
    flag_1x1: 'flags/1x1/tm.svg',
    flag_4x3: 'flags/4x3/tm.svg',
    iso: true,
    name: 'Turkmenistan',
  },
  {
    code: 'tn',
    flag_1x1: 'flags/1x1/tn.svg',
    flag_4x3: 'flags/4x3/tn.svg',
    iso: true,
    name: 'Tunisia',
  },
  {
    code: 'to',
    flag_1x1: 'flags/1x1/to.svg',
    flag_4x3: 'flags/4x3/to.svg',
    iso: true,
    name: 'Tonga',
  },
  {
    code: 'tr',
    flag_1x1: 'flags/1x1/tr.svg',
    flag_4x3: 'flags/4x3/tr.svg',
    iso: true,
    name: 'Turkey',
  },
  {
    code: 'tt',
    flag_1x1: 'flags/1x1/tt.svg',
    flag_4x3: 'flags/4x3/tt.svg',
    iso: true,
    name: 'Trinidad and Tobago',
  },
  {
    code: 'tv',
    flag_1x1: 'flags/1x1/tv.svg',
    flag_4x3: 'flags/4x3/tv.svg',
    iso: true,
    name: 'Tuvalu',
  },
  {
    code: 'tw',
    flag_1x1: 'flags/1x1/tw.svg',
    flag_4x3: 'flags/4x3/tw.svg',
    iso: true,
    name: 'Taiwan',
  },
  {
    code: 'tz',
    flag_1x1: 'flags/1x1/tz.svg',
    flag_4x3: 'flags/4x3/tz.svg',
    iso: true,
    name: 'Tanzania',
  },
  {
    code: 'ua',
    flag_1x1: 'flags/1x1/ua.svg',
    flag_4x3: 'flags/4x3/ua.svg',
    iso: true,
    name: 'Ukraine',
  },
  {
    code: 'ug',
    flag_1x1: 'flags/1x1/ug.svg',
    flag_4x3: 'flags/4x3/ug.svg',
    iso: true,
    name: 'Uganda',
  },
  {
    code: 'um',
    flag_1x1: 'flags/1x1/um.svg',
    flag_4x3: 'flags/4x3/um.svg',
    iso: true,
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'un',
    flag_1x1: 'flags/1x1/un.svg',
    flag_4x3: 'flags/4x3/un.svg',
    iso: false,
    name: 'United Nations',
  },
  {
    code: 'uy',
    flag_1x1: 'flags/1x1/uy.svg',
    flag_4x3: 'flags/4x3/uy.svg',
    iso: true,
    name: 'Uruguay',
  },
  {
    code: 'uz',
    flag_1x1: 'flags/1x1/uz.svg',
    flag_4x3: 'flags/4x3/uz.svg',
    iso: true,
    name: 'Uzbekistan',
  },
  {
    code: 'va',
    flag_1x1: 'flags/1x1/va.svg',
    flag_4x3: 'flags/4x3/va.svg',
    iso: true,
    name: 'Holy See',
  },
  {
    code: 'vc',
    flag_1x1: 'flags/1x1/vc.svg',
    flag_4x3: 'flags/4x3/vc.svg',
    iso: true,
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 've',
    flag_1x1: 'flags/1x1/ve.svg',
    flag_4x3: 'flags/4x3/ve.svg',
    iso: true,
    name: 'Venezuela (Bolivarian Republic of)',
  },
  {
    code: 'vg',
    flag_1x1: 'flags/1x1/vg.svg',
    flag_4x3: 'flags/4x3/vg.svg',
    iso: true,
    name: 'Virgin Islands (British)',
  },
  {
    code: 'vi',
    flag_1x1: 'flags/1x1/vi.svg',
    flag_4x3: 'flags/4x3/vi.svg',
    iso: true,
    name: 'Virgin Islands (U.S.)',
  },
  {
    code: 'vn',
    flag_1x1: 'flags/1x1/vn.svg',
    flag_4x3: 'flags/4x3/vn.svg',
    iso: true,
    name: 'Vietnam',
  },
  {
    code: 'vu',
    flag_1x1: 'flags/1x1/vu.svg',
    flag_4x3: 'flags/4x3/vu.svg',
    iso: true,
    name: 'Vanuatu',
  },
  {
    code: 'wf',
    flag_1x1: 'flags/1x1/wf.svg',
    flag_4x3: 'flags/4x3/wf.svg',
    iso: true,
    name: 'Wallis and Futuna',
  },
  {
    code: 'ws',
    flag_1x1: 'flags/1x1/ws.svg',
    flag_4x3: 'flags/4x3/ws.svg',
    iso: true,
    name: 'Samoa',
  },
  {
    code: 'xk',
    flag_1x1: 'flags/1x1/xk.svg',
    flag_4x3: 'flags/4x3/xk.svg',
    iso: false,
    name: 'Kosovo',
  },
  {
    code: 'xx',
    flag_1x1: 'flags/1x1/xx.svg',
    flag_4x3: 'flags/4x3/xx.svg',
    iso: false,
    name: 'Unknown',
  },
  {
    code: 'ye',
    flag_1x1: 'flags/1x1/ye.svg',
    flag_4x3: 'flags/4x3/ye.svg',
    iso: true,
    name: 'Yemen',
  },
  {
    code: 'yt',
    flag_1x1: 'flags/1x1/yt.svg',
    flag_4x3: 'flags/4x3/yt.svg',
    iso: true,
    name: 'Mayotte',
  },
  {
    code: 'za',
    flag_1x1: 'flags/1x1/za.svg',
    flag_4x3: 'flags/4x3/za.svg',
    iso: true,
    name: 'South Africa',
  },
  {
    code: 'zm',
    flag_1x1: 'flags/1x1/zm.svg',
    flag_4x3: 'flags/4x3/zm.svg',
    iso: true,
    name: 'Zambia',
  },
  {
    code: 'zw',
    flag_1x1: 'flags/1x1/zw.svg',
    flag_4x3: 'flags/4x3/zw.svg',
    iso: true,
    name: 'Zimbabwe',
  },
]