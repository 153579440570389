<template>
  <Heading :text="t('import-export-data')" />
  <Banner v-if="done">{{ t('imported') }}</Banner>
  <Banner v-if="error">{{ t('import-error') }}</Banner>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <p v-html="t('import-description').split('\n').join('<br />')"></p>
  <div class="flex justify-end">
    <a
      class="btn gradient small"
      :href="csvTrack"
      download="Rankister_Track.csv"
    >
      {{ t('import-download-csv-track') }}
    </a>
    <a
      class="btn gradient small"
      :href="txtCountries"
      download="Rankister_Countries.txt"
    >
      {{ t('import-download-csv-countries') }}
    </a>
    <a
      class="btn gradient small"
      :href="txtCategories"
      download="Rankister_Categories.txt"
    >
      {{ t('import-download-csv-categories') }}
    </a>
  </div>
  <form v-if="!done" class="my-4" @submit.prevent="importCsv">
    <div>
      <FormRow
        v-if="isAdmin"
        v-model="user"
        name="owner"
        :label="t('owner')"
        type="select"
        required
      >
        <option value="">{{ t('choose') }}</option>
        <option v-for="user of users" :key="user.uid" :value="user.uid">
          {{ user.displayName }} - {{ user.email }}
        </option>
      </FormRow>
      <FormRow label="" type="file" @change="csv = $event as unknown as File" />
      <FormRow v-model="delimiter" :label="t('delimiter')" type="select">
        <option value="tab">{{ t('tab') }}</option>
        <option value=",">{{ t('comma') }}</option>
        <option value=";">{{ t('semicolon') }}</option>
      </FormRow>
    </div>
    <div class="flex justify-end mt-4">
      <button
        class="btn gradient disabled:opacity-50"
        :disabled="!csv || loading"
      >
        {{ t('import-csv') }}
      </button>
    </div>
  </form>
  <div v-else>
    <button class="btn gradient !m-0 small" @click="() => (done = false)">
      {{ t('csv-reupload') }}
    </button>
    <div v-for="(line, k) in logs" :key="k" class="border-b py-1">
      <div class="flex gap-3 items-center">
        <span>{{ t(line[0]) }}</span>
        <span v-if="line[1]">({{ line[1] }})</span>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Heading from '../../components/Heading.vue'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useFirebase } from '../../plugins/firebase'
import User from '../../models/user'
import FormRow from '../../components/form/FormRow.vue'
import Banner from '../../components/Banner.vue'
import Loader from '../../components/Loader.vue'
import store from '../../store'
import countries from '../../plugins/countries'
import categories from '../../plugins/categories'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const loading = ref(false)
const csv = ref<File>()
const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const users = ref<any>()
const user = ref<string>()

if (isAdmin)
  User.allLight().then((res) => {
    users.value = res as User[]
  })

const csvTrack = window.URL.createObjectURL(
  new Blob(
    [
      'country,name,description,extra (string:price),domain,price,category,type (blog|newspaper),accepts_gambling (y|n),accepts_cbd (y|n),accepts_adult (y|n),accepts_trading (y|n),facebook (leave empty if not expected),twitter (leave empty if not expected),reddit (leave empty if not expected),youtube (leave empty if not expected),telegram (leave empty if not expected),instagram (leave empty if not expected),tiktok (leave empty if not expected),publication-guidelines,publication-duration (1-year|2-years|unlimited),publication-time (24h|48h|96h),google-news (y|n)',
    ],
    { type: 'text/csv' },
  ),
)
const txtCountries = window.URL.createObjectURL(
  new Blob([countries.map((i) => i.code + ' -> ' + i.name).join('\n')], {
    type: 'text/csv',
  }),
)

const txtCategories = window.URL.createObjectURL(
  new Blob([categories.map((i) => i + ' -> ' + t(i)).join('\n')], {
    type: 'text/csv',
  }),
)
const firebase = useFirebase()
const delimiter = ref(',')
const done = ref(false)
const logs = ref<any>()
const error = ref(false)
const importCsv = async () => {
  loading.value = true
  store.commit('popup/open', { popup: Loader })
  await firebase
    .function('importFromCSV', {
      csv: await csv.value?.text(),
      user: isAdmin ? user.value : (await User.getCurrent()).uid,
      delimiter: delimiter.value === 'tab' ? '\t' : delimiter.value,
    })
    .then((res) => {
      logs.value = res
      done.value = true
    })
    .catch(() => (error.value = true))
  store.commit('popup/close')
  loading.value = false
}
</script>
