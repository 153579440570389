Number.prototype.format = function (
  this: number,
  decimals = 2,
  decSep = ',',
  thousandsSep = '.',
) {
  const s = this.toFixed(decimals).split('.')
  return (
    s[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep) +
    (decimals > 0 ? decSep + s[1] : '')
  )
}

Date.prototype.format = function (this: Date, hours = true) {
  return (
    `${this.getDate()}/${String(this.getMonth() + 1).padStart(2, '0')}/${this.getFullYear()}` +
    (hours
      ? ` ${String(this.getHours()).padStart(2, '0')}:${String(this.getMinutes()).padStart(2, '0')}`
      : '')
  )
}

String.prototype.parseDeniedWords = function (this: string) {
  return (
    this.replace(/\+(?:[0-9]●?){6,14}[0-9]/gm, '[Removed Phone Number]')
    .replace(/(?:[0-9]●?){6,14}[0-9]/gm, '[Removed Phone Number]')
    .replace(/\S+@\S+\.\S+/gm, '[Removed Email Address]')
  )
}