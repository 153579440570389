<template>
  <div class="flex items-center justify-center gap-2">
    <template
      v-if="
        order.product_collection === 'products' &&
        ['work-in-progress', 'need-review'].includes(order.status)
      "
    >
      <div
        v-if="
          !order.advertiserInfo
        "
        class="h-7 w-7 flex items-center justify-center rounded-full bg-red-500 text-white"
      >
        <ExclamationIcon class="w-5 h-5" />
      </div>
      <button
        v-if="
          order.status !== 'canceled' &&
          order.status !== 'pending' &&
          order.status !== 'refunded'
        "
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="openPopup"
      >
        <EyeIcon
          v-if="order.product_collection === 'products'"
          class="w-4 h-4"
        />
      </button>
      <button
        v-if="order.status !== 'pending'"
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="chat"
      >
        <ChatAlt2Icon class="w-4 h-4" />
      </button>
    </template>
    <template
      v-else-if="
        order.product_collection === 'articles' &&
        ['paid', 'work-in-progress', 'need-review'].includes(order.status)
      "
    >
      <button
        v-if="
          order.status !== 'canceled' &&
          order.status !== 'pending' &&
          order.status !== 'refunded'
        "
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="openPopup"
      >
        <DocumentTextIcon
          v-if="order.product_collection === 'articles'"
          class="w-4 h-4"
        />
      </button>
      <button
        v-if="order.status !== 'pending'"
        class="gradient h-7 w-7 p-1.5 rounded-full text-center lg:w-auto bg-blend-darken text-white"
        @click="chat"
      >
        <ChatAlt2Icon class="w-4 h-4" />
      </button>
    </template>
    <template
      v-if="
        order.product_collection === 'products' && order.status === 'completed'
      "
    >
      <a :href="order.articleLink" target="_BLANK"
        ><ExternalLinkIcon class="w-6 h-6 text-emerald-500"
      /></a>
      <AnimatePing
        :title="order.blchecker?.status ?? 'pending'"
        :class="{
          'bg-red-500': order.blchecker?.status === 'inactive',
          'bg-yellow-500':
            order.blchecker?.status === 'pending' || !order.blchecker?.status,
          'bg-emerald-500': order.blchecker?.status === 'active',
        }"
      />
      {{ t('blchecker-' + (order.blchecker?.status ?? 'pending')) }}</template
    >
  </div>
</template>
<script lang="ts">
export default defineComponent({ name: 'OrderItem' })
</script>
<script lang="ts" setup>
import { defineComponent, ref } from 'vue'
import Order from '../models/order'
import Product from '../models/product'
import { useFirebase } from '../plugins/firebase'
import {
  EyeIcon,
  ExclamationIcon,
  DocumentTextIcon,
  ChatAlt2Icon,
} from '@heroicons/vue/solid'
import { ExternalLinkIcon } from '@heroicons/vue/outline'
import Article from '../models/article'
import store from '../store'
import OrderPopup from './popups/OrderPopupLight.vue'
import OrderPopupArticleLight from './popups/OrderPopupArticleLight.vue'
import Loader from './Loader.vue'
import Chat from '../models/chat'
import ChatPopup from './popups/ChatPopup.vue'
import Press from '../models/press'
import RefuseOrder from './popups/RefuseOrder.vue'
import AnimatePing from './AnimatePing.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const props = defineProps<{
  order: Order
  users?: any
  linkedOrders?: Order[]
  linkedOrder?: string
}>()

const emit = defineEmits(['update'])

const product = ref<Product | Article | Press>()
const product_collection = ref<string>(
  props.order.product_collection ??
    (props.order.extras ? 'products' : 'articles'),
)
if (props.order.extras && product_collection.value === 'products') {
  if (props.order.product_object)
    product.value = props.order.product_object as Product
  else
    Product.find(props.order.product).then(
      (prod) => (product.value = prod as Product),
    )
} else if (product_collection.value === 'articles') {
  if (props.order.product_object)
    product.value = props.order.product_object as Article
  else
    Article.find(props.order.product).then(
      (art) => (product.value = art as Article),
    )
} else if (product_collection.value === 'presses') {
  if (props.order.product_object)
    product.value = props.order.product_object as Press
  else
    Press.find(props.order.product).then(
      (art) => (product.value = art as Press),
    )
}

const firebase = useFirebase()

const openPopup = () =>
  store.commit('popup/open', {
    popup:
      props.order.product_collection === 'articles'
        ? OrderPopupArticleLight
        : OrderPopup,
    order: props.order,
    orderDone: popupOrderDone,
    orderRefused: popupOrderRefused,
    orderChange: popupOrderChange,
  })

const popupOrderDone = async () => {
  store.commit('popup/close')
  store.commit('popup/open', {
    popup: Loader,
  })
  await firebase.function('orderDone', { order: props.order.uid })
  emit('update')
  setTimeout(() => {
    store.commit('popup/close')
  }, 2000)
}
const popupOrderRefused = async () =>
  store.commit('popup/open', {
    popup: RefuseOrder,
    order: props.order.uid,
    update: () => emit('update'),
  })

const popupOrderChange = async () => {
  store.commit('popup/close')
  store.commit('popup/open', {
    popup: Loader,
  })
  await firebase.function('orderChangeNotification', { order: props.order.uid })
  setTimeout(() => {
    store.commit('popup/close')
  }, 2000)
}

const chat = async () => {
  let model = await Chat.find(props.order.uid)
  if (!model) {
    model = new Chat({
      uid: props.order.uid,
      advertiser: props.order.owner,
      publisher: props.order.publisher,
      createdAt: new Date(),
    })
    model.save()
  }
  store.commit('popup/open', { popup: ChatPopup, chat: model })
}
</script>
