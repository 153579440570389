import { Module } from 'vuex'
import Article from '../models/article'
import Press from '../models/press'
import Product from '../models/product'
import { trackingAddToCart, trackingRemoveFromCart } from '../plugins/tracking'
export interface CartItem {
  product: Product | Article | Press
  extras?: boolean[]
  words?: number
  info: string
  language?: string
  publicationguidelines?: string
  linkedOrder?: CartItem | null
  campaign_index?: number
  anchor?: string
  link?: string
  pictureLink?: string
  publishDate?: Date
}

export interface CartState {
  items: CartItem[]
  campaign: CartItem[]
}

export default {
  namespaced: true,
  strict: true,
  state: JSON.parse(localStorage.getItem('cart') ?? 'null') || {
    items: [],
    campaign: [],
  },
  mutations: {
    add(
      state,
      payload:
        | Product
        | Article
        | Press
        | {
            product: Product | Article | Press
            defaults: Partial<CartItem>
            campaign: string
          },
    ) {
      const product =
        payload instanceof Product ||
        payload instanceof Article ||
        payload instanceof Press
          ? payload
          : payload.product
      const defaults =
        payload instanceof Product ||
        payload instanceof Article ||
        payload instanceof Press
          ? {}
          : payload.defaults
      if (!state['campaign' in payload ? 'campaign' : 'items'])
        state['campaign' in payload ? 'campaign' : 'items'] = []
      state['campaign' in payload ? 'campaign' : 'items'].push({
        product,
        extras:
          'extras' in product && product.extras
            ? product.extras.map(() => false)
            : undefined,
        words: undefined,
        language: undefined,
        info: '',
        publicationguidelines: '',
        linkedOrder: null,
        ...defaults,
      })
      localStorage.setItem('cart', JSON.stringify(state))
      trackingAddToCart(product)
    },
    remove(state, index: number) {
      trackingRemoveFromCart(state.items.find((_, i) => i === index)?.product)
      state.items = state.items.filter((_, i) => i !== index)
      localStorage.setItem('cart', JSON.stringify(state))
    },
    empty(state) {
      state.items = []
      state.campaign = []
      localStorage.setItem('cart', JSON.stringify(state))
    },
    tempSave(state) {
      localStorage.setItem('cart', JSON.stringify(state))
    },
  },
} as Module<CartState, any>
