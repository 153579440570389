import { createStore } from 'vuex'
import AccountType, { AccountTypeState } from './AccountType'
import cart, { CartState } from './cart'
import popup, { PopupState } from './popup'
import snackbar, { SnackbarState } from './snackbar'

const store = createStore({
  modules: {
    AccountType,
    cart,
    popup,
    snackbar,
  },
})

export type State = {
  AccountType: AccountTypeState
  cart: CartState
  popup: PopupState
  snackbar: SnackbarState
}

export default store
