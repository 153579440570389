<template>
  <div
    class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 border-b last:border-none pb-4"
  >
    <label
      v-if="label"
      :for="name"
      class="flex items-center text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
    >
      {{ label }} <span v-if="required" class="text-red-600">*</span>
      <div v-if="info" class="ml-2">
        <InformationCircleIcon
          v-if="info[info.length - 1] !== '-'"
          class="w-4 h-4 fill-blue-500"
          @click="openInfoPopup()"
        />
        <InformationCircleIcon
          v-else
          class="w-4 h-4 fill-blue-500"
          @click="openInfoPopup(info + (name ?? ''))"
        />
      </div>
    </label>
    <div class="mt-1 sm:mt-0 sm:col-span-2 space-y-4">
      <template v-if="!type && !types">
        <slot />
      </template>
      <template v-else-if="type === 'file'">
        <FileInput
          v-bind="$attrs"
          :name="name ?? ''"
          :required="required"
          :accepts="accepts"
        />
      </template>
      <template
        v-else-if="
          typeof modelValue === 'object' &&
          !('arrayBuffer' in modelValue) &&
          type !== 'select' &&
          type !== 'vselect'
        "
      >
        <div
          v-for="(model, i) of modelValue"
          :key="i"
          class="relative flex gap-2"
          :class="label ? 'max-w-lg' : 'w-full'"
        >
          <template v-if="typeof model === 'object'">
            <FormInput
              v-for="(key, j) of Object.keys(model)"
              :key="key"
              v-bind="$attrs"
              :placeholder="t(`${key}`)"
              :name="name ?? ''"
              :type="types ? types[j] : 'text'"
              :required="required"
              :before="before"
              :after="after"
              :model-value="(model as Record<string, string | number>)[key]"
              class="flex-grow"
              @update:model-value="$emit('update:modelValue', (modelValue as Record<string, string | number>[]).map(
                (el, idx) => idx === i ? {...el, [key]: $event} : el)
              )"
            >
              <slot />
            </FormInput>
          </template>
          <template v-else>
            <FormInput
              v-bind="$attrs"
              :name="name ?? ''"
              :type="type ?? 'text'"
              :required="required"
              :before="before"
              :after="after"
              :model-value="(model as string)"
              class="flex-grow"
              @update:model-value="$emit('update:modelValue', (modelValue as string[]).map((el, idx) => idx === i ? $event : el))"
            >
              <slot />
            </FormInput>
          </template>
          <button
            type="button"
            class="absolute right-0 top-2 -mr-8 text-red-500"
            @click="$emit('update:modelValue', 
              typeof (modelValue as unknown[])[i] === 'object' && (modelValue as unknown[]).length === 1
                ? [Object.keys((modelValue as Record<string, string | number>[])[0]).reduce((acc, key) => ({...acc, [key]: ''}), {})]
                : (modelValue as string[]).filter((_, idx) => idx !== i)
            )"
          >
            <XCircleIcon class="block h-6 w-6" />
          </button>
        </div>
        <button
          v-if="label !== 'Website' || modelValue.length < 1"
          type="button"
          class="inline-flex items-center gap-1 hover:text-primary-500"
          @click="$emit('update:modelValue', [
            ...(modelValue as unknown[]),
            (!(modelValue as unknown[])[0] || typeof (modelValue as unknown[])[0] === 'string')
              ? ''
              : Object.keys((modelValue as Record<string, string | number>[])[0]).reduce((acc, key) => ({...acc, [key]: ''}), {})
          ])"
        >
          <PlusIcon class="h-5 w-5" /> {{ t('add') }} {{ t(label as string) }}
        </button>
      </template>
      <template v-else-if="type === 'tel'">
        <FormInput
          v-bind="$attrs"
          :name="name ?? ''"
          type="tel"
          :required="required"
          :before="before"
          :after="after"
          :model-value="(modelValue as string)"
          @update:model-value="$emit('update:modelValue', $event)"
        >
          <slot />
          <div v-html="sublabel"></div>
        </FormInput>
      </template>
      <template v-else-if="type === 'vselect'">
        <FormInput
          v-bind="$attrs"
          :name="name ?? ''"
          type="vselect"
          :required="required"
          :before="before"
          :after="after"
          :model-value="(modelValue as string)"
          @update:model-value="$emit('update:modelValue', $event)"
        >
          <slot />
        </FormInput>
        <div v-html="sublabel"></div>
      </template>
      <template v-else-if="type === 'editor'">
        <ckeditor
          :editor="ClassicEditor"
          :config="{
            toolbar: ['bold', 'italic', 'numberedList', 'bulletedList'],
          }"
          v-bind="$attrs"
          :name="name ?? ''"
          :required="required"
          :model-value="(modelValue as string)"
          @update:model-value="$emit('update:modelValue', $event)"
        ></ckeditor>
        <slot />
        <div v-html="sublabel"></div>
      </template>
      <template v-else>
        <FormInput
          v-bind="$attrs"
          :name="name ?? ''"
          :type="type ?? 'text'"
          :required="required"
          :before="before"
          :after="after"
          :model-value="(modelValue as string)"
          @update:model-value="$emit('update:modelValue', $event)"
        >
          <slot />
          <span v-if="pattern && !validated" class="text-red-500">{{
            t('domain-validation-' + validated)
          }}</span>
        </FormInput>
        <div class="text-sm" v-html="sublabel"></div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import FormInput from './FormInput.vue'
import {
  PlusIcon,
  XCircleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/solid'
import { useI18n } from 'vue-i18n'
import { State } from '../../store'
import { useStore } from 'vuex'
import Info from '../popups/Info.vue'
import FileInput from './FileInput.vue'
import { ref } from 'vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { component as ckeditor } from '@ckeditor/ckeditor5-vue'

const store = useStore<State>()
const { t } = useI18n()
const validated = ref<boolean>(false)

const props = defineProps<{
  label?: string
  name?: string
  type?: string
  types?: string[]
  required?: boolean
  before?: string
  after?: string
  info?: string
  pattern?: string
  sublabel?: string
  accepts?: string
  modelValue?:
    | string
    | number
    | File
    | (string | Record<string, string | number>)[]
    | boolean | any
}>()
defineEmits(['update:modelValue'])

const openInfoPopup = (overwrite?: string) => {
  store.commit('popup/open', { popup: Info, info: overwrite ?? props.info })
}
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
