<template>
  <RouterView v-if="firebase && authChecked" />
  <Loader v-else />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { RouterView, useRouter } from 'vue-router'
import { useFirebase } from './plugins/firebase'
import Loader from './components/Loader.vue'
import User from './models/user'

const router = useRouter()
const firebase = useFirebase()
const auth = firebase.getAuth()

const authChecked = ref(false)
const authenticated = ref(true)
auth.onAuthStateChanged(async (user) => {
  const path = router.currentRoute.value.query.redirect
    ? (router.currentRoute.value.query.redirect as string)
    : '/app/admin'

  authenticated.value = !!user
  if (user && user?.emailVerified) {
    await User.getCurrent()
    let query = undefined
    if (router.currentRoute.value.query.redirect) {
      let { redirect, ...params } = router.currentRoute.value.query
      query = params as Record<string, string>
    }
    void router.push({ path, query })
  } else {
    void router.push({ path: '/login' })
  }
  authChecked.value = true
})
</script>
