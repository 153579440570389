<template>
  <Heading text="Chats" />
  <div class="flex items-center justify-between gap-2">
    <input
      v-model="filterByName"
      type="text"
      placeholder="Search by Order ID..."
      class="my-4 flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
    />
    <button class="btn gradient" @click="fetch">{{ t('search') }}</button>
    <button class="btn secondary" @click="reset">
      {{ t('reset') }}
    </button>
  </div>
  <button
    v-for="chat of chats.sort((a, b) => {
      return a.chat.messages[a.chat.messages.length - 1]?.date.getTime() >
        b.chat.messages[b.chat.messages.length - 1]?.date.getTime()
        ? -1
        : 1
    })"
    :key="chat.chat.uid"
    class="group w-full text-left py-10 border-b last:border-none border-gray-200 even:bg-gray-50 hover:static-gradient-transparent px-4 lg:px-10 flex flex-col lg:flex-row gap-6 md:gap-0 lg:items-center space-x-6"
    @click="open(chat.chat as Chat)"
  >
    <div class="flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            Order #{{ chat.order?.uid }} -
            {{
              chat.order?.product_collection === 'presses'
                ? t('press-release')
                : chat.order?.product_collection === 'articles'
                ? t('copywriting-service')
                : chat.product?.name
            }}
          </h4>
          <p class="mt-2 text-sm text-gray-600 max-w-2xl">
            <template v-if="!chat.chat.messages.length">
              {{ t('no-messages') }}
            </template>
            <template v-else>
              {{
                chat.chat.messages[chat.chat.messages.length - 1].from ===
                chat.advertiser?.uid
                  ? chat.advertiser?.displayName
                  : chat.publisher?.displayName
              }}:
              {{ chat.chat.messages[chat.chat.messages.length - 1].content }}
            </template>
          </p>
        </div>
      </div>
    </div>
  </button>
  <Pagination
    :pagination="pagination"
    :handle-next="next"
    :handle-prev="prev"
  />
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import Chat from '../../models/chat'
import Heading from '../../components/Heading.vue'
import Order from '../../models/order'
import Product from '../../models/product'
import { useStore } from 'vuex'
import ChatPopup from '../../components/popups/ChatPopup.vue'
import { useI18n } from 'vue-i18n'
import User from '../../models/user'
import LoaderVue from '../../components/Loader.vue'
import { State } from '../../store'
import {
  documentId,
  limit,
  orderBy,
  startAfter,
  where,
} from '@firebase/firestore'
import Pagination from '../../components/Pagination.vue'

const store = useStore<State>()
const { t } = useI18n()
const filterByName = ref<string>('')
const chats = ref<
  {
    chat: Chat
    order: Order
    product: Product
    advertiser: User
    publisher: User
  }[]
>([])

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 10,
})
store.commit('popup/open', {
  popup: LoaderVue,
})

const last = ref<string | undefined>(undefined)

const reset = () => {
  filterByName.value = ''
  fetch()
}

const fetch = () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  console.log(
    filterByName.value,
    pagination.value.current == 1
      ? 0
      : pagination.value.resultsPerPage * (pagination.value.current - 1),
  )
  Promise.all([
    User.allLight(),
    Chat.count(),
    Chat.all([
      ...(filterByName.value !== ''
        ? [where(documentId(), '==', filterByName.value)]
        : [
            orderBy(documentId()),
            ...(last.value ? [startAfter(last.value)] : []),
            limit(pagination.value.resultsPerPage),
          ]),
    ]),
  ]).then(async ([users, total, res]) => {
    pagination.value.total = total
    pagination.value.totalPages = Math.ceil(
      total / pagination.value.resultsPerPage,
    )
    if((res as Chat[])[(res as Chat[]).length - 1])
      last.value = (res as Chat[])[(res as Chat[]).length - 1]?.uid ?? undefined
    else
      last.value = undefined
    if ((res as Chat[])?.length > 0) {
      const orders = await Order.all([
        where(
          documentId(),
          'in',
          res?.map((i) => i.uid),
        ),
      ])
      const products = await Product.all([
        where(
          documentId(),
          'in',
          (orders as Order[]).map((i: Order) => i.product),
        ),
      ])

      const _chats = <typeof chats.value>[]
      console.log(orders, products)
      for (const chat of res as Chat[]) {
        await chat.loadMessages()
        const order = orders?.find(({ uid }) => uid === chat.uid)
        const product = products?.find(({ uid }) => uid === order?.product)
        if (!order || !order.uid) continue
        _chats.push({
          chat,
          order: order as Order,
          product: product as Product,
          publisher: users.find(
            (el) => el.uid === chat.publisher,
          ) as User,
          advertiser: users.find(
            (el) => el.uid === chat.advertiser,
          ) as User,
        })
      }
      chats.value = _chats
    }
    store.commit('popup/close')
  })
}

onMounted(fetch)

const open = (chat: Chat) =>
  store.commit('popup/open', { popup: ChatPopup, chat })

const next = () => {
  pagination.value.current++
  fetch()
}

const prev = () => {
  pagination.value.current--
  fetch()
}
</script>
