import { Module } from 'vuex'

export interface AccountTypeState {
  type: 'publisher' | 'advertiser'
}

export default {
  namespaced: true,
  strict: true,
  state: {
    type: localStorage.getItem('AccountType') ?? 'publisher',
  },
  mutations: {
    setType(state, value) {
      state.type = value
      localStorage.setItem('AccountType', value)
    },
  },
} as Module<AccountTypeState, any>
