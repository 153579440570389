<template>
  <div class="flex justify-between items-center">
    <div class="inline-block mr-4">
      <span v-if="enabled">{{ t('advertiser') }}</span>
      <span v-else>{{ t('publisher') }}</span>
    </div>
    <Switch
      v-model="enabled"
      class="bg-gradient-to-r from-primary-500 to-secondary-500 bg-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-600 focus-visible:ring-primary-500"
      style="background-size: 110%"
    >
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      />
    </Switch>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'
import { Switch } from '@headlessui/vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router';

const { t } = useI18n()
const store = useStore()
const enabled = ref(store.state.AccountType.type === 'advertiser')
const router = useRouter()
watch(enabled, (val) => {
  store.commit('AccountType/setType', val ? 'advertiser' : 'publisher')
  router.push('/app')
})
</script>
