<template>
  <div class="flex items-center">
    <input
      v-model="filterByName"
      type="text"
      :placeholder="t('search-by-name') + '...'"
      class="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded sm:text-sm border-gray-300 shadow-sm"
    />
    <button class="btn primary" @click="update()">Cerca</button>
  </div>
  <div v-if="products.length">
    <ProductItem
      v-for="product in products"
      :key="product.uid"
      :product="product"
      :users="props.users"
      @update="update"
    />
    <Pagination
      :pagination="pagination"
      :handle-next="next"
      :handle-prev="prev"
    />
  </div>
  <div v-else>No products to show.</div>
</template>
<script setup lang="ts">
import {
  limit,
  orderBy,
  QueryConstraint,
  startAfter,
  where,
} from '@firebase/firestore'
import { ref } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import Pagination from '../Pagination.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'
import User from '../../models/user'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const filterByName = ref<string>('')
const props = defineProps<{
  users: any
}>()

const pagination = ref<{
  current: number
  total: number
  totalPages: number
  resultsPerPage: number
}>({
  current: 1,
  total: 0,
  totalPages: 0,
  resultsPerPage: 20,
})
const last = ref<any>(undefined)
const products = ref<Product[]>([])

const common_query = [where('rejected', '==', true)]

Product.count(common_query).then((total) => {
  pagination.value.total = total
  pagination.value.totalPages = Math.ceil(
    total / pagination.value.resultsPerPage,
  )
})

const next = async () => {
  await fetch([
    orderBy('createdAt', 'desc'),
    ...common_query,
    ...(last.value ? [startAfter(last.value)] : []),
  ])
  pagination.value.current++
}

const prev = async () => {
  await fetch([
    orderBy('createdAt', 'asc'),
    ...common_query,
    ...(last.value ? [startAfter(last.value)] : []),
  ])
  pagination.value.current--
}

const fetch = async (query: QueryConstraint[] = []) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const prods = (await Product.all([
    ...query,
    //limit(pagination.value.resultsPerPage),
  ])) as Product[]
  if (!prods.length) {
    store.commit('popup/close')
    return
  }
  if (filterByName.value !== '') {
    const _prods = prods.filter(
      (p) =>
        p.name.match(new RegExp(filterByName.value, 'i')) ||
        p.website[0]?.match(new RegExp(filterByName.value, 'i')),
    )
    if (!_prods.length) {
      last.value = null
      products.value = []
      store.commit('popup/close')
      return
    }
    last.value = _prods[_prods.length - 1].createdAt
    products.value = _prods
  } else {
    last.value = prods[prods.length - 1].createdAt
    products.value = prods
  }
  store.commit('popup/close')
}

const update = async () => {
  fetch([orderBy('createdAt', 'desc'), ...common_query])
}
update()
</script>
