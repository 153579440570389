<template>
  <div>
    <div class="grid xl:grid-cols-4 gap-3 my-4">
      <InfoBox color="bg-blue-500">
        <div class="text-center">
          <div class="text-sm uppercase">mode</div>
          <div class="font-bold text-3xl">Admin</div>
        </div>
      </InfoBox>
      <!--<InfoBox link="/app/admin/orders" color="bg-green-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('orders') }}</div>
          <div class="font-bold text-3xl flex justify-center pt-2">
            <Loading v-if="!orders" /><span v-else>{{ orders }}</span>
          </div>
        </div>
      </InfoBox>
      <InfoBox link="/app/admin/products" color="bg-red-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('products') }}</div>
          <div class="font-bold text-3xl flex justify-center pt-2">
            <Loading v-if="!products" /><span v-else>{{ products }}</span>
          </div>
        </div>
      </InfoBox>-->
      <!--<InfoBox link="/app/admin/withdrawals" color="bg-orange-500">
        <div class="text-center">
          <div class="text-sm uppercase">{{ t('withdrawal-requests') }}</div>
          <div class="font-bold text-3xl">{{ withdrawals }}</div>
        </div>
      </InfoBox>-->
    </div>
    <!--<div class="md:flex justify-between gap-3 space-x-2 md:space-x-0">
      <div class="w-full md:w-1/2">
        <RecentlyAdded />
      </div>
      <div class="w-full md:w-1/2">
        <Notifications />
      </div>
    </div>-->
  </div>
</template>
<script lang="ts" setup>
import RecentlyAdded from '../../components/RecentlyAdded.vue'
import Notifications from '../../components/Notifications.vue'
import InfoBox from '../../components/InfoBox.vue'
import { onMounted, ref } from 'vue'
import Order from '../../models/order'
import Product from '../../models/product'
import { useI18n } from 'vue-i18n'
import Loading from '../../components/Loading.vue'
const { t } = useI18n()

/*const orders = ref<number>(0)
const products = ref<number>(0)

onMounted(async() => {
  orders.value = await Order.count()
  products.value = await Product.count()

})*/
</script>
