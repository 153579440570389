import { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  StarIcon,
  ClipboardListIcon,
  DocumentTextIcon,
  ChatAlt2Icon,
  CurrencyDollarIcon,
  UsersIcon,
  CurrencyEuroIcon,
  CogIcon,
  SwitchHorizontalIcon,
  ShoppingBagIcon,
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  ViewBoardsIcon,
} from '@heroicons/vue/outline'
import { CodeIcon } from '@heroicons/vue/solid'
import User from '../../models/user'

export interface NavItem {
  name: string
  text?: string
  href: string
  target?: string
  icon?: FunctionalComponent<HTMLAttributes & VNodeProps, any>
  showIf?: (user: User | undefined) => void
  preload?: () => void
}

export const adminNav = [
  { name: 'Dashboard', href: '/app/admin/personal', icon: HomeIcon },
  {
    name: 'Projects',
    href: '/app/admin/personal/projects',
    icon: FolderIcon,
  },
  {
    name: 'Orders',
    href: '/app/admin/personal/orders',
    icon: ClipboardListIcon,
  },
  {
    name: 'Chats',
    href: '/app/admin/personal/chats',
    icon: ChatAlt2Icon,
  },
  {
    name: 'Settings',
    href: '/app/admin/personal/settings',
    icon: CogIcon,
  },
  { name: 'Rankister', href: '/app/admin' },
] as NavItem[]

export const softAdminNav = [
  { name: 'Dashboard', href: '/personal/soft-admin', icon: HomeIcon },
  {
    name: 'Products',
    href: '/app/admin/personal/soft-admin/products',
    icon: FolderIcon,
  },
  {
    name: 'Orders',
    href: '/app/admin/personal/soft-admin/orders',
    icon: ClipboardListIcon,
  },
  {
    name: 'Chats',
    href: '/app/admin/personal/soft-admin/chats',
    icon: ChatAlt2Icon,
  },
  {
    name: 'backlinks-checker',
    href: '/app/admin/personal/soft-admin/blchecker',
    icon: CheckCircleIcon,
  }
] as NavItem[]

export const userNavigation = [
  { name: 'your-profile', href: '/personal/profile' },
  { name: 'affiliation', href: '/personal/affiliation' },
  { name: 'notifications', href: '/personal/notifications' },
  { name: 'sign-out', href: '/logout' },
] as NavItem[]

export const globalLinks = [
  {
    name: 'faq',
    text: 'how-rankister-works',
    href: 'https://rankister.tawk.help/',
    icon: QuestionMarkCircleIcon,
  },
]
