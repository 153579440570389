<template>
  <Popup v-if="product" size="lg">
    <div class="lg:flex flex-grow items-center gap-6">
      <div class="flex-auto flex flex-col">
        <div>
          <h4 class="font-medium text-gray-900 flex">
            {{ product.name }}
            <div class="flex gap-1 mx-2">
              <img
                v-for="ty of types"
                :key="(ty as unknown as string)"
                :src="`/assets/icons/${ty}.svg`"
                width="16"
                height="16"
              />
            </div>
          </h4>
          <a
            v-if="product.website[0]"
            :href="'https://' + product.website[0]"
            class="underline text-sm"
            target="_BLANK"
            >{{ product.website[0] }}</a
          >
          <p class="mt-2 text-sm text-gray-600 max-w-2xl">
            {{ t('order-date') }}: {{ order.createdAt.format() }}
          </p>
          <p class="mt-2 text-sm text-gray-600 max-w-2xl">
            {{ t(order.status) }}
          </p>
        </div>
      </div>
      <div class="flex flex-col text-right text-xl font-bold whitespace-nowrap">
        {{ Number(order.price).format() }} €
        <p class="mt-2 text-sm text-gray-600 max-w-2xl font-normal">
          {{ t('order-id') }}: {{ order.uid }}
        </p>
      </div>
    </div>
    <div class="my-6 divide-y-2 space-y-4">
      <dl class="flex flex-col sm:gap-4 lg:gap-6 text-sm">
        <div
          v-for="(extra, i) in product.extras ?? []"
          :key="extra.name"
          class="grid grid-cols-3 gap-4"
        >
          <dt class="font-medium text-gray-900">{{ extra.name }}</dt>
          <dd class="text-gray-700 col-span-2">
            {{ (order.extras ?? [])![i] ? t('yes') : t('no') }}
          </dd>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('anchor') }}</dt>
          <dd class="text-gray-700 col-span-2">{{ order.anchor }}</dd>
        </div>
        <div v-if="order.link" class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <dt class="font-medium text-gray-900">{{ t('link') }}</dt>
          <dd class="text-gray-700 col-span-2">{{ order.link }}</dd>
        </div>
        <div
          v-if="order.publishDate"
          class="grid grid-cols-1 lg:grid-cols-3 gap-4"
        >
          <dt class="font-medium text-gray-900">
            {{ t('preferred-publish-date') }}
          </dt>
          <dd class="text-gray-700 col-span-2">
            {{ order.publishDate?.format(false) }}
          </dd>
        </div>
        <div
          v-if="order.articleLink"
          class="grid grid-cols-1 lg:grid-cols-3 gap-4"
        >
          <dt class="font-medium text-gray-900">{{ t('article-link') }}</dt>
          <dd class="text-gray-700 col-span-2">
            <a
              :href="order.articleLink"
              target="_blank"
              class="text-primary-500 hover:text-primary-600 hover:underline"
            >
              {{ order.articleLink }}
            </a>
          </dd>
        </div>
        <div
          v-if="order.pictureLink"
          class="grid grid-cols-1 lg:grid-cols-3 gap-4"
        >
          <dt class="font-medium text-gray-900">{{ t('picture-link') }}</dt>
          <dd class="text-gray-700 col-span-2">
            {{ order.pictureLink }}
          </dd>
        </div>
      </dl>
    </div>
    <div v-if="order.info" class="mb-4">
      <h4 class="font-medium text-gray-900 flex">{{ t('order-info') }}:</h4>
      <p class="mt-2 text-sm text-gray-600 max-w-2xl">{{ order.info }}</p>
    </div>
    <div v-if="!order.linkedOrder && order.status === 'paid'">
      <h4 class="font-medium text-gray-900 flex">{{ t('publication') }}:</h4>
      <div v-if="!changing && currentArticle">
        <div class="my-4 text-emerald-500">{{ t('article-received') }}</div>
        <a :href="currentArticle" class="btn primary m-0">
          {{ t('download-article') }}
        </a>
        <a href="" class="btn secondary" @click.prevent="changing = !changing">
          {{ t('change-article') }}
        </a>
      </div>
      <FormRow
        v-if="changing || !currentArticle"
        label=""
        type="file"
        accepts=".doc,.docx"
        @change="article = $event as unknown as File;order.advertiserInfo = false"
      />
    </div>
    <div v-if="order.status === 'paid' && order.linkedOrder && !order.advertiserInfo">
      <button class="btn primary" @click="takeArticleFromLinkedOrder">
        {{ t('take-article-from-linked-order') }}
      </button>
    </div>
    <div
      v-if="
        order.status !== 'work-in-progress' &&
        order.status !== 'need-review' &&
        order.status !== 'completed'
      "
      class="flex justify-end items-center gap-2 mt-6"
    >
      <span v-if="done" class="text-emerald-500">{{ t('saved') }}</span>
      <button
        v-if="
          (article && !order.advertiserInfo) ||
          (order.advertiserInfo && changing && article)
        "
        :disabled="loading || (!!info.pictureLink && !info.accept)"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="save"
      >
        {{ t('save') }}
      </button>
    </div>
    <div
      v-if="order.status === 'need-review' || order.status === 'completed'"
      class="flex justify-end items-center gap-2 mt-6"
    >
      <button
        v-if="order.status === 'need-review' || isAdmin"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderRefused"
      >
        {{ t(isAdmin || isSoftAdmin ? 'rollback-order' : 'refuse-order') }}
      </button>
      <button
        v-if="order.status === 'need-review'"
        class="flex items-center gap-1 btn gradient disabled:opacity-50"
        @click="orderDone"
      >
        {{ t('confirm-order') }}
      </button>
    </div>
  </Popup>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex'
import { State } from '../../store'
import { useI18n } from 'vue-i18n'
import Order from '../../models/order'
import { ref } from 'vue'
import { computed } from '@vue/reactivity'
import FormRow from '../form/FormRow.vue'
import { useFirebase } from '../../plugins/firebase'
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
  listAll,
  deleteObject,
getBytes,
} from '@firebase/storage'
import LoaderVue from '../Loader.vue'
import { useRoute } from 'vue-router'
import Popup from './Popup.vue'
import Product from '../../models/product'

const store = useStore<State>()
const types = computed(() =>
  [
    'website',
    'facebook',
    'instagram',
    'youtube',
    'tiktok',
    'reddit',
    'telegram',
  ].filter(
    (type) =>
      (store.state.popup.args.order.product as unknown as Record<string, []>)[
        type
      ]?.length,
  ),
)

const product = ref<Product>()
Product.find(store.state.popup.args.order.product).then(
  (p) => (product.value = p as Product),
)
const order = ref<Order>(store.state.popup.args.order)
const orderDone = store.state.popup.args.orderDone
const orderRefused = store.state.popup.args.orderRefused

const { t } = useI18n()

const isAdmin = useRoute().fullPath.startsWith('/app/admin')
const isSoftAdmin = useRoute().fullPath.startsWith('/app/soft-admin')

const firebase = useFirebase()
const storage = firebase.getStorage()
const erroredFile = ref<boolean>(false)

const info = ref({
  publishDate: order.value.publishDate?.toISOString().split('T')[0],
  link: order.value.link,
  anchor: order.value.anchor,
  pictureLink: order.value.pictureLink,
  accept: order.value.pictureLink ? true : false,
})
const changing = ref(false)
const currentArticle = ref<string>()
const getCurrentArticle = async () => {
  const list = await listAll(storageRef(storage, 'articles/'))
  const el = list.items.find((item) =>
    item.name.startsWith(order.value.uid as string),
  )
  if (el) {
    currentArticle.value = await getDownloadURL(
      storageRef(storage, `articles/${el.name}`),
    )
  } else erroredFile.value = true
}
getCurrentArticle()
const article = ref<File>()
const done = ref(false)
const loading = ref(false)
const save = async () => {
  store.commit('popup/open', { popup: LoaderVue })
  loading.value = true
  if (article.value) {
    const list = await listAll(storageRef(storage, 'articles/'))
    list.items.forEach((item) =>
      item.name.startsWith(order.value.uid as string)
        ? deleteObject(storageRef(storage, `articles/${item.name}`))
        : void 0,
    )
    await uploadBytes(
      storageRef(
        storage,
        `articles/${order.value.uid}.${article.value.name.split('.').pop()}`,
      ),
      await article.value.arrayBuffer(),
    )
    order.value.article = true
  }
  order.value.advertiserInfo = true
  order.value.advertiserInfoDate = new Date()
  order.value.link = info.value.link
  order.value.anchor = info.value.anchor
  order.value.pictureLink = info.value.pictureLink
  order.value.publishDate = info.value.publishDate
    ? new Date(info.value.publishDate)
    : undefined
  await order.value.save()
  await firebase.function('orderChangeNotification', { order: order.value.uid })
  await getCurrentArticle()
  changing.value = false
  loading.value = false
  done.value = true
  setTimeout(() => {
    done.value = false
    store.commit('popup/close')
  }, 2000)
}

const takeArticleFromLinkedOrder = async () => {
  store.commit('popup/open', { popup: LoaderVue })
  const linkedOrder = await new Promise((resolve) => {
    Order.find(order.value.linkedOrder).then((lO) => resolve(lO as Order))
  }) as Order
  if(!linkedOrder) {
    alert('Order not found. Please contact us')
    return
  }

  const linkedOrderArticleURL = await getBytes(
      storageRef(storage, `articles/${linkedOrder.uid}.docx`),
    )

  await uploadBytes(
    storageRef(
      storage,
      `articles/${order.value.uid}.docx`,
    ),
    linkedOrderArticleURL
  )
  order.value.article = true
  await save()
}
</script>
