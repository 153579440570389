import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from '@firebase/firestore'
import { firebase } from '../plugins/firebase'
import Model from './model'

interface ChatModel {
  uid?: string
  advertiser: string
  publisher: string
  createdAt: Date
  messages?: {
    from: string
    content: string
    date: Date
  }[]
}

export default class Chat extends Model implements ChatModel {
  public collection = 'chats'
  static collection = 'chats'

  public advertiser: string
  public publisher: string
  public createdAt: Date
  public messages: {
    uid: string
    from: string
    content: string
    date: Date
    read: boolean
  }[] = []

  public async loadMessages() {
    const firestore = firebase.getFirestore()
    const messages = (
      await getDocs(
        query(
          collection(firestore, `${this.collection}/${this.uid}/messages`),
          orderBy('date', 'asc'),
        ),
      )
    ).docs
    this.messages = messages.map((message) => ({
      uid: message.id,
      from: message.data().from,
      content: message.data().content,
      date: message.data().date.toDate(),
      read: message.data().read || false
    }))
  }

  public constructor(attributes: ChatModel) {
    super()
    this.uid = attributes.uid
    this.advertiser = attributes.advertiser
    this.publisher = attributes.publisher
    this.createdAt = attributes.createdAt
    this.loadMessages()
  }

  static instantiate(id: string, prod: DocumentData) {
    const { uid, ...attrs } = prod as ChatModel
    return new this({
      uid: id,
      ...attrs,
    })
  }

  public markAsRead(uid: string) {
    const firestore = firebase.getFirestore()
    return Promise.all(this.messages.filter(i => i.from != uid && !i.read).map((i) => updateDoc(doc(firestore, `${this.collection}/${this.uid}/messages/${i.uid}`), {
      read: true
    })))
  }

  public async sendMessage(message: { from: string; content: string }) {
    const firestore = firebase.getFirestore()
    const data = await getDoc(
      await addDoc(
        collection(firestore, `${this.collection}/${this.uid}/messages`),
        { ...message, date: new Date() },
      ),
    )

    await firebase.function('chatNotify', {
      id: data.id,
      chat: this.uid
    })

    this.messages.push({
      uid: data.id,
      from: data.data()!.from,
      content: data.data()!.content,
      date: data.data()!.date.toDate() as Date,
      read: data.data()!.read || false
    })
  }
}
