<template>
  <div class='container mx-auto grid divide-gray-300 divide-y-[1px] gap-2'>
    <div v-for='feature of features' :key='feature.name' class='flex justify-between items-center'>
      <div class='inline-block mr-4'>
        {{ feature.name }}
      </div>
      <Switch
        v-model='feature.isActive'
        :class='feature.isActive? " from-primary-500 to-secondary-500" : "from-gray-400 to-gray-500"'
        class='bg-gradient-to-r bg-center relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-gray-600 focus-visible:ring-primary-500'
        style='background-size: 110%'
        @update:model-value='feature.save.apply(feature)'
      >
        <span
          :class="[
          feature.isActive ? 'translate-x-5' : 'translate-x-0',
        ]"
          aria-hidden='true'
          class='pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
        />
      </Switch>
    </div>
  </div>
</template>
<script lang='ts' setup>
import Feature, { featureList } from '../../models/feature'
import { onMounted, ref } from 'vue'
import { Switch } from '@headlessui/vue'

const features = ref<Record<Feature['name'], Feature>>(featureList.reduce((acc, f) => {
    acc[f] = new Feature(f, false)
    return acc
  }, {} as Record<Feature['name'], Feature>),
)

console.log(features.value)

onMounted(() => {
  Feature.getFeatures().then((fs: Feature[]) => {
    fs.forEach((f) => {
      features.value[f.name] = f
    })
  })
})

</script>
<style scoped>

</style>