<template>
  <div v-if='products.length'>
    <div v-for='(duplicates, i) in products' :key='i' class='pl-2'>
      <ul class='flex justify-between p-2 bg-black text-white'>
        <li>Owner: {{ users.find((u: any) => u.uid === duplicates[0].owner)?.displayName }}</li>
        <li>Type: {{ duplicates[0].type }}</li>
        <li>Publication Duration: {{ duplicates[0].publicationduration }}</li>
      </ul>
      <div class='border-l-2'>

        <ProductItem
          v-for='product in duplicates'
          :key='product.uid'
          :product='product'
          :users='props.users'
          @update='update'
        />
      </div>
    </div>
  </div>
  <div v-else>No products to show.</div>
</template>
<script lang='ts' setup>
import { orderBy, QueryConstraint } from '@firebase/firestore'
import { ref } from 'vue'
import Product from '../../models/product'
import ProductItem from '../ProductItem.vue'
import store from '../../store'
import LoaderVue from '../Loader.vue'
import { Listbox } from '@headlessui/vue'

const props = defineProps<{
  users: any
}>()

const last = ref<any>(undefined)
const products = ref<Product[][]>([])

const getDuplicates = (prods: Product[]): Product[][] => {
  const hashMap: { [key: string]: Product[] } = {}

  prods.forEach((prod) => {
    const key = prod.hash
    if (!hashMap[key]) {
      hashMap[key] = []
    }
    hashMap[key].push(prod)
  })

  return Object.values(hashMap).filter((prods) => prods.length > 1)
}

const fetch = async (query: QueryConstraint[] = []) => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  const prods = (await Product.all([
    ...query,
  ])) as Product[]
  if (!prods.length) {
    store.commit('popup/close')
    return
  }
  last.value = prods[prods.length - 1].createdAt
  products.value = getDuplicates(prods)
  store.commit('popup/close')
}

const update = async () => {
  fetch([orderBy('createdAt', 'desc')])
}
update()
</script>
