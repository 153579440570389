<template>
  <div>
    <Heading :text="t('settings')" />
    <form
      v-if="
        bank &&
        banner &&
        bannerPub &&
        orderFee &&
        apiAhrefs &&
        apiMoz &&
        invoicingData &&
        minMediaAmount &&
        affiliatePercentage
      "
      ref="form"
      @submit.prevent="save"
    >
      <div>
        <FormRow
          v-model="bank.value.iban"
          name="iban"
          label="IBAN"
          type="text"
        />
        <FormRow
          v-model="bank.value.bank"
          name="bank-name"
          label="Bank Name"
          type="text"
        />
        <FormRow
          v-model="bank.value.name"
          name="name"
          label="Name"
          type="text"
        />
        <FormRow
          v-model="banner.value.image"
          name="banner-image"
          label="Banner Image (Advertiser)"
          type="text"
        />
        <FormRow
          v-model="banner.value.link"
          name="banner-link"
          label="Banner Link (Advertiser)"
          type="text"
        />
        <FormRow
          v-model="bannerPub.value.image"
          name="banner-image"
          label="Banner Image (Publisher)"
          type="text"
        />
        <FormRow
          v-model="bannerPub.value.link"
          name="banner-link"
          label="Banner Link (Publisher)"
          type="text"
        />
        <FormRow
          v-model="orderFee.value"
          name="order-fee"
          label="Order fee"
          placeholder="15"
          after="%"
          type="number"
        />
        <FormRow
          v-model="apiAhrefs.value"
          name="api-ahrefs"
          label="API Ahrefs"
          type="text"
        />
        <FormRow
          v-model="apiMoz.value"
          name="api-moz"
          label="API Moz"
          type="text"
        />
        <FormRow
          v-model="minMediaAmount.value"
          name="min-media-amount"
          label="Minimum media amount"
          step="0.01"
          lang="it"
          type="number"
        />
        <FormRow
          v-model="invoicingData.value"
          name="invoicingData"
          label="Invoicing Data"
          type="textarea"
        />
        <FormRow
          v-model="affiliatePercentage.value"
          name="affiliatePercentage"
          label="Affiliate Percentage"
          placeholder="5"
          after="%"
          type="number"
        />
      </div>
      <button class="btn gradient float-right mt-4 mb-16">Save settings</button>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'
import Heading from '../../components/Heading.vue'
import FormRow from '../../components/form/FormRow.vue'
import Setting from '../../models/setting'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { State } from '../../store'
import LoaderVue from '../../components/Loader.vue'

const store = useStore<State>()
const { t } = useI18n()

store.commit('popup/open', {
  popup: LoaderVue,
})

const bank = ref<Setting>()
const banner = ref<Setting>()
const bannerPub = ref<Setting>()
const orderFee = ref<Setting>()
const apiAhrefs = ref<Setting>()
const apiMoz = ref<Setting>()
const invoicingData = ref<Setting>()
const affiliatePercentage = ref<Setting>()
const minMediaAmount = ref<Setting>()

Promise.all([
  Setting.findOrCreate('bank'),
  Setting.findOrCreate('banner'),
  Setting.findOrCreate('banner-pub'),
  Setting.findOrCreate('order-fee'),
  Setting.findOrCreate('api-ahrefs'),
  Setting.findOrCreate('api-moz'),
  Setting.findOrCreate('invoicing-data'),
  Setting.findOrCreate('min-media-amount'),
  Setting.findOrCreate('affiliate-percentage'),
]).then(
  ([
    bankSetting,
    bannerSetting,
    bannerPubSetting,
    orderFeeSetting,
    apiAhrefsSetting,
    apiMozSetting,
    invoicingDataSetting,
    minMediaAmountSetting,
    affiliatePercentageSetting,
  ]) => {
    if(!bankSetting.value) bankSetting.value = {}
    bank.value = bankSetting

    if(!bannerSetting.value) bannerSetting.value = {}
    banner.value = bannerSetting

    if(!bannerPubSetting.value) bannerPubSetting.value = {}
    bannerPub.value = bannerPubSetting

    orderFeeSetting.value = orderFeeSetting.value * 100
    orderFee.value = orderFeeSetting

    apiAhrefs.value = apiAhrefsSetting
    apiMoz.value = apiMozSetting
    invoicingData.value = invoicingDataSetting
    minMediaAmount.value = minMediaAmountSetting

    affiliatePercentageSetting.value = affiliatePercentageSetting.value * 100
    affiliatePercentage.value = affiliatePercentageSetting

    store.commit('popup/close')
  },
)

const form = ref<HTMLFormElement>()
const save = async () => {
  store.commit('popup/open', {
    popup: LoaderVue,
  })
  ;(orderFee.value as Setting).value = (orderFee.value as Setting).value / 100
  await orderFee.value?.save()
  ;(orderFee.value as Setting).value = (orderFee.value as Setting).value * 100
  await bank.value?.save()
  await banner.value?.save()
  await bannerPub.value?.save()
  await apiAhrefs.value?.save()
  await apiMoz.value?.save()
  await invoicingData.value?.save()
  await minMediaAmount.value?.save()
  ;(affiliatePercentage.value as Setting).value =
    (affiliatePercentage.value as Setting).value / 100
  await affiliatePercentage.value?.save()
  ;(affiliatePercentage.value as Setting).value =
    (affiliatePercentage.value as Setting).value * 100
  store.commit('popup/close')
}
</script>
